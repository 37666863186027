import React, { useEffect, useState } from 'react'
import ReportComp from './ReportComp'
import ReportCompDetail from './ReportCompDetail'
import { useAppContext } from '../../AppContext'
import Loader from '../shared/Loader'

const TtiReport = ({ data,info }) => {
  const { user: tenant } = useAppContext()

  const [ttiresult, setTtiResult] = useState(true)
  const [failreasion, setFailReasion] = useState([])
  const checkResult = () => {
    let finalresult = true
    let reasion = []
    if (
      data?.BloodBagIssueEntity?.BloodTestingEntity?.hiv == '1' ||
      data?.BloodBagIssueEntity?.BloodTestingEntity?.anty_hcv == '1' ||
      data?.BloodBagIssueEntity?.BloodTestingEntity?.vdrl == '1' ||
      data?.BloodBagIssueEntity?.BloodTestingEntity?.malaria == '1' ||
      data?.BloodBagIssueEntity?.BloodTestingEntity?.hsbg == '1'
    ) {
      finalresult = false
      if (data?.BloodBagIssueEntity?.BloodTestingEntity?.hiv == '1') {
        reasion.push('HIV positive')
      }
      if (data?.BloodBagIssueEntity?.BloodTestingEntity?.anty_hcv == '1') {
        reasion.push('Anti-HCV (Hepatitis C) positive')
      }
      if (data?.BloodBagIssueEntity?.BloodTestingEntity?.vdrl == '1') {
        reasion.push('Syphilis (VDRL) positive')
      }
      if (data?.BloodBagIssueEntity?.BloodTestingEntity?.malaria == '1') {
        reasion.push('Malaria positive')
      }
      if (data?.BloodBagIssueEntity?.BloodTestingEntity?.hsbg == '1') {
        reasion.push('HBsAg(Hepatitis B) positive')
      }
    } else {
    }

    setTtiResult(finalresult)
    setFailReasion(reasion)
  }

  useEffect(() => {
    if (data) {
      checkResult()
    }
  }, [data])


  if(!data) return <Loader isLoading={true} />

  return (
    <>
      <ReportComp tenant={tenant} ttiresult={ttiresult} failreasion={failreasion} data={data} info={info} />
      {/* <ReportCompDetail tenant={tenant} ttiresult={ttiresult} failreasion={failreasion} data={data} /> */}
    </>
  )
}

export default TtiReport
