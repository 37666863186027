import React, { createContext, useContext, useEffect, useState } from 'react'
import AxiosHelper from './helper/AxiosHelper'
import Loader from './app/shared/Loader'
import { getDonorNameSans, getToken } from './app/middleware/auth'
import { checkTenantId } from './helper/CommonAPI'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { fullPageLayoutRoutes } from './app/App'

const AppContext = createContext()

export const useAppContext = () => {
  return useContext(AppContext)
}

// ** Defaults
const defaultProvider = {
  user: null,
  loading: false,
  setUser: () => null,
  setLoading: () => Boolean
}

const AppContextProvider = ({ children }) => {
  const [user, setUser] = useState(defaultProvider.user)
  const [donor, setDonor] = useState(defaultProvider.user)
  const [loading, setLoading] = useState(defaultProvider.loading)
  const [ttiOpen, setTtiOpen] = useState(false)
  const [campOpen, setCampOpen] = useState(false)
  const [wasteOpen, setWasteOpen] = useState(false)
  const [donorOpen, setDonorOpen] = useState(true)
  const [states, setStates] = useState([])
  const [districts, setDistricts] = useState([])
  const [requisitionActiveTab, setRequisitionActiveTab] = useState(1)
  const [billingActiveTab, setBillingActiveTab] = useState(1)
  const [activeTutorial, setActiveTutorial] = useState('')
  const [crossMatchSelectedDetailes, setCrossMatchSelectedDetailes] = useState({})
  const location = useLocation()

  const isFullPageLayout = fullPageLayoutRoutes?.some(route => location.pathname.startsWith(route))

  const getStates = () => {
    AxiosHelper.get('address/state')
      .then(response => {
        setStates(response?.data?.data)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const getDistict = val => {
    const state = states?.find(item => item.name === val)
    AxiosHelper.get('address/district/' + state?.id)
      .then(response => {
        setDistricts(response?.data?.data)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const initAuth = async () => {
    let token = getToken()
    if (token) {
      setLoading(true)
      const URL = checkTenantId(`tenants/setting`)
      AxiosHelper.get(URL)
        .then(resp => {
          setUser(resp.data.data)
          setLoading(false)
        })
        .catch(err => {
          console.log(err)
          setLoading(false)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  useEffect(() => {
    initAuth()
  }, [])

  useEffect(() => {
    const tenant = getDonorNameSans()
    if (tenant) {
      setDonor(tenant)
    } else {
      setDonor('')
    }
  }, [])

  const values = {
    ttiOpen,
    setTtiOpen,
    setDonorOpen,
    donorOpen,
    states,
    setStates,
    getStates,
    districts,
    setDistricts,
    getDistict,
    campOpen,
    setCampOpen,
    requisitionActiveTab,
    setRequisitionActiveTab,
    billingActiveTab,
    setBillingActiveTab,
    wasteOpen,
    setWasteOpen,
    crossMatchSelectedDetailes,
    setCrossMatchSelectedDetailes,
    user,
    setUser,
    activeTutorial,
    setActiveTutorial,
    donor,
    setDonor,
    initAuth
  }

  if (loading && !isFullPageLayout) {
    return <Loader isLoading={true} />
  }

  return <AppContext.Provider value={values}>{children}</AppContext.Provider>
}

export default AppContextProvider
