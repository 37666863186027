import React from 'react';
import { useParams } from 'react-router-dom';
import BreadCrumb from '../../common/BreadCrumb';
import PageHeader from '../../common/PageHeader';
import UnderContruction from '../../under-construction';
import Dashboard from '../../dashboard/Dashboard';

function TenantDashboard() {
  const { id } = useParams();
  return <Dashboard id={id} />;
}

export default TenantDashboard;
