import {
  Box, Button, Dialog, DialogActions, DialogContent, Fade, IconButton, Typography,
} from '@mui/material';
import React, { forwardRef } from 'react';

const Transition = forwardRef((props, ref) => <Fade ref={ref} {...props} />);

function ModalCustom2(props) {
  // ** props
  const {
    closeDialog, children, open, confirmDialog, title, size, scroll, id, fullScreen,
  } = props;

  return (
    <Dialog
      fullWidth
      fullScreen={fullScreen || false}
      open={open}
      maxWidth={size || 'sm'}
      scroll={scroll || 'body'}
      onClose={closeDialog}
      onBackdropClick={closeDialog}
      TransitionComponent={Transition}
      id={id || 'custom_modal'}
    >
      <div>
        <DialogContent sx={{}}>
          <div className="model-heading">{title || 'Are You Sure?'}</div>
          <div className="model-body">{children}</div>

          <DialogActions>
            <button className="btn backBtn" onClick={closeDialog}>
              Close
            </button>
            <button className="btn backBtn btn-gradient-custom " onClick={confirmDialog}>
              Confirm
              {' '}
            </button>
          </DialogActions>
        </DialogContent>
      </div>
    </Dialog>
  );
}

export default ModalCustom2;
