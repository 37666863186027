import { Box } from '@mui/material'
import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { getFileIcon, getFileTypeFromFile, getFileTypeFromUrl } from '../../helper/CommonAPI'
import { toast } from 'react-toastify'

function UploadBox({ placeholder, error, disabled, onChange, name, allowed, file, size, sx, ...other }) {
  const imgUrl = file ? (typeof file === 'string' ? file : file.preview) : ''
  let sizeFromProp = size || 10

  function ImageRender() {
    let filesType
    if (typeof file === 'string') {
      filesType = getFileTypeFromUrl(file)
    } else {
      filesType = getFileTypeFromFile(file)
    }
    const url = filesType !== 'Image' ? getFileIcon(filesType) : imgUrl

    return (
      <img
        // src={url}
        src={url||"/assets/images/Requistion/document-upload-done.svg"}
        alt=''
        style={{
          top: 8,
          left: 8,
          zIndex: 8,
          borderRadius: 1,
          objectFit: 'contain',
          position: 'absolute',
          width: 'calc(100% - 16px)',
          height: 'calc(100% - 16px)',
          minWidth: '120px'
        }}
      />
    )
  }

  const handleFileChange = (files, inputName) => {
    const MAX_FILE_SIZE = sizeFromProp * 1024 * 1024 // 10 MB in bytes
    const validFiles = []
    const invalidFiles = []

    
    for (let i = 0; i < files.length; i++) {
      if (files[i].size > MAX_FILE_SIZE) {
        invalidFiles.push(files[i])
        } else {
          validFiles.push(files[i])
          }
          }
          
          console.log('invalidFiles', validFiles)

    if (invalidFiles.length > 0) {
      toast.error(`file is too large. Each file must be less than ${sizeFromProp} MB.`)
    }

    if (validFiles.length > 0) {
      onChange(validFiles, inputName)
    }
  }



  return (
    <label className='input__filess' style={{ marginBottom: 0, position: 'relative', ...sx }} htmlFor={name || 'files'}>
      <input
        onChange={e => handleFileChange(e.target.files, name)}
        style={{
          opacity: 0,
          width: 0,
          height: 0,
          position: 'absolute'
        }}
        type='file'
        name={name || 'files'}
        id={name || 'files'}
        className='inputfile'
        {...other}
      />
      {imgUrl ? (
        ImageRender()
      ) : (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <img
            style={{
              width: '35px',
              marginRight: '5px',
              objectFit: 'contain'
            }}
            src='/assets/images/certificate/download.svg'
            alt=''
          />
          {placeholder || ''} Choose file
          <br />
          {allowed || 'Allowed *.svg,*.png'}
        </Box>
      )}
    </label>
  )
}

export default UploadBox
