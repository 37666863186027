import moment from 'moment'
import { CTABtns } from '../ui/allotted-component'

export const CardHeader = ({
  children,
  lastUpdated,
  collectionDate,
  expiredDate,
  testingDate,
  actionBtns = false,
  actionCallBack,
  r_id,
  deleteBtn = true,
  editBtn = true,
  disableEdit = false, // Set default value here
  editToolTip = 'Edit',
  deleteToolTip = 'Delete'
}) => {
  console.log(`disable in header -> `, disableEdit)
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        borderBottom: '1px solid #ddd',
        borderCollapse: 'collapse',
        width: '100%'
      }}
    >
      <div>
        <div className='trail-subheading'>{children}</div>
        <div className='trail-small'>
          {lastUpdated ? `Last Updated: ${moment(lastUpdated).format('DD MMM YYYY hh:mm A')}` : null}
          {lastUpdated && collectionDate ? ' | ' : null}
          {collectionDate ? `Collection Date: ${moment(collectionDate).format('DD MMM YYYY')}` : null}
          {collectionDate && expiredDate ? ' | ' : null}
          {expiredDate ? `Expire Date: ${moment(expiredDate).format('DD MMM YYYY')}` : null}
          {expiredDate && testingDate ? ' | ' : null}
          {testingDate ? `Tested On: ${moment(testingDate).format('DD MMM YYYY')}` : null}
        </div>
      </div>
      {actionBtns ? (
        <CTABtns
          editToolTip={editToolTip}
          deleteToolTip={deleteToolTip}
          disableEdit={disableEdit}
          actionCallBack={actionCallBack}
          r_id={r_id}
          deleteBtn={deleteBtn}
          editBtn={editBtn}
        />
      ) : null}
    </div>
  )
}
