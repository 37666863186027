import React, { useState } from 'react'
import {
  Box,
  Checkbox,
  FormLabel,
  Grid,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  Select,
  Typography
} from '@mui/material'
import { inputStyles, labelStyles } from '../certification/EditCertificate'
import dayjs from 'dayjs'
import { ChevronLeft, ChevronRight, FileDownload } from '@mui/icons-material'
import { DatePicker } from '@mui/x-date-pickers'
import { downLoadFiles, getTenatIdSans, getTenatNameSans } from '../middleware/auth'
import { radioStyles } from '../donors/Donors'
import { toast } from 'react-toastify'
import { checkTenantId } from '../../helper/CommonAPI'

export const bloodComponentArray = [
  { value: 'WBCPD', label: 'Whole Blood (CPD)' },
  { value: 'WBCPD1', label: 'Whole Blood (CPDA-1)' },
  { value: 'PRBC', label: 'Packed Red Blood Cells' },
  { value: 'FFP', label: 'Fresh Frozen Plasma' },
  { value: 'SDP', label: 'Single Donor Platelet' },
  { value: 'CRYP', label: 'Cryoprecipitate' },
  { value: 'PLASMA', label: 'Plasma' },
  { value: 'PC', label: 'Platelet Concentrate' },
  { value: 'CPP', label: 'Cryo Poor Plasma' },
  { value: 'RDP', label: 'Random Donor Platelets' },
  { value: 'PAS', label: 'Platelets additive solutions' }
]

const StockReport = ({ baseUrl, pdf, excel, type, name }) => {
  const [reportAnchor, setReportAnchor] = useState(null)
  const initialFil = {
    duration: 'monthly',
    startDate: '',
    endDate: '',
    blood_components: ['WBCPD1', 'PRBC', 'FFP', 'SDP', 'RDP'],
    month: dayjs().month(),
    sortingOnColumn: "orderByCollectionDate",
    sortingType: "desc",
  }
  const [searchParams, setSearchParams] = useState(initialFil)

  const handleApplyFilter = (values, downloadFormat) => {
    let filterurl = ''

    if (values?.blood_components) {
      if (values?.blood_components?.length < 5) {
        toast.error('Please select atleast 5 components')
        return
      } else {
        let str = values.blood_components.join('~')
        filterurl = filterurl + `&selectedColumns=${str}`
      }
    }
    if (type === 'paediatric') {
      filterurl = filterurl + `&blood_request_type=2`
    }

    if (values?.startDate) {
      filterurl = filterurl + `&startDate=${values?.startDate}`
    }
    if (values?.endDate) {
      filterurl = filterurl + `&endDate=${values?.endDate}`
    }

    if (values?.duration === 'monthly') {
      const today = dayjs().format('YYYY-MM-DD')
      const firstDate =
        values?.month || values?.month === 0 ? dayjs().month(values.month).startOf('month').format('YYYY-MM-DD') : ''
      const lastDate =
        values?.month || values?.month === 0
          ? dayjs().month(values.month).isSame(dayjs(), 'month')
            ? today
            : dayjs().month(values.month).endOf('month').format('YYYY-MM-DD')
          : ''

      filterurl = filterurl + `&startDate=${firstDate}&endDate=${lastDate}`
    }

    if (values?.sortingOnColumn && values?.sortingType) {
      filterurl = filterurl + `&${values?.sortingOnColumn}=${values?.sortingType}`
    }

    filterurl = checkTenantId(filterurl)

    downLoadFiles(`/${baseUrl}?${downloadFormat}=true${filterurl}`, 'Component_Register_Report')
  }

  const openReportMenu = event => {
    setReportAnchor(event.currentTarget)
  }

  const closeBillMenu = () => {
    setReportAnchor(null)
  }

  function handleAllChanges(e) {
    if (e.target.value === 'monthly') {
      setSearchParams({
        ...searchParams,
        [e.target.name]: e.target.value,
        startDate: '',
        endDate: ''
      })
    } else {
      setSearchParams({
        ...searchParams,
        [e.target.name]: e.target.value
      })
    }
  }

  const multiple = event => {
    const {
      target: { value }
    } = event

    // Check if the number of selected components is more than 5
    if (value.length <= 5) {
      setSearchParams({
        ...searchParams,
        blood_components: value
      })
    } else {
      console.log(searchParams?.blood_components)
      toast.error('Cannot select more than 5 components')
    }
  }

  return (
    <>
      <button
        type='button'
        className='btn gradient-btn'
        // onClick={handlebillingReport}
        onClick={openReportMenu}
      >
        <FileDownload fontSize='small' /> {name ? name : 'Report'}
      </button>
      <Menu
        open={Boolean(reportAnchor)}
        anchorEl={reportAnchor}
        onClose={closeBillMenu}
        keepMounted
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}

      //TransitionComponent={Slide}
      >
        <div className='fltr-billing-container'>
          <Grid sx={{ maxWidth: '480px', padding: '16px' }} container spacing={2}>
            <Grid item xs={12} sm={4}>
              <FormLabel sx={labelStyles}>Components</FormLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Select
                sx={{ height: '42px', ...inputStyles }}
                multiple
                fullWidth
                InputLabelProps={{ shrink: false }}
                value={searchParams?.blood_components || []}
                onChange={e => {
                  multiple(e)
                }}
                renderValue={selected => selected.join(',')}
              >
                {bloodComponentArray?.map((d, i) => (
                  <MenuItem key={i} value={d?.value}>
                    <Checkbox sx={radioStyles} checked={searchParams?.blood_components?.indexOf(d?.value) > -1} />
                    <ListItemText primary={d?.label} />
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormLabel sx={labelStyles}>Sorting on Column</FormLabel>
            </Grid>

            <Grid item xs={12} sm={8}>
              <Select
                sx={{ height: '42px', ...inputStyles }}
                fullWidth
                name='sortingOnColumn'
                value={searchParams?.sortingOnColumn}
                onChange={e => handleAllChanges(e)}
              >
                <MenuItem value={"orderByCollectionDate"} >Collection Date</MenuItem>
                <MenuItem value={"orderByBagNumber"} >Bag Number</MenuItem>
              </Select>
            </Grid>

            {searchParams?.sortingOnColumn ? <>
              <Grid item xs={12} sm={4}>
                <FormLabel sx={labelStyles}>Sorting by</FormLabel>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Select
                  sx={{ height: '42px', ...inputStyles }}
                  fullWidth
                  name='sortingType'
                  value={searchParams?.sortingType}
                  onChange={e => handleAllChanges(e)}
                >
                  <MenuItem value={'asc'}>Ascending</MenuItem>
                  <MenuItem value={'desc'}>Descending</MenuItem>
                </Select>
              </Grid>
            </> : null}
            <Grid item xs={12} sm={4}>
              <FormLabel sx={labelStyles}>Duration</FormLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Select
                sx={{ height: '42px', ...inputStyles }}
                fullWidth
                select
                placeholder='duration'
                name='duration'
                value={searchParams?.duration}
                onChange={handleAllChanges}
              >
                <MenuItem value='monthly'>Monthly</MenuItem>
                {/* <MenuItem value="Half_year">Last 6 Months</MenuItem> */}
                <MenuItem value='custom'>Custom</MenuItem>
              </Select>
              {searchParams?.duration === 'monthly' ? (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    background: '#fff',
                    borderRadius: 2
                  }}
                >
                  <IconButton
                    onClick={() => {
                      if (searchParams?.month > 0) {
                        setSearchParams({
                          ...searchParams,
                          month: searchParams?.month - 1
                        })
                      }
                    }}
                  >
                    <ChevronLeft />
                  </IconButton>
                  <Typography>
                    <DatePicker
                      views={['month']}
                      disableFuture
                      value={dayjs().month(searchParams?.month)}
                      format='MMMM-YYYY'
                      onChange={value => {
                        setSearchParams({
                          ...searchParams,
                          month: dayjs(value).month()
                        })
                      }}
                      sx={{
                        ...inputStyles,
                        background: 'transparent !important',
                        border: 0,
                        '& fieldset': { border: '0 !important' },
                        '& .MuiOutlinedInput-root': {
                          p: '0 !important'
                        }
                      }}
                      slotProps={{
                        textField: {
                          size: 'small',
                          fullWidth: true
                        }
                      }}
                    />
                  </Typography>
                  <IconButton
                    onClick={() => {
                      if (searchParams?.month < dayjs().month()) {
                        setSearchParams({
                          ...searchParams,
                          month: searchParams?.month + 1
                        })
                      }
                    }}
                  >
                    <ChevronRight />
                  </IconButton>
                </Box>
              ) : null}
              {searchParams?.duration === 'custom' ? (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    background: '#fff',
                    gap: 2,
                    pt: 2
                  }}
                >
                  <DatePicker
                    sx={{ ...inputStyles, maxWidth: '50' }}
                    fullWidth
                    type='date'
                    format='YYYY-MM-DD'
                    name='startDate'
                    disableFuture
                    value={searchParams?.startDate || null}
                    onChange={value =>
                      setSearchParams({
                        ...searchParams,
                        startDate: dayjs(value).format('YYYY-MM-DD')
                      })
                    }
                  />

                  <DatePicker
                    sx={{ ...inputStyles, maxWidth: '50' }}
                    fullWidth
                    type='date'
                    disableFuture
                    format='YYYY-MM-DD'
                    name='endDate'
                    value={searchParams?.endDate || null}
                    onChange={value =>
                      setSearchParams({
                        ...searchParams,
                        endDate: dayjs(value).format('YYYY-MM-DD')
                      })
                    }
                  />
                </Box>
              ) : null}
            </Grid>

            <Grid item xs={12} display='flex' justifyContent='flex-end' gap={2}>
              <button
                className='btn backBtn '
                onClick={() => {
                  setSearchParams(initialFil)
                }}
              >
                Reset
              </button>

              {excel ? (
                <button className='btn gradient-btn' onClick={() => handleApplyFilter(searchParams, 'downloadExcel')}>
                  Download Excel
                </button>
              ) : null}

              {pdf ? (
                <button className='btn gradient-btn' onClick={() => handleApplyFilter(searchParams, 'downloadPdf')}>
                  Download pdf
                </button>
              ) : null}
            </Grid>
          </Grid>
        </div>
      </Menu>
    </>
  )
}

export default StockReport
