import React from 'react';
import PageHeader from '../common/PageHeader';
import BreadCrumb from '../common/BreadCrumb';
import ScrollCard from '../shared/ScrollCard';
import AddBloodReqForm from './new-request/AddBloodReqForm';

const breadcrumbRoute = [
  {
    path: '/dashboard',
    label: 'Dashboard',
  },
  {
    label: 'Blood Requisition',
  },
];

function BloodRequisition() {
  return (
    <div>
      <BreadCrumb breadcrumbRoute={breadcrumbRoute}>
        <PageHeader title="Blood Request" />
      </BreadCrumb>
      <ScrollCard>
        <AddBloodReqForm />
      </ScrollCard>
    </div>
  );
}

export default BloodRequisition;
