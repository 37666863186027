import { Launch } from '@mui/icons-material'
import { Tooltip } from '@mui/material'
import moment from 'moment'
import { useHistory } from 'react-router-dom'

export const DonorInfoSection = ({ data }) => {
  const history = useHistory()
  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: '1fr 3fr',
        gap: '1rem'
      }}
    >
      <div>
        <div
          className='profile-description1 profile-picc'
          style={{
            height: '200px'
            //  backgroundColor: '#E9EDF3'
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              gap: '1rem'
            }}
          >
            <img
              style={{
                height: '50%',
                borderRadius: '1rem'
              }}
              src={
                data?.gender
                  ? data?.gender === 'Male'
                    ? `/assets/images/avatars/1.png`
                    : `/assets/images/avatars/8.png`
                  : `/assets/images/donor_profile/profile.png`
              }
              alt='profile-pic'
            />

            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '1rem'
              }}
            >
              <div style={{ display: 'flex', fontSize: '14px' }}>
                <Tooltip title='Gender' className='general-black'>
                  <img
                    src={'/assets/images/contact/gender-neutral.svg'}
                    style={{ width: '15px', height: '15px', marginRight: '5px', textWrap: 'wrap' }}
                    alt='Gender'
                  />
                </Tooltip>
                <span>{data?.gender || 'N/A'}</span>
              </div>
              <div style={{ display: 'flex', fontSize: '14px' }}>
                <Tooltip title='Blood Group' className='general-black'>
                  <img
                    src={'/assets/images/all-donor-filters/Ttiblood.svg'}
                    style={{
                      width: '15px',
                      height: '15px',
                      marginRight: '5px',
                      textWrap: 'wrap',
                      filter: 'grayscale(100%)'
                    }}
                    alt='Blood Group'
                  />
                </Tooltip>
                <span>{data?.blood_group || 'N/A'}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className='profile-description1' style={{ height: '200px' }}>
          {/* <p className='general-black'>Basic Information</p> */}
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            {data?.abha_id ? (
              <Tooltip className='general-black' title={'Go To Donor Profile'}>
                <div
                  onClick={() => {
                    history.push(`/donors-detail/${data?.abha_id}`)
                  }}
                  className='pic-name'
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: '0.5rem',
                    cursor: 'pointer'
                  }}
                >
                  <div className='pic-name'>{data?.name || 'N/A'}</div>
                  <Launch />
                </div>
              </Tooltip>
            ) : (
              <div className='pic-name'>{data?.name || 'N/A'}</div>
            )}
            {/* <div>
                  <Tooltip className='general-black' title={'Edit'}>
                    <img
                      src={'/assets/images/donor_profile/editbtn.svg'}
                      alt='edit'
                      className='edit'
                      style={{ cursor: 'pointer', width: '24px', height: '24px' }}
                      name='edit'
                      onClick={handleEdit}
                    />
                  </Tooltip>
                </div> */}
          </div>
          {/* {currenttab === 1 && isOpen ? null : (
                <div
                  style={{
                    color: '#13BCAB',
                    fontSize: '14px',
                    fontWeight: 500,
                    fontFamily: 'inter',
                    textDecoration: 'underline',
                    marginLeft: '5px',
                    cursor: 'pointer',
                    marginTop: '0.5rem',
                    marginBottom: '0.5rem'
                  }}
                  onClick={() => {
                    setOpen(true)
                    // getBagInfo('timeline')
                  }}
                >
                  View Timeline
                </div>
              )} */}
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              gap: '1rem',
              marginTop: '1rem'
            }}
          >
            <div style={{ display: 'flex', fontSize: '14px' }}>
              <Tooltip title='Mobile' className='general-black'>
                <img
                  src={'/assets/images/contact/phone-call.svg'}
                  style={{ width: '15px', height: '15px', marginRight: '5px', textWrap: 'wrap' }}
                  alt='Mobile'
                />
              </Tooltip>
              <span>{data?.mobile ? `+91 ${data?.mobile}` : 'N/A'}</span>
            </div>
            <div style={{ display: 'flex', fontSize: '14px' }}>
              <Tooltip title='Email' className='general-black'>
                <img
                  src={'/assets/images/contact/mail.svg'}
                  style={{
                    width: '15px',
                    height: '15px',
                    marginRight: '5px',
                    textWrap: 'wrap',
                    filter: 'grayscale(100%)'
                  }}
                  alt='Email'
                />
              </Tooltip>
              <span>{data?.email || 'N/A'}</span>
            </div>
            <div style={{ display: 'flex', fontSize: '14px' }}>
              <Tooltip title='DoB / Age' className='general-black'>
                <img
                  src={'/assets/images/contact/calendar (1).svg'}
                  style={{
                    width: '15px',
                    height: '15px',
                    marginRight: '5px',
                    textWrap: 'wrap',
                    filter: 'grayscale(100%)'
                  }}
                  alt='DoB / Age'
                />
              </Tooltip>
              <span>
                {data?.dob ? (
                  `${moment(data?.dob).format('DD MMM YYYY')}(${data?.age}yrs)`
                ) : (
                  <>{data?.age || 'N/A'} Yr </>
                )}
              </span>
            </div>
            <div style={{ display: 'flex', fontSize: '14px' }}>
              <Tooltip title='Martial Status' className='general-black'>
                <img
                  src={'/assets/images/contact/marriage.svg'}
                  style={{
                    width: '15px',
                    height: '15px',
                    marginRight: '5px',
                    textWrap: 'wrap',
                    filter: 'grayscale(100%)'
                  }}
                  alt='Martial Status'
                />
              </Tooltip>
              <span>{data?.martial_status === 'Married' ? 'Yes' : 'No' || 'N/A'}</span>
            </div>
            <div style={{ display: 'flex', fontSize: '14px' }}>
              <Tooltip title='Spouse Name' className='general-black'>
                <img
                  src={'/assets/images/contact/husbands 1.svg'}
                  style={{
                    width: '15px',
                    height: '15px',
                    marginRight: '5px',
                    textWrap: 'wrap',
                    filter: 'grayscale(100%)'
                  }}
                  alt='Spouse Name'
                />
              </Tooltip>
              <span>{data?.spouse_name || 'N/A'}</span>
            </div>
            <div style={{ display: 'flex', fontSize: '14px' }}>
              <Tooltip title='Location' className='general-black'>
                <img
                  src={'/assets/images/contact/location.svg'}
                  style={{
                    width: '15px',
                    height: '15px',
                    marginRight: '5px',
                    textWrap: 'wrap',
                    filter: 'grayscale(100%)'
                  }}
                  alt='Location'
                />
              </Tooltip>
              <span>{`${data?.area || ''} ${data?.city || ''} ${data?.district || ''} ${data?.state || ''} India ${data?.pincode || ''}`}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
