import React from "react";
import { useEffect } from "react";
import { useState } from "react";

export const LabelDiv = ({ title, children, style }) => {
  return (
    <div style={style} className="reg-box w-100 position-relative ">
      {title ? <p className="div_label div_label_text">{title}</p> : null}
      {children}
    </div>
  );
};

export const ColumnDetail = ({ title, label, err }) => {
  return (
    <div className="col align-items-center ">
      <label>{label}</label>
      <h6 className={err ? "text-danger" : ""}>{title}</h6>
    </div>
  );
};
export const LargeColumnDetail = ({ title, label }) => {
  let answer = Array.isArray(title);
  return (
    <div className="row">
      <div className="col align-items-center ">
        <label>{label}</label>
      </div>
      <div className="col align-items-center ">
        {answer === true ? (
          title?.length > 0 ? (
            <div className="d-flex">
              {title?.map((item, i) => (
                <h6 key={i} className="text-danger">
                  {item}
                  {i + 1 < title?.length ? " , " : ""}
                </h6>
              ))}
            </div>
          ) : (
            <h6>No</h6>
          )
        ) : (
          <>
            {label ===
              "Did you have any record of previous Blood Donation(s) ?" ? (
              <h6 style={{ color: title !== "Yes" ? "black" : "red" }}>
                {title}
              </h6>
            ) : (
              <>
                {label === "Did you sleep well last night ?" ? (
                  <h6 style={{ color: title === "Yes" ? "black" : "red" }}>
                    {title}
                  </h6>
                ) : <>{label === "Have you eaten anything in the last 4 hours ?" ?
                  <h6 style={{ color: title === "Yes" ? "black" : "red" }}>
                    {title}
                  </h6> :
                  (<h6 style={{ color: title === "Yes" ? "red" : "black" }}>
                    {title}
                  </h6>)}
                </>}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

const ScreeningDonorDetails = ({ previewData, profile, info }) => {
  const [dieases, setDiseases] = useState([]);
  const [declarations, setDeclarations] = useState([]);
  const [SDPData, setSDPData] = useState({});
  console.log(previewData);
  useEffect(() => {
    if (previewData) {
      if (previewData?.dieases !== null) {
        let dis = JSON.parse(previewData?.dieases);
        setDiseases(dis);
      }
      if (previewData?.declration !== null) {
        let dec = JSON.parse(previewData?.declration);
        setDeclarations(dec);
      }
      if (previewData && previewData?.checkup_detail) {
        let sdp = JSON.parse(previewData?.checkup_detail)
        setSDPData(sdp)
      }
      console.log(declarations);
    }
  }, [previewData]);

  return (
    <div className="form flex-column ">
      <LabelDiv title="Donor Type">
        <div className="row">
          <LargeColumnDetail label={previewData?.donor_type || ""} title="" />
        </div>
      </LabelDiv>
      <LabelDiv title="Body Vitals">
        <div className="row">
          <ColumnDetail
            label="Hemoglobin Level"
            title={previewData?.hemoglobin_level || "N/A"}
            err={
              Number(previewData?.hemoglobin_level) < 12.5 || Number(previewData?.hemoglobin_level) > 18
            }
          />
          <ColumnDetail
            label="Weight"
            title={previewData?.weight || "N/A"}
            err={previewData?.weight < 45}
          />
          <ColumnDetail
            label="Temperature"
            title={previewData?.temprature || "N/A"}
            err={previewData?.temprature != "98"}
          />
          {/* <ColumnDetail
            label="Sugar Level"
            title={previewData?.sugar_level || "N/A"}
            err={
              previewData?.sugar_level < 70 || previewData?.sugar_level > 140
            }
          /> */}

          <ColumnDetail
            label="Blood Pressure"
            title={previewData?.blood_pressure || "N/A"}
            err={previewData?.blood_pressure != "120/80"}
          />


          <ColumnDetail
            label="Pulse"
            title={previewData?.pulse || "N/A"}
            err={previewData?.pulse < 72 || previewData?.pulse > 90}
          />
        </div>
        {previewData?.donor_type === "SDP" ?
          <div className="row">
            <ColumnDetail
              label="WBC (10^3/uL)"
              title={SDPData?.wbc || "N/A"}
              err={SDPData?.wbc < 4 || SDPData?.wbc > 10}
            />
            <ColumnDetail
              label="RBC (10^6/uL)"
              title={SDPData?.rbc || "N/A"}
              err={SDPData?.rbc < 3.50 || SDPData?.rbc > 5.50}
            />
            <ColumnDetail
              label="HCT (%)"
              title={SDPData?.hct || "N/A"}
              err={SDPData?.hct < 36 || SDPData?.hct > 48}
            />
            <ColumnDetail
              label="PLT (10^3/uL)"
              title={SDPData?.plt || "N/A"}
              err={SDPData?.plt < 150 || SDPData?.plt > 450}
            />
            <ColumnDetail
            />
          </div> : null}
      </LabelDiv>
      <LabelDiv title="Diseases">
        {dieases?.length > 0 ? (
          dieases?.map((item, i) => (
            <LargeColumnDetail key={i} label={item || ""} title="" />
          ))
        ) : (
          <LargeColumnDetail label="Nil" title="" />
        )}
      </LabelDiv>
      {declarations?.length > 0 ? (
        <LabelDiv title="Other Information">
          {declarations?.map((item, i) => (
            <>
              {item.gender == "Both" && (
                <LargeColumnDetail
                  key={i}
                  label={item?.label}
                  title={item?.value}
                />
              )}
              {item.gender == info?.gender && (
                <LargeColumnDetail
                  key={i}
                  label={item?.label}
                  title={item?.value}
                />
              )}
              {item.gender == undefined && (
                <LargeColumnDetail
                  key={i}
                  label={item?.label}
                  title={item?.value}
                />
              )}
            </>
          ))}
        </LabelDiv>
      ) : null}
    </div>
  );
};

export default ScreeningDonorDetails;