import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react'
import SideDrawer, { Header } from '../shared/SideDrawer'
import {
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
  FormControlLabel,
  Checkbox,
  FormGroup,
  Autocomplete,
  ListItemText,
  Select,
  FormLabel,
  Radio
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { Close, DehazeSharp, Delete, FilterList, Margin } from '@mui/icons-material'
import { useEffect } from 'react'
import { inputStyles, labelStyles } from '../certification/EditCertificate'
import { RHFDatePicker } from '../shared/RHFDatePicker'
import moment from 'moment'
import dayjs from 'dayjs'
import { useForm } from 'react-hook-form'

export const IconButtonStyle = {
  width: '100%',
  borderRadius: '5px'
}

const radioStyles = {
  '&.MuiCheckbox-indeterminate,&.Mui-checked': {
    color: '#214F8A'
  }
}

const InputEndProps = {
  endAdornment: (
    <InputAdornment
      position='end'
      sx={{
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <img alt='' src={'/assets/images/search-icon.svg'} />
    </InputAdornment>
  )
}

const FilterBox = ({ search, option, deletehistory, index }) => {
  return (
    <Grid item xs={12}>
      <Card
        sx={{
          border: '1px dashed #B3B3B3',
          background: '#EBF6F6',
          borderRadius: 1,
          paddingLeft: 2,
          paddingRight: 1,
          py: 1
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Typography className='filter-head' fontSize='1rem'>
            {option}
          </Typography>
          <Tooltip title='Delete'>
            <IconButton color='error' name={index} onClick={() => deletehistory(index)}>
              <Delete />
            </IconButton>
          </Tooltip>
        </Box>
        {option === 'Request Date' ? (
          <Typography className='filter-value'>
            Must contain{' '}
            <span className='filter-value-sub'>
              “
              {`${dayjs(search?.request_start).format('DD MMM YYYY ')} to ${dayjs(search?.request_end).format(' DD MMM YYYY')}`}
              ”
            </span>
          </Typography>
        ) : option === 'Issue Date' ? (
          <Typography className='filter-value'>
            Must contain{' '}
            <span className='filter-value-sub'>
              “
              {`${dayjs(search?.issue_start).format('DD MMM YYYY ')} to ${dayjs(search?.issue_end).format(' DD MMM YYYY')}`}
              ”
            </span>
          </Typography>
        ) : (
          <Typography className='filter-value'>
            Must contain <span className='filter-value-sub'>“{search?.toString()}”</span>
          </Typography>
        )}
      </Card>
    </Grid>
  )
}

const filterLabel = {
  color: '#444',
  fontFamily: 'Inter',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '20px',
  whiteSpace: 'normal',
  MarginBottom: '0'
}
export const active = {
  background: 'var(--default, linear-gradient(89deg, #204E89 0%, #12B9AA 100%))',
  color: '#FFFFFF',
  borderRadius: '0'
}

const unactive = {
  color: '#000000'
}

const reqtype = [
  { label: 'Urgent', value: 'urgent' },
  { label: 'Routine', value: 'routine' },
  { label: 'Reserved', value: 'Reserved' }
]

const bloodGroup = [
  { value: 'AB-Ve', label: 'AB-Ve' },
  { value: 'AB+Ve', label: 'AB+Ve' },
  { value: 'A+Ve', label: 'A+Ve' },
  { value: 'A-Ve', label: 'A-Ve' },
  { value: 'B+Ve', label: 'B+Ve' },
  { value: 'B-Ve', label: 'B-Ve' },
  { value: 'O-Ve', label: 'O-Ve' },
  { value: 'O+Ve', label: 'O+Ve' }
]

const columnArr = [
  'Hospital patient ID',
  'Father/Husband Name',
  'Hospital Name',
  'Hospital Location',
  'Patient Blood Group',
  'Blood Component Name',
  'Volume',
  'Unit',
  'Reason Of Request',
  'Blood Required Date',
  'Resquest Order By',
  'Blood Sample Id'
]

const rowArr = [
  { label: 'Request Order Id', value: 'request_id' },
  { label: 'Hospital patient ID', value: 'patient_id' },
  { label: 'Patient Name', value: 'patient_name' },
  { label: 'Father/Husband Name', value: 'father_name' },
  { label: 'Hospital Name', value: 'hospital_name' },
  { label: 'Reason Of Request', value: 'request_type' },
  { label: 'Request Order By', value: 'request_orderby' },
  { label: 'Patient Blood Group', value: 'blood_group' },
  { label: 'Blood Sample Id', value: 'sample_id' },
  { label: 'Request Date', value: 'request_date' },
  { label: 'Issue Date', value: 'issue_date' }
]

const Filters = ({
  open,
  setOpen,
  columnlist,
  handleColumnFilter,
  resetAllFilters,
  setFilters,
  resetFilter,
  setParentFiltersView
}) => {
  const [filterRow, setFilterRow] = useState(true)
  const [selectedItem, setSelectedItem] = useState([])
  const [selectOption, setSelectOption] = useState('')
  const [rowInputSearch, setRowInputSearch] = useState('')
  const [filterhistory, setFilterHistory] = useState([])
  const [optionalSelect, setOptionalSelect] = useState([])
  const [requestDateFilter, setRequestDateFilter] = useState({
    request_start: dayjs(new Date()),
    request_end: dayjs(new Date()),
    is_apply: false
  })
  const [issueDateFilter, setIssueDateFilter] = useState({
    issue_start: dayjs(new Date()),
    issue_end: dayjs(new Date()),
    is_apply: false
  })
  const methods = useForm({
    //defaultValues,
    mode: 'onChange'
    //resolver: yupResolver(schema),
  })
  useEffect(() => {
    if (resetFilter?.type === 'resetAll') {
      resetAll()
    } else if (resetFilter?.type === 'deleteHistory') {
      deletehistory(resetFilter?.id)
    }
  }, [resetFilter])
  useEffect(() => {
    setSelectedItem(columnlist)
  }, [open])

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    getValues,
    setFocus,
    reset,
    formState: { errors }
  } = methods
  const handleCheckbox = e => {
    let isSelected = e.target.checked
    let val = e.target.value
    if (isSelected) {
      setSelectedItem([...selectedItem, val])
    } else {
      setSelectedItem(prevData => {
        return prevData.filter(camp_id => {
          return camp_id !== val
        })
      })
    }
  }
  const resetAll = () => {
    setRowInputSearch('')
    setSelectOption('')
    let selected = JSON.stringify(columnArr)
    localStorage.setItem('column', selected)
    setSelectedItem(columnArr)
    setParentFiltersView([])
    //handleColumnFilter(columnArr);

    resetAllFilters()
    setRequestDateFilter({
      request_start: dayjs(new Date()),
      request_end: dayjs(new Date()),
      is_apply: false
    })
    setIssueDateFilter({
      issue_start: dayjs(new Date()),
      issue_end: dayjs(new Date()),
      is_apply: false
    })
    setFilterHistory([])
    setFilters([])
  }

  const handleColumnFilterr = () => {
    let selected = JSON.stringify(selectedItem)
    localStorage.setItem('column', selected)
    handleColumnFilter(selectedItem)
    setOpen(false)
  }
  const deletehistory = id => {
    const a = filterhistory.filter((ele, i) => i !== id)
    console.log(a)
    setFilterHistory(a)
    setFilters(a)
    setParentFiltersView(a)
  }

  const handleAdd = () => {
    var filtereddata = filterhistory.filter(elemet => elemet.searchoption.value !== selectOption?.value)
    if (selectOption.label === 'Reason Of Request' && optionalSelect.length > 0) {
      console.log('true')

      setParentFiltersView([
        ...filtereddata,
        {
          searchoption: selectOption,
          searchtext: optionalSelect,
          DateFilter: requestDateFilter,
          DateIssueFilter: issueDateFilter
        }
      ])
      setFilterHistory([
        ...filtereddata,
        {
          searchoption: selectOption,
          searchtext: optionalSelect,
          DateFilter: requestDateFilter,
          DateIssueFilter: issueDateFilter
        }
      ])

      setSelectOption('')
      setRowInputSearch('')
      setFilters([
        ...filtereddata,
        {
          searchoption: selectOption,
          searchtext: optionalSelect,
          DateFilter: requestDateFilter,
          DateIssueFilter: issueDateFilter
        }
      ])
    }
    if (selectOption.label === 'Patient Blood Group' && optionalSelect.length > 0) {
      let arr = []
      optionalSelect.forEach(ele => {
        switch (ele) {
          case 'AB-Ve':
            arr.push('abn')
            break
          case 'AB+Ve':
            arr.push('abp')
            break
          case 'A+Ve':
            arr.push('ap')
            break
          case 'A-Ve':
            arr.push('an')
            break
          case 'B+Ve':
            arr.push('bp')
            break
          case 'B-Ve':
            arr.push('bn')
            break
          case 'O+Ve':
            arr.push('op')
            break
          case 'O-Ve':
            arr.push('on')
            break
          default:
            break
        }
      })

      setFilterHistory([
        ...filtereddata,
        {
          searchoption: selectOption,
          searchtext: optionalSelect,
          DateFilter: requestDateFilter,
          DateIssueFilter: issueDateFilter
        }
      ])
      setSelectOption('')
      setRowInputSearch('')
      setFilters([
        ...filtereddata,
        {
          searchoption: selectOption,
          searchtext: arr,
          DateFilter: requestDateFilter,
          DateIssueFilter: issueDateFilter
        }
      ])
      setParentFiltersView([
        ...filtereddata,
        {
          searchoption: selectOption,
          searchtext: optionalSelect,
          DateFilter: requestDateFilter,
          DateIssueFilter: issueDateFilter
        }
      ])
    }
    if (selectOption.label.length > 0 && rowInputSearch.length > 0) {
      setFilterHistory([
        ...filtereddata,
        {
          searchoption: selectOption,
          searchtext: rowInputSearch,
          DateFilter: requestDateFilter,
          DateIssueFilter: issueDateFilter
        }
      ])
      setSelectOption('')
      setRowInputSearch('')
      setFilters([
        ...filtereddata,
        {
          searchoption: selectOption,
          searchtext: rowInputSearch,
          DateFilter: requestDateFilter,
          DateIssueFilter: issueDateFilter
        }
      ])
      setParentFiltersView([
        ...filtereddata,
        {
          searchoption: selectOption,
          searchtext: rowInputSearch,
          DateFilter: requestDateFilter,
          DateIssueFilter: issueDateFilter
        }
      ])
    }
    if (selectOption.label === 'Request Date') {
      setFilterHistory([
        ...filtereddata,
        {
          searchoption: selectOption,
          searchtext: rowInputSearch,
          DateFilter: { ...requestDateFilter, is_apply: true },
          DateIssueFilter: issueDateFilter
        }
      ])
      setFilters([
        ...filtereddata,
        {
          searchoption: selectOption,
          searchtext: rowInputSearch,
          DateFilter: { ...requestDateFilter, is_apply: true },
          DateIssueFilter: issueDateFilter
        }
      ])
      setParentFiltersView([
        ...filtereddata,
        {
          searchoption: selectOption,
          searchtext: rowInputSearch,
          DateFilter: { ...requestDateFilter, is_apply: true },
          DateIssueFilter: issueDateFilter
        }
      ])
    }
    if (selectOption.label === 'Issue Date') {
      setFilterHistory([
        ...filtereddata,
        {
          searchoption: selectOption,
          searchtext: rowInputSearch,
          DateIssueFilter: { ...issueDateFilter, is_apply: true },
          DateFilter: requestDateFilter
        }
      ])
      setFilters([
        ...filtereddata,
        {
          searchoption: selectOption,
          searchtext: rowInputSearch,
          DateIssueFilter: { ...issueDateFilter, is_apply: true },
          DateFilter: requestDateFilter
        }
      ])
      setParentFiltersView([
        ...filtereddata,
        {
          searchoption: selectOption,
          searchtext: rowInputSearch,
          DateIssueFilter: { ...issueDateFilter, is_apply: true },
          DateFilter: requestDateFilter
        }
      ])
    }
  }

  const multiple = event => {
    const {
      target: { value }
    } = event
    console.log(value)
    setOptionalSelect(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    )
  }
  let validation = {
    shouldValidate: true,
    shouldDirty: true
  }
  const OnDateHandler = (e, id) => {
    if (id === 'request_start') {
      setRequestDateFilter({
        ...requestDateFilter,
        request_start: dayjs(e)
      })
    } else if (id === 'request_end') {
      setRequestDateFilter({
        ...requestDateFilter,
        request_end: dayjs(e)
      })
    } else if (id === 'issue_start') {
      setIssueDateFilter({
        ...issueDateFilter,
        issue_start: dayjs(e)
      })
    } else if (id === 'issue_end') {
      setIssueDateFilter({
        ...issueDateFilter,
        issue_end: dayjs(e)
      })
    }
  }
  const handleRadio = e => {
    setValue('filter_month_type', e.target.value, validation)
    var d1 = moment()
    var d2 = moment()
    if (e.target.value === 'last_month') {
      d1 = moment().subtract(1, 'month')
    } else if (e.target.value === 'last_three_month') {
      d1 = moment().subtract(3, 'month')
    } else if (e.target.value === 'last_six_month') {
      d1 = moment().subtract(6, 'month')
    } else if (e.target.value === 'last_one_year') {
      d1 = moment().subtract(12, 'month')
    }
    if (selectOption.label === 'Request Date') {
      setRequestDateFilter({
        ...requestDateFilter,
        request_start: dayjs(new Date(d1.format())),
        request_end: dayjs(new Date(d2.format()))
      })
    } else {
      setIssueDateFilter({
        ...issueDateFilter,
        issue_start: dayjs(new Date(d1.format())),
        issue_end: dayjs(new Date(d2.format()))
      })
    }
  }
  return (
    <SideDrawer open={open} customWidth={400} handleClose={() => setOpen(false)} title='Add Filters'>
      <Box sx={{ display: 'flex', position: 'relative' }}>
        {/* <Box
          sx={{
            width: "4rem",
            height: "100vh",
            borderRight: "1px solid #d1d5db",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <IconButton color="primary" sx={filterRow ? active : unactive}
          >
            <FilterList onClick={() => { setFilterRow(true) }} />
          </IconButton>
          <IconButton color="primary" sx={!filterRow ? active : unactive}>
            <DehazeSharp sx={{ transform: "rotate(90deg)" }} 
            //onClick={() => { setFilterRow(false) }}
             />
          </IconButton>
        </Box> */}

        {filterRow ? (
          <Box sx={{ width: '100%' }}>
            <Header>
              <Typography fontSize='1.7rem' variant='h6'>
                Filters
              </Typography>
              <Close fontSize='medium' onClick={() => setOpen(false)} sx={{ cursor: 'pointer' }} />
            </Header>
            <Box sx={{ px: { xs: 2, sm: 4 } }}>
              <InputLabel sx={filterLabel}>Select Column</InputLabel>
              <Autocomplete
                autoHighlight
                size='small'
                disableClearable={true}
                options={rowArr}
                id='appBar-search'
                isOptionEqualToValue={() => true}
                value={selectOption}
                onChange={(event, obj) => {
                  setValue('filter_month_type', null, validation)
                  setSelectOption(obj)
                  setOptionalSelect([])
                  setRowInputSearch('')
                  console.log(obj)
                }}
                getOptionLabel={option => option.label || ''}
                sx={{
                  boxShadow: 'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px',
                  '& + .MuiAutocomplete-popper': {}
                }}
                renderInput={params => {
                  return (
                    <TextField
                      {...params}
                      placeholder='Enter column name'
                      sx={inputStyles}
                      // required
                    />
                  )
                }}
              />

              {/* <TextField                  
                  sx={{mt:2,...inputStyles}}
                  fullWidth
                  select
                  type="text"
                  placeholder="Enter District"
                  name="district"
                  onChange={(event, obj) => setOptionalSelect(obj)}
                  value={optionalSelect}
                  // required
                 
                >{selectOption.label === "Reason Of Request"?<>
                  reqtype.map((e,i)=(
                    <MenuItem value="">No District found</MenuItem>
                  ))
                </>:<></>}
                    
                  
                </TextField>
                */}

              {selectOption.label === 'Reason Of Request' || selectOption.label === 'Patient Blood Group' ? (
                <>
                  {/* <Autocomplete
                  autoHighlight
                  multiple
                  size="small"
                  disableClearable={true}
                  options={selectOption.label === "Reason Of Request"?reqtype:bloodGroup}
                  id="app"
                  isOptionEqualToValue={() => true}
                  value={optionalSelect}
                  onChange={(event, obj) => 
                  handleStatusChange(event,obj)
                  
                   }
                  getOptionLabel={(option) => option.label || ""}
                  sx={{
                    mt: 2,
                    boxShadow:
                      "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px",
                    "& + .MuiAutocomplete-popper": {
                      mt: 2,
                    },
                  }}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        placeholder="Enter column name"
                        sx={inputStyles}
                      // required

                      />
                    );
                  }}

                /> */}
                  {selectOption.label === 'Reason Of Request' ? (
                    <Select
                      sx={{ height: '42px', mt: 2, ...inputStyles }}
                      multiple
                      fullWidth
                      InputLabelProps={{ shrink: false }}
                      value={optionalSelect}
                      onChange={e => {
                        multiple(e)
                      }}
                      renderValue={selected => selected.join(',')}
                    >
                      {reqtype?.map((d, i) => (
                        <MenuItem key={i} value={d?.label}>
                          <Checkbox sx={radioStyles} checked={optionalSelect.indexOf(d?.label) > -1} />
                          <ListItemText primary={d?.label} />
                        </MenuItem>
                      ))}
                    </Select>
                  ) : (
                    <Select
                      sx={{ height: '42px', mt: 2, ...inputStyles }}
                      multiple
                      fullWidth
                      InputLabelProps={{ shrink: false }}
                      value={optionalSelect}
                      onChange={e => {
                        multiple(e)
                      }}
                      renderValue={selected => selected.join(',')}
                    >
                      {bloodGroup?.map((d, i) => (
                        <MenuItem key={i} value={d?.value}>
                          <Checkbox sx={radioStyles} checked={optionalSelect.indexOf(d?.value) > -1} />
                          <ListItemText primary={d?.label} />
                        </MenuItem>
                      ))}
                    </Select>
                  )}

                  {/* <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={personName}
          onChange={handleChange}
          input={<OutlinedInput label="Tag" />}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}
        >
          {names.map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox 

                sx={radioStyles}
              checked={personName.indexOf(name) > -1} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select> */}
                </>
              ) : selectOption.label === 'Request Date' || selectOption.label === 'Issue Date' ? (
                <>
                  <Box sx={{ mt: 2 }}>
                    <Grid container>
                      <Grid item xs={6} sm={6} md={6} lg={6}>
                        <Radio
                          inputProps={{
                            'aria-label': 'last_month'
                          }}
                          sx={radioStyles}
                          checked={watch('filter_month_type') === 'last_month'}
                          onChange={handleRadio}
                          value='last_month'
                          name='filter_month_type'
                        />
                        <FormLabel
                          className='filter-head'
                          sx={{ ...labelStyles, mr: '25px' }}
                          id='demo-row-radio-buttons-group-label'
                        >
                          Last Month
                        </FormLabel>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6} lg={6}>
                        <Radio
                          inputProps={{
                            'aria-label': 'last_three_month'
                          }}
                          sx={radioStyles}
                          checked={watch('filter_month_type') === 'last_three_month'}
                          onChange={handleRadio}
                          value='last_three_month'
                          name='filter_month_type'
                        />
                        <FormLabel
                          className='filter-head'
                          sx={{ ...labelStyles, mr: '25px' }}
                          id='demo-row-radio-buttons-group-label'
                        >
                          Last 3 Month
                        </FormLabel>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6} lg={6}>
                        <Radio
                          inputProps={{
                            'aria-label': 'last_six_month'
                          }}
                          sx={radioStyles}
                          checked={watch('filter_month_type') === 'last_six_month'}
                          onChange={handleRadio}
                          value='last_six_month'
                          name='filter_month_type'
                        />
                        <FormLabel
                          className='filter-head'
                          sx={{ ...labelStyles, mr: '25px' }}
                          id='demo-row-radio-buttons-group-label'
                        >
                          Last 6 Month
                        </FormLabel>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6} lg={6}>
                        <Radio
                          inputProps={{
                            'aria-label': 'last_one_year'
                          }}
                          sx={radioStyles}
                          checked={watch('filter_month_type') === 'last_one_year'}
                          onChange={handleRadio}
                          value='last_one_year'
                          name='filter_month_type'
                        />
                        <FormLabel
                          className='filter-head'
                          sx={{ ...labelStyles, mr: '25px' }}
                          id='demo-row-radio-buttons-group-label'
                        >
                          Last 1 Year
                        </FormLabel>
                      </Grid>
                      <Grid item sx={{ mt: 2 }} xs={12} sm={12} md={12} lg={12}>
                        <Typography className='filter-head cms-2'>Or select a custom date of your choice</Typography>
                      </Grid>
                    </Grid>
                    <Grid sx={{ mt: 2 }} item xs={12} sm={12} md={6} lg={6} display={'flex'}>
                      <DatePicker
                        className='cme-2'
                        format='DD/MM/YYYY'
                        slotProps={{
                          textField: {
                            size: 'small',
                            sx: { maxWidth: 170, background: '#fff' }
                          }
                        }}
                        value={
                          selectOption.label === 'Request Date'
                            ? requestDateFilter?.request_start
                            : issueDateFilter?.issue_start
                        }
                        onChange={value =>
                          OnDateHandler(
                            value,
                            `${selectOption.label === 'Request Date' ? 'request_start' : 'issue_start'}`
                          )
                        }
                        label='From'
                      />
                      <DatePicker
                        className='cms-2'
                        format='DD/MM/YYYY'
                        slotProps={{
                          textField: {
                            size: 'small',
                            sx: { maxWidth: 170, background: '#fff' }
                          }
                        }}
                        value={
                          selectOption.label === 'Request Date'
                            ? requestDateFilter?.request_end
                            : issueDateFilter?.issue_end
                        }
                        minDate={
                          selectOption.label === 'Request Date'
                            ? requestDateFilter?.request_start
                            : issueDateFilter?.issue_start
                        }
                        onChange={value =>
                          OnDateHandler(value, `${selectOption.label === 'Request Date' ? 'request_end' : 'issue_end'}`)
                        }
                        label='To'
                      />
                    </Grid>
                  </Box>
                </>
              ) : (
                <TextField
                  size='small'
                  placeholder='Search Value'
                  fullWidth
                  sx={{ mt: 2, ...inputStyles }}
                  InputProps={InputEndProps}
                  value={rowInputSearch}
                  onChange={e => {
                    setRowInputSearch(e.target.value)
                  }}
                />
              )}

              <Box sx={{ mt: 2 }} display='flex'>
                <button type='button' className='btn gradient-btn' onClick={() => handleAdd()}>
                  Add Filter
                </button>
                <Button
                  size='large'
                  variant='text'
                  color='primary'
                  onClick={() => {
                    setRowInputSearch('')
                    setSelectOption('')
                  }}
                >
                  Reset
                </Button>
              </Box>
            </Box>
            <Header>
              <Typography>Added Filters</Typography>
              <Typography size='large' sx={{ fontWeight: 600, cursor: 'pointer' }} color='primary' onClick={resetAll}>
                Reset All Filters
              </Typography>
            </Header>
            <Grid container spacing={3} sx={{ px: { xs: 2, sm: 4 } }}>
              {filterhistory.map((ele, idx) =>
                ele?.searchoption?.label === 'Request Date' && ele?.DateFilter?.is_apply == true ? (
                  <FilterBox
                    key={idx}
                    search={ele?.DateFilter}
                    option={ele?.searchoption?.label}
                    deletehistory={deletehistory}
                    index={idx}
                  />
                ) : ele?.searchoption?.label === 'Issue Date' && ele?.DateIssueFilter?.is_apply == true ? (
                  <FilterBox
                    key={idx}
                    search={ele?.DateIssueFilter}
                    option={ele?.searchoption?.label}
                    deletehistory={deletehistory}
                    index={idx}
                  />
                ) : (
                  <FilterBox
                    key={idx}
                    search={ele?.searchtext}
                    option={ele?.searchoption?.label}
                    deletehistory={deletehistory}
                    index={idx}
                  />
                )
              )}
            </Grid>
          </Box>
        ) : null}

        {!filterRow ? (
          <>
            <Box sx={{ width: '100%' }}>
              <Header>
                <Typography fontSize='1.7rem' variant='h6'>
                  Column
                </Typography>
                <Close fontSize='medium' onClick={() => setOpen(false)} sx={{ cursor: 'pointer' }} />
              </Header>
              <Box sx={{ px: { xs: 2, sm: 4 } }}>
                <InputLabel sx={filterLabel}>Select columns that you want to see on the table.</InputLabel>

                <TextField
                  size='small'
                  placeholder='Search Column'
                  fullWidth
                  sx={{ mt: 2, ...inputStyles }}
                  InputProps={InputEndProps}
                  // value={rowInputSearch}
                  onChange={e => {
                    console.log(e)
                  }}
                />
              </Box>
              <Box sx={{ px: { xs: 2, sm: 4 }, mt: 2 }}>
                <FormGroup>
                  {columnArr.map((ele, idx) => (
                    <FormControlLabel
                      key={idx}
                      control={
                        <Checkbox
                          sx={radioStyles}
                          onChange={handleCheckbox}
                          checked={selectedItem.includes(ele)}
                          value={ele}
                        />
                      }
                      label={ele}
                    />
                  ))}
                </FormGroup>
              </Box>
              <Box
                sx={{
                  position: 'sticky',
                  bottom: 0,
                  px: { xs: 2, sm: 4 },
                  width: '100%',
                  background: 'white',
                  pt: 2,
                  pb: 2
                }}
              >
                <button type='button' className='btn gradient-btn' onClick={handleColumnFilterr}>
                  Apply Filter
                </button>
              </Box>
            </Box>
          </>
        ) : null}
      </Box>
    </SideDrawer>
  )
}

export default Filters
