import React, { Component, useEffect } from 'react'
import { Link, withRouter, useHistory } from 'react-router-dom'
import { Trans } from 'react-i18next'
import { motion } from 'framer-motion'

import {
  getName,
  getTenatName,
  checkPermission,
  getEntityInfo,
  getTenatNameSans,
  getInitials,
  getDonorNameSans,
  getDonorIdSans
} from '../middleware/auth'
import { Collapse } from '@mui/material'
import { ChevronRight, KeyboardArrowDown } from '@mui/icons-material'
import { useAppContext } from '../../AppContext'
import { useLocation } from 'react-router-dom'
import { ScrollWrapper } from './ScrollWrapper'
import { useSettings } from '../../hooks/useSetting'

export function SideMenuCustom({ active, title, IconMui, icon, path, clickFn, className, navCollapsed, navHover }) {
  return (
    <li className={active ? 'nav-item active' : 'nav-item'}>
      <Link
        onClick={clickFn}
        className={`nav-link d-flex justify-content-start align-items-start w-100 mx-0 ${className} ${navCollapsed && !navHover ? 'center_imp' : ''}`}
        to={path}
      >
        <div className='sidebar-list-items'>
          {IconMui ? IconMui : <img src={icon || '/assets/images/sidebar/blood.png'} alt='Blood Managment' />}
          {navCollapsed && !navHover ? null : (
            <span className='menu-title'>
              <Trans>{title}</Trans>
            </span>
          )}
        </div>
      </Link>
    </li>
  )
}

export function SideMenuCustomCollapse({ active, title, icon, clickFn, className, children, navHover }) {
  const { ttiOpen, setTtiOpen } = useAppContext()
  const { settings } = useSettings()
  const { navCollapsed } = settings

  const location = useLocation()

  useEffect(() => {
    if (navCollapsed || !navHover) {
      setTtiOpen(false)
    }
  }, [navCollapsed, navHover])

  useEffect(() => {
    if (!location.pathname.startsWith('/lab-testing')) {
      setTtiOpen(false)
    }
  }, [location?.pathname])

  return (
    <div>
      <li
        onClick={() => setTtiOpen(!ttiOpen)}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
        className={active ? 'nav-item active' : 'nav-item'}
      >
        <div
          onClick={() => {
            clickFn()
          }}
          className={`nav-link d-flex justify-content-start align-items-start w-100 mx-0 ${className}  ${navCollapsed && !navHover ? 'center_imp' : ''}`}
        >
          <div className='sidebar-list-items'>
            <img src={icon || '/assets/images/sidebar/blood.png'} alt='Blood Managment' />

            {navCollapsed && !navHover ? null : (
              <span className='menu-title'>
                <Trans>{title}</Trans>
              </span>
            )}
          </div>
        </div>
        {navCollapsed && !navHover ? null : (
          <div className='menu-title'>
            {ttiOpen ? (
              <KeyboardArrowDown sx={{ color: 'white' }} fontSize='medium' />
            ) : (
              <ChevronRight sx={{ color: 'white' }} fontSize='medium' />
            )}
          </div>
        )}
      </li>

      <Collapse in={ttiOpen}>{children}</Collapse>
    </div>
  )
}

export function SideMenuWasteManagementCollapse({ active, title, icon, clickFn, className, children, navHover }) {
  const { wasteOpen, setWasteOpen } = useAppContext()
  const history = useHistory()
  const location = useLocation()
  const { settings } = useSettings()
  const { navCollapsed } = settings

  useEffect(() => {
    if (navCollapsed || !navHover) {
      setWasteOpen(false)
    }
  }, [navCollapsed, navHover])

  useEffect(() => {
    if (!location.pathname.startsWith('/waste-management')) {
      setWasteOpen(false)
    }
  }, [location?.pathname])

  return (
    <div>
      <li
        onClick={() => {
          setWasteOpen(!wasteOpen)
          history.push('/waste-management/waste-list')
        }}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
        className={active ? 'nav-item active' : 'nav-item'}
      >
        <div
          onClick={() => {
            clickFn()
          }}
          className={`nav-link d-flex justify-content-start align-items-start w-100 mx-0 ${className} ${navCollapsed && !navHover ? 'center_imp' : ''}`}
        >
          <div className='sidebar-list-items'>
            <img src={icon || '/assets/images/alldonors/bin.png'} alt='Waste Managment' />
            {navCollapsed && !navHover ? null : (
              <span className='menu-title'>
                <Trans>{title}</Trans>
              </span>
            )}
          </div>
        </div>
        {navCollapsed && !navHover ? null : (
          <div className='menu-title'>
            {wasteOpen ? (
              <KeyboardArrowDown sx={{ color: 'white' }} fontSize='medium' />
            ) : (
              <ChevronRight sx={{ color: 'white' }} fontSize='medium' />
            )}
          </div>
        )}
      </li>

      <Collapse in={wasteOpen}>{children}</Collapse>
    </div>
  )
}

export function SideMenuCampCustomCollapse({ active, title, icon, clickFn, className, children, navHover }) {
  const { setCampOpen, campOpen } = useAppContext()
  const history = useHistory()
  const location = useLocation()
  const { settings } = useSettings()
  const { navCollapsed } = settings

  useEffect(() => {
    if (navCollapsed || !navHover) {
      setCampOpen(false)
    }
  }, [navCollapsed, navHover])

  const handleEntered = () => {
    // Trigger a recalculation of the scrollbar when the Collapse component is fully entered
    const sidebar = document.getElementById('sidebar')
    if (sidebar) {
      sidebar.dispatchEvent(new Event('resize'))
    }
  }

  const handleExited = () => {
    // Trigger a recalculation of the scrollbar when the Collapse component is fully exited
    const sidebar = document.getElementById('sidebar')
    console.log('sidebar', sidebar)
    if (sidebar) {
      sidebar.dispatchEvent(new Event('resize'))
    }
  }

  useEffect(() => {
    if (!location.pathname.startsWith('/blood-camp-management')) {
      setCampOpen(false)
    }
  }, [location?.pathname])

  return (
    <div>
      <li
        onClick={() => {
          setCampOpen(!campOpen)
          history.push('/blood-camp-management')
        }}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
        className={active ? 'nav-item active' : 'nav-item'}
      >
        <div
          onClick={() => {
            clickFn()
          }}
          className={`nav-link d-flex justify-content-start align-items-start w-100 mx-0 ${className} ${navCollapsed && !navHover ? 'center_imp' : ''}`}
        >
          <div className='sidebar-list-items'>
            <img src={icon || '/assets/images/sidebar/blood.png'} alt='Blood Managment' />
            {navCollapsed && !navHover ? null : (
              <span className='menu-title'>
                <Trans>{title}</Trans>
              </span>
            )}
          </div>
        </div>
        {navCollapsed && !navHover ? null : (
          <div className='menu-title'>
            {campOpen ? (
              <KeyboardArrowDown sx={{ color: 'white' }} fontSize='medium' />
            ) : (
              <ChevronRight sx={{ color: 'white' }} fontSize='medium' />
            )}
          </div>
        )}
      </li>

      <Collapse in={campOpen} onEntered={handleEntered} onExited={handleExited}>
        {children}
      </Collapse>
    </div>
  )
}

export function SideMenuDonorCollapse({ navHover }) {
  const { setDonorOpen, donorOpen, setDonor } = useAppContext()
  const location = useLocation()
  const donorData = getDonorNameSans()

  function isPathActive(path) {
    return location.pathname.startsWith(path)
  }

  const { settings } = useSettings()
  const { navCollapsed } = settings

  useEffect(() => {
    if (navCollapsed || !navHover) {
      setDonorOpen(false)
    }
  }, [navCollapsed, navHover])

  useEffect(() => {
    if (location.pathname.startsWith('/donors-detail/')) {
      setDonorOpen(true)
      setDonor(donorData)
    } else {
      setDonor('')
      localStorage.removeItem('donor')
    }
  }, [location.pathname])

  return (
    <motion.div
      // initial={{ opacity: 0, scale: 0.5 }}
      // animate={{ opacity: 1, scale: 1 }}
      // transition={{ duration: 0.5 }}
      variants={{
        collapsed: { scale: 0.6 },
        open: { scale: 1 }
      }}
      transition={{ duration: 0.3 }}
      className='content-placeholder '
    >
      <div
        id='donor__profileDiv'
        onClick={() => {
          setDonorOpen(!donorOpen)
        }}
        className='tenant-user nav-item nav-profile cursor-pointer'
      >
        <span className='nav-link  '>
          <div className='nav-profile-image'>
            <p>{getInitials(getDonorNameSans())}</p>
            <span className='login-status online'></span>{' '}
          </div>
          <div className='nav-profile-text icon__profile'>
            {navCollapsed && !navHover ? null : (
              <span className='font-weight-bold mb-2'>
                <Trans>{getDonorNameSans()}</Trans>
              </span>
            )}
            {navCollapsed && !navHover ? null : (
              <div className='menu-title'>
                {donorOpen ? (
                  <KeyboardArrowDown sx={{ color: 'white' }} fontSize='medium' />
                ) : (
                  <ChevronRight sx={{ color: 'white' }} fontSize='medium' />
                )}
              </div>
            )}
          </div>
        </span>
      </div>
      <Collapse in={donorOpen}>
        <ul className='custom_drop_down'>
          <li>
            <Link
              to={`/donors-detail/${getDonorIdSans()}`}
              className={isPathActive(`/donors-detail/${getDonorIdSans()}`) ? ' active' : ''}
            >
              Donor Profile
            </Link>
          </li>
          {checkPermission('CREATE_SCREENING') || getTenatNameSans() != '' ? (
            <li>
              <Link
                to={`/donors-detail/screening/${getDonorIdSans()}`}
                className={isPathActive(`/donors-detail/screening/${getDonorIdSans()}`) ? ' active' : ''}
              >
                Donor Screening
              </Link>
            </li>
          ) : null}
          {checkPermission('CREATE_BLOOD_BAG') || getTenatNameSans() != '' ? (
            <li>
              <Link
                to={`/donors-detail/blood-inventory/${getDonorIdSans()}`}
                className={isPathActive(`/donors-detail/blood-inventory/${getDonorIdSans()}`) ? ' active' : ''}
              >
                Add Bag & Donation
              </Link>
            </li>
          ) : null}
          {checkPermission('UPDATE_TTI_TESTING') || getTenatNameSans() != '' ? (
            <li>
              <Link
                to={`/donors-detail/testing/${getDonorIdSans()}`}
                className={isPathActive(`/donors-detail/testing`) ? ' active' : ''}
              >
                TTI Testing
              </Link>
            </li>
          ) : null}

          {checkPermission('CREATE_BLOODS') || getTenatNameSans() != '' ? (
            <li>
              <Link
                to={`/donors-detail/donor-inventory/${getDonorIdSans()}`}
                className={isPathActive(`/donors-detail/donor-inventory`) ? ' active' : ''}
              >
                Donor Inventory
              </Link>
            </li>
          ) : null}
        </ul>
      </Collapse>
    </motion.div>
  )
}

class Sidebar extends Component {
  state = {}
  constructor(props) {
    super(props)
  }

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({ [menuState]: false })
    } else if (Object.keys(this.state).length === 0) {
      this.setState({ [menuState]: true })
    } else {
      Object.keys(this.state).forEach(i => {
        this.setState({ [i]: false })
      })
      this.setState({ [menuState]: true })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged()
    }
    if (prevState.tenant !== this.state.tenant) {
      this.fetchDataFromLocalStorage()
    }
  }

  onRouteChanged() {
    document.querySelector('#sidebar').classList.remove('active')
    Object.keys(this.state).forEach(i => {
      this.setState({ [i]: false })
    })

    const dropdownPaths = [
      { path: '/tenants', state: 'tenantsMenuOpen' },
      { path: '/users', state: 'usersMenuOpen' },
      { path: '/entity', state: 'entityMenuOpen' },

      { path: '/roles', state: 'rolesMenuOpen' },
      { path: '/bloods', state: 'bloodsMenuOpen' },
      { path: '/donors', state: 'DonorsMenuOpen' }
    ]

    dropdownPaths.forEach(obj => {
      if (this.isPathActive(obj.path)) {
        this.setState({ [obj.state]: true })
      }
    })

    if (this.props.closeAllSide) {
      document.getElementById('sidebar_new').classList.remove('active')
    }
  }

  closeDonorProfile() {
    localStorage.removeItem('donor')
  }

  render() {
    // console.log('state value' + this.state.tenant.name);
    return (
      <nav
        className='sidebar sidebar-offcanvas '
        id='sidebar'
        onClick={() => {
          document.getElementById('container_wraper').classList.remove('sidebar-collapse')
        }}
      >
        <motion.section
          className='sidebar-top-section'
          key='content'
          initial='collapsed'
          animate='open'
          exit='collapsed'
          variants={{
            open: { opacity: 1, height: 'auto' },
            collapsed: { opacity: 0, height: 0 }
          }}
          transition={{
            duration: 0.8,
            ease: [0.04, 0.62, 0.23, 0.98]
          }}
        >
          <div>
            {getEntityInfo() != 1 ? (
              <ul style={{ paddingBottom: 0 }} className='nav'>
                <div className=' nav-item nav-profile'>
                  <span className='nav-link' onClick={evt => evt.preventDefault()}>
                    <div className='nav-profile-image'>
                      <p>{getInitials(getTenatName())}</p>
                      <span className='login-status online'></span>{' '}
                    </div>
                    <div className='nav-profile-text hidetext'>
                      <span className='font-weight-bold mb-2'>
                        <Trans>{getTenatName()}</Trans>
                      </span>
                      <span className='text-small'>
                        <Trans>{getName()}</Trans>
                      </span>
                    </div>
                  </span>
                </div>
              </ul>
            ) : (
              <div className='d-flex mx-3 my-1'>
                <Link className='logo-container' to='/'>
                  <img className='banner-logo' src={'/assets/images/logo.svg'} alt='logo' />
                  <img className='mini-logo' src={'/assets/images/logo-mini.svg'} alt='logo' />
                </Link>
              </div>
            )}

            <ul className='nav overflow_side mid_menu'>
              <ScrollWrapper className='scroll_wrapper'>
                <li className={this.isPathActive('/dashboard') ? 'nav-item active' : 'nav-item'}>
                  <Link
                    onClick={() => {
                      localStorage.removeItem('tenant')
                      localStorage.removeItem('donor')
                    }}
                    className='nav-link d-flex justify-content-start align-items-start w-100 mx-0'
                    to='/dashboard'
                  >
                    <div className='sidebar-list-items'>
                      <img src={'/assets/images/sidebar/Dashboard.svg'} alt='dashboard' />
                      <span className='menu-title'>
                        <Trans>Dashboard</Trans>
                      </span>
                    </div>
                  </Link>
                </li>

                {checkPermission('LIST_TENANTS') && (
                  <li className={this.isPathActive('/tenants') ? 'nav-item active' : 'nav-item'}>
                    <Link
                      onClick={() => {
                        localStorage.removeItem('tenant')
                        localStorage.removeItem('donor')
                      }}
                      className='nav-link d-flex justify-content-start align-items-start w-100 mx-0'
                      to='/tenants'
                    >
                      <div className='sidebar-list-items'>
                        <img src={'/assets/images/sidebar/tenantManagement.svg'} alt='Tenant Managment' />
                        <span className='menu-title'>
                          <Trans>Tenant Managment</Trans>
                        </span>
                      </div>
                    </Link>
                  </li>
                )}

                {getTenatNameSans() != '' && (
                  <motion.div
                    variants={{
                      collapsed: { scale: 0.8 },
                      open: { scale: 1 }
                    }}
                    transition={{ duration: 0.8 }}
                    className='content-placeholder '
                  >
                    <div
                      onClick={() => {
                        localStorage.removeItem('donor')
                        document.getElementById('drop-down-donors').classList.remove('drop-down-clicked')
                        document.getElementById('donor__profileDiv').remove()
                      }}
                      className='tenant-user nav-item nav-profile'
                    >
                      <span className='nav-link' onClick={evt => evt.preventDefault()}>
                        <div className='nav-profile-image'>
                          <p>{getInitials(getTenatNameSans())}</p>
                          <span className='login-status online'></span>{' '}
                        </div>
                        <div className='nav-profile-text'>
                          <span className='font-weight-bold mb-2'>
                            <Trans>{getTenatNameSans()}</Trans>
                          </span>
                        </div>
                      </span>
                    </div>
                  </motion.div>
                )}

                {getEntityInfo() != 1 || getTenatNameSans() != '' ? (
                  <>
                    {checkPermission('LIST_DONORS') && (
                      <SideMenuCustom
                        clickFn={() => localStorage.removeItem('donor')}
                        path='/donors'
                        active={this.isPathActive('/donors')}
                        title='All Donors'
                        icon={'/assets/images/sidebar/donor.png'}
                      />
                    )}
                  </>
                ) : null}

                {getDonorNameSans() != '' && <SideMenuDonorCollapse />}

                {checkPermission('READ_REPORT') === true ? (
                  <SideMenuCustom
                    clickFn={() => localStorage.removeItem('donor')}
                    path='/master-register'
                    active={this.isPathActive('/master-register')}
                    title='Master Register'
                    icon={'/assets/images/sidebar/registration.svg'}
                  />
                ) : null}

                {getEntityInfo() != 1 || getTenatNameSans() != '' ? (
                  <>
                    {checkPermission('VIEW_TTI_TESTING') ? (
                      <SideMenuCustomCollapse
                        clickFn={() => {}}
                        active={this.isPathActive('/serology')}
                        title='Serology Testing'
                        icon={'/assets/images/modal/flask1.png'}
                      >
                        <li>
                          <ul style={{ height: 'auto' }} id='drop-down-donors' className='custom_drop_down '>
                            {/* VIEW_TTI_TESTING */}
                            {checkPermission('VIEW_TTI_TESTING') && (
                              <li>
                                <Link
                                  to={`/serology/lab-testing`}
                                  onClick={() => localStorage.removeItem('donor')}
                                  className={this.isPathActive(`/serology/lab-testing`) ? ' active' : ''}
                                >
                                  TTI Testing
                                </Link>
                              </li>
                            )}
                            {checkPermission('VIEW_TTI_TESTING') && (
                              <li>
                                <Link
                                  to={'/serology/blood-group-testing'}
                                  onClick={() => localStorage.removeItem('donor')}
                                  className={this.isPathActive(`/serology/blood-group-testing`) ? ' active' : ''}
                                >
                                  Blood Grouping
                                </Link>
                              </li>
                            )}
                            {/* {checkPermission("VIEW_CROSS_MATCHING_BAG") && (
                            <li>
                              <Link
                                to="/serology-testing/blood-Group"
                                onClick={() => localStorage.removeItem("donor")}
                                className={
                                  this.isPathActive(`/cross-match-testing`)
                                    ? " active"
                                    : ""
                                }
                              >
                                Cross Match Testing
                              </Link>
                            </li>
                          )} */}
                          </ul>{' '}
                        </li>
                      </SideMenuCustomCollapse>
                    ) : null}

                    {checkPermission('READ_BILLING') && (
                      <SideMenuCustom
                        clickFn={() => localStorage.removeItem('donor')}
                        path='/billing'
                        active={this.isPathActive('/billing')}
                        title='Billing'
                        icon={'/assets/images/sidebar/Billing.svg'}
                      />
                    )}

                    {checkPermission('LIST_BLOODS') && (
                      <SideMenuCustom
                        clickFn={() => localStorage.removeItem('donor')}
                        path='/blood-stock'
                        active={this.isPathActive('/blood-stock')}
                        title='Blood Stock'
                        icon={'/assets/images/sidebar/bloodStock.svg'}
                      />
                    )}
                    {/* {checkPermission("VIEW_WASTE") && (
                      <SideMenuCustom
                        clickFn={() => localStorage.removeItem("donor")}
                        path="/waste-management/all"
                        active={this.isPathActive("/waste-management")}
                        title="Waste Management"
                        icon={"/assets/images/alldonors/bin.png"}
                      />
                    )} */}
                    {checkPermission('VIEW_WASTE') === true ? (
                      <SideMenuWasteManagementCollapse
                        clickFn={() => {
                          localStorage.removeItem('donor')
                        }}
                        active={this.isPathActive('/waste-management')}
                        title='Waste Management'
                        icon={'/assets/images/alldonors/bin.png'}
                      >
                        <li>
                          <ul style={{ height: 'auto' }} id='drop-down-donors' className='custom_drop_down '>
                            {checkPermission('ADD_CAMP') === true && (
                              <li>
                                <Link
                                  to={`/waste-management/waste-list`}
                                  //onClick={() => localStorage.removeItem("donor")}
                                  className={this.isPathActive(`/waste-management/waste-list`) ? ' active' : ''}
                                >
                                  Waste List
                                </Link>
                              </li>
                            )}
                            {checkPermission('VIEW_CAMP') === true && (
                              <li>
                                <Link
                                  to='/waste-management/autoclave'
                                  // onClick={() => localStorage.removeItem("donor")}
                                  className={this.isPathActive(`/waste-management/autoclave`) ? ' active' : ''}
                                >
                                  Autoclave
                                </Link>
                              </li>
                            )}
                          </ul>{' '}
                        </li>
                      </SideMenuWasteManagementCollapse>
                    ) : null}

                    {checkPermission('VIEW_REQUEST') === true ? (
                      <SideMenuCustom
                        clickFn={() => localStorage.removeItem('donor')}
                        path='/blood-loan'
                        active={this.isPathActive('/blood-loan')}
                        title='Bulk Blood Transfer'
                        icon={'/assets/images/blood-loan.svg'}
                      />
                    ) : null}

                    {checkPermission('VIEW_REQUEST') === true ? (
                      <SideMenuCustom
                        clickFn={() => localStorage.removeItem('donor')}
                        path='/blood-requisition'
                        active={this.isPathActive('/blood-requisition')}
                        title='Blood Requisition'
                        icon={'/assets/images/blood-requestion.svg'}
                      />
                    ) : null}
                    {checkPermission('VIEW_CAMP') === true ? (
                      <>
                        <SideMenuCampCustomCollapse
                          clickFn={() => {
                            localStorage.removeItem('donor')
                          }}
                          active={this.isPathActive('/blood-camp-managemen')}
                          title='Camp Management'
                          icon={'/assets/images/Bloodcamp.svg'}
                        >
                          <li>
                            <ul style={{ height: 'auto' }} id='drop-down-donors' className='custom_drop_down '>
                              {checkPermission('ADD_CAMP') === true && (
                                <li>
                                  <Link
                                    to={`/blood-camp-management/add`}
                                    //onClick={() => localStorage.removeItem("donor")}
                                    className={this.isPathActive(`/blood-camp-management/add`) ? ' active' : ''}
                                  >
                                    Add Camp
                                  </Link>
                                </li>
                              )}
                              {checkPermission('VIEW_CAMP') === true && (
                                <li>
                                  <Link
                                    to='/blood-camp-management/facility'
                                    // onClick={() => localStorage.removeItem("donor")}
                                    className={this.isPathActive(`/blood-camp-management/facility`) ? ' active' : ''}
                                  >
                                    Facility Inspection
                                  </Link>
                                </li>
                              )}
                              {checkPermission('VIEW_CAMP') === true && (
                                <li>
                                  <Link
                                    to='/blood-camp-management/staff'
                                    //  onClick={() => localStorage.removeItem("donor")}
                                    className={this.isPathActive(`/blood-camp-management/staff`) ? ' active' : ''}
                                  >
                                    Staff
                                  </Link>
                                </li>
                              )}
                              {checkPermission('VIEW_CAMP') === true && (
                                <li>
                                  <Link
                                    to='/blood-camp-management/items'
                                    //  onClick={() => localStorage.removeItem("donor")}
                                    className={this.isPathActive(`/blood-camp-management/items`) ? ' active' : ''}
                                  >
                                    Add Items
                                  </Link>
                                </li>
                              )}
                              {checkPermission('VIEW_CAMP') === true && (
                                <li>
                                  <Link
                                    to='/blood-camp-management/post-camp-report'
                                    //  onClick={() => localStorage.removeItem("donor")}
                                    className={
                                      this.isPathActive(`/blood-camp-management/post-camp-report`) ? ' active' : ''
                                    }
                                  >
                                    Post Camp Report
                                  </Link>
                                </li>
                              )}
                            </ul>{' '}
                          </li>
                        </SideMenuCampCustomCollapse>
                      </>
                    ) : null}

                    {checkPermission('VIEW_ACCOUNT') === true ? (
                      <SideMenuCustom
                        clickFn={() => localStorage.removeItem('donor')}
                        path='/manage-account'
                        active={this.isPathActive('/manage-account')}
                        title='Manage Account'
                        icon={'/assets/images/sidebar/settings.svg'}
                      />
                    ) : null}
                    {checkPermission('READ_CERTIFICATE') === true ? (
                      <SideMenuCustom
                        clickFn={() => localStorage.removeItem('donor')}
                        path='/certification'
                        active={this.isPathActive('/certification')}
                        title='Certification'
                        icon={'/assets/images/sidebar/certificate.svg'}
                      />
                    ) : null}

                    {checkPermission('READ_REPORT') === true ? (
                      <SideMenuCustom
                        clickFn={() => localStorage.removeItem('donor')}
                        path='/reports'
                        active={this.isPathActive('/reports')}
                        title='Reports'
                        icon={'/assets/images/sidebar/bloodStock.svg'}
                      />
                    ) : null}

                    {checkPermission('VIEW_ACCOUNT') === true ? (
                      <SideMenuCustom
                        clickFn={() => localStorage.removeItem('donor')}
                        path='/barcodes'
                        active={this.isPathActive('/barcodes')}
                        title='QR Code Generation'
                        icon={'/assets/images/sidebar/barcode_1.svg'}
                      />
                    ) : null}

                    {checkPermission('LIST_USERS') === true ? (
                      <SideMenuCustom
                        clickFn={() => localStorage.removeItem('donor')}
                        path='/users'
                        active={this.isPathActive('/users')}
                        title='Manage Users'
                        icon={'/assets/images/sidebar/userManagement.svg'}
                      />
                    ) : null}

                    {checkPermission('LIST_ROLES') === true ? (
                      <SideMenuCustom
                        clickFn={() => localStorage.removeItem('donor')}
                        path='/roles'
                        active={this.isPathActive('/roles')}
                        title='Role Management'
                        icon={'/assets/images/sidebar/roleManage.svg'}
                      />
                    ) : null}
                    {checkPermission('VIEW_ACCOUNT') === true ? (
                      <SideMenuCustom
                        clickFn={() => localStorage.removeItem('donor')}
                        path='/stock-items'
                        active={this.isPathActive('/stock-items')}
                        title='Items Stock'
                        icon={'/assets/images/sidebar/bloodStock.svg'}
                      />
                    ) : null}
                  </>
                ) : null}
              </ScrollWrapper>
            </ul>
          </div>

          <section className='footer-section  '>
            <p className='footer-note'>© EHR Logic. All rights reserved</p>
          </section>
        </motion.section>
      </nav>
    )
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path)
  }

  removeDonor() {
    localStorage.removeItem('donor')
  }

  fetchDataFromLocalStorage() {
    const tenant = JSON.parse(localStorage.getItem('tenant'))
    console.log('funtions')
    console.log(tenant)
    if (tenant) {
      this.setState({ tenant: tenant })
    }
  }

  componentDidMount() {
    // this.fetchDataFromLocalStorage();
    this.onRouteChanged()
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector('body')
    document.querySelectorAll('.sidebar .nav-item').forEach(el => {
      el.addEventListener('mouseover', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open')
        }
      })
      el.addEventListener('mouseout', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open')
        }
      })
    })
  }
}

export default withRouter(Sidebar)
