import React, { Component, useEffect, useState } from 'react';
import './App.scss';
import './App.css';
import './shared/shared.css';
import './abha/AddDonor.css';
import './donor-inventory/DonorInventory.css';
import './donors/Donors.css';
import './common/common.css';
import './donors/profile/DonorProfile.css';
import './blood-camp-management/blood-camp-management.css';
import './blood-loan/blood-loan.css';
import './blood-requisition/blood-requisition.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './dashboard/Dashboard.css'

import AppRoutes from './AppRoutes';
import Navbar from './shared/Navbar';
import Sidebar from './shared/Sidebar';
import { withTranslation } from 'react-i18next';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useLocation } from 'react-router-dom';
import UserLayout from '../layout/UserLayout';

export const fullPageLayoutRoutes = [
  '/login',
  '/donor/registeration',
  '/donor/registeration/:type/:id',
  '/guest',
  '/print-pages',
  '/reset-password',
  '/p/df'
];

function App() {
  const [fullPageLayout, setFullPageLayout] = useState(false);
  const location = useLocation();

  // Effect to update the state when the route changes
  useEffect(() => {
    for (let i = 0; i < fullPageLayoutRoutes.length; i++) {
      // if (this.props.location.pathname === fullPageLayoutRoutes[i]) {
      if (location.pathname.startsWith(fullPageLayoutRoutes[i])) {
        setFullPageLayout(true);
        break;
      } else {
        setFullPageLayout(false);
      }
    }
  }, [location.key]); // Only re-run the effect when the location key changes (i.e., on route change)

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {fullPageLayout === true ? (
        <div>
          <AppRoutes />
        </div>
      ) : (
        <UserLayout>
          <AppRoutes />
        </UserLayout>
      )}
    </LocalizationProvider>
  );
}

export default App;

// class App extends Component {
//   state = {};
//   // componentDidMount() {
//   //   this.onRouteChanged();
//   // }

//   render() {
//     // let footerComponent = !this.state.isFullPageLayout ? <Footer /> : '';
//     return (
//       <LocalizationProvider dateAdapter={AdapterDayjs}>
//         <ToastContainer
//           position="top-center"
//           autoClose={5000}
//           hideProgressBar={false}
//           newestOnTop={false}
//           closeOnClick
//           rtl={false}
//           pauseOnFocusLoss
//           draggable
//           pauseOnHover
//           theme="light"
//         />
//         {console.log("this.state.blankLayout", this.state.blankLayout,this.state.isFullPageLayout)}
//         {/* {this.state.blankLayout === true ||
//         this.state.isFullPageLayout === false ? (
//           <div>
//             <AppRoutes />
//           </div>
//         ) : (
//           <UserLayout>
//             <AppRoutes />
//           </UserLayout>
//         )} */}
//         <div
//             id="container_wraper"
//             className="container-scroller container_wraper"
//           >
//             {this.state.isFullPageLayout === false ? (
//               <div className="container_left">
//                 <Sidebar />
//               </div>
//             ) : null}
//             <div className="container-fluid page-body-wrapper container_right">
//               <div className="main-content">
//                 {this.state.isFullPageLayout === false ? <Navbar /> : null}
//                 <AppRoutes />
//               </div>
//             </div>
//           </div>
//       </LocalizationProvider>
//     );
//   }

//   // componentDidUpdate(prevProps) {
//   //   if (this.props.location !== prevProps.location) {
//   //     this.onRouteChanged();
//   //   }
//   // }

//   onRouteChanged() {
//     console.log("this.state.blankLayout2", this.state.blankLayout,this.state.isFullPageLayout)
//     const { i18n } = this.props;
//     const body = document.querySelector("body");
//     // if (this.props.location.pathname === "/layout/RtlLayout") {
//     //   body.classList.add("rtl");
//     //   i18n.changeLanguage("ar");
//     // } else {
//     //   body.classList.remove("rtl");
//     //   i18n.changeLanguage("en");
//     // }
//     window.scrollTo(0, 0);
//     // "/print-pages",
//     const fullPageLayoutRoutes = [
//       "/login",
//       "/donor/registeration",
//       "/donor/registeration/:type/:id",
//       "/guest",
//     ];

//     for (let i = 0; i < fullPageLayoutRoutes.length; i++) {
//       // if (this.props.location.pathname === fullPageLayoutRoutes[i]) {
//       if (this.props.location.pathname.startsWith(fullPageLayoutRoutes[i])) {
//         this.setState({
//           isFullPageLayout: true,
//           blankLayout: false,
//         });
//         document
//           .querySelector(".page-body-wrapper")
//           .classList.add("full-page-wrapper");
//         break;
//       } else {
//         this.setState({
//           isFullPageLayout: false,
//           blankLayout: true,
//         });
//         document
//           .querySelector(".page-body-wrapper")
//           .classList.remove("full-page-wrapper");
//       }
//     }

//     if (this.props.location.pathname.startsWith("/print-pages")) {
//       this.setState({
//         blankLayout: true,
//       });
//     } else {
//       this.setState({
//         blankLayout: false,
//       });
//     }
//   }
// }

// export default withTranslation()(withRouter(App));
