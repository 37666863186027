import React from 'react'

// form
import { useFormContext, Controller } from 'react-hook-form'
// @mui
import { FormHelperText, TextField } from '@mui/material'

// ----------------------------------------------------------------------

export default function RHFSelect({
  name,
  native,
  maxHeight = 220,
  helperText,
  children,
  placeholder,
  handleChange,
  ...other
}) {
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, ref, ...field }, fieldState: { error } }) => {
        return (
          <div>
            <TextField
              {...field}
              onChange={e => {
                onChange(e.target.value)
                if (handleChange) {
                  handleChange(e.target.value)
                }
              }}
              inputRef={ref}
              fullWidth
              select
              SelectProps={{
                native,
                MenuProps: {
                  PaperProps: {
                    sx: {
                      ...(!native && {
                        px: 1,
                        maxHeight: typeof maxHeight === 'number' ? maxHeight : 'unset',
                        '& .MuiMenuItem-root': {
                          px: 1,
                          borderRadius: 0.75,
                          typography: 'body2',
                          textTransform: 'capitalize'
                        }
                      })
                    }
                  }
                },
                sx: { textTransform: 'capitalize' }
              }}
              error={!!error}
              helperText={helperText ? helperText : ''}
              {...other}
            >
              {children}
            </TextField>
            {error ? <FormHelperText sx={{ color: 'error.main' }}>{error?.message}</FormHelperText> : null}
          </div>
        )
      }}
    />
  )
}
