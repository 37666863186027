import * as React from 'react'
import { Controller, Control, Path, FieldValues } from 'react-hook-form'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import { FormHelperText } from '@mui/material'
import { inputStyles } from '../certification/EditCertificate'

export const RHFAutocompleteField = props => {
  const { control, options, name, size, handleChange } = props

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: true
      }}
      render={({ field, fieldState: { error } }) => {
        const { onChange, value, ref } = field

        return (
          <>
            <Autocomplete
              size={size || 'small'}
              value={
                value
                  ? options.find(option => {
                      return value === option.id
                    }) ?? null
                  : null
              }
              getOptionLabel={option => {
                return option.label
              }}
              onChange={(event, newValue) => {
                onChange(newValue ? newValue.id : '')
              }}
              id='controllable-states-demo'
              options={options}
              renderInput={params => (
                <TextField error={Boolean(error)} {...params} label={props.placeholder} inputRef={ref} />
              )}
            />
            {error && <FormHelperText sx={{ color: 'error.main' }}>{error.message}</FormHelperText>}
          </>
        )
      }}
    />
  )
}

const MUIAutocomplete = ({ size, options, value, onChange, placeholder, error, ...other }) => {
  return (
    <>
      <Autocomplete
        size={size || 'small'}
        value={
          value
            ? options.find(option => {
                return value === option.name
              }) ?? null
            : null
        }
        getOptionLabel={option => {
          return option.name
        }}
        onChange={(event, newValue) => {
          onChange(newValue ? newValue.name : '')
        }}
        id='controllable-states-demo'
        options={options}
        {...other}
        renderInput={params => (
          <TextField
            size={size || 'small'}
            sx={inputStyles}
            error={Boolean(error)}
            {...params}
            placeholder={placeholder}
          />
        )}
      />
      {error && <FormHelperText sx={{ color: 'error.main' }}>{error}</FormHelperText>}
    </>
  )
}

export default MUIAutocomplete
