import React from 'react'
import ScrollBar from 'react-perfect-scrollbar'
import { useReactToPrint } from 'react-to-print'
import LoadingButton from '../shared/LoadingButton'

const PrintWrapper = ({ fileName, handleCancel, children ,layout}) => {
  const componentRef = React.useRef(null)

  const onBeforeGetContentResolve = React.useRef(null)

  const [loading, setPrintLoading] = React.useState(false)

  const handleAfterPrint = React.useCallback(() => {
    setPrintLoading(false)
    console.log('`onAfterPrint` called') // tslint:disable-line no-console
  }, [])

  const handleBeforePrint = React.useCallback(() => {
    console.log('`onBeforePrint` called') // tslint:disable-line no-console
  }, [])

  const handleOnBeforeGetContent = React.useCallback(() => {
    setPrintLoading(true)
    return new Promise(resolve => {
      onBeforeGetContentResolve.current = resolve
      resolve()
    })
  }, [setPrintLoading])

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current
  }, [componentRef.current])

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: fileName || 'document',
    onBeforeGetContent: handleOnBeforeGetContent,
    onBeforePrint: handleBeforePrint,
    onAfterPrint: handleAfterPrint,
    removeAfterPrint: true
  })

  return (
    <ScrollBar>
      <div
        style={{
          padding: '16px',
          display: 'flex',
          gap: '16px',
          justifyContent: 'center',
paddingBottom: '24px',
          overflowY: 'auto'
        }}
      >


        {layout}
        <div style={{ display: 'flex', gap: '16px' }}>
          <button
            type='button'
            className='btn backBtn '
            onClick={() => {
              handleCancel?.()
            }}
          >
            Cancel
          </button>

          <LoadingButton loading={loading} type='button' className='btn gradient-btn' onClick={handlePrint}>
            Print
          </LoadingButton>
        </div>
      </div>
      <div className='d-flex justify-content-center'>
        <div ref={componentRef}>{children}</div>
      </div>
    </ScrollBar>
  )
}

export default PrintWrapper
