import React, { useState } from 'react'
import { Pagination } from 'react-bootstrap'
import MUIPagination from '@mui/material/Pagination'
import { Box, Typography } from '@mui/material'
const PaginationLink = ({ pagination, getNext, fullDetail = false }) => {
  const [page, setPage] = useState(1)
  function handlePage(page) {
    setPage(page)
    getNext?.(page)
  }

  const itemsPerPage = pagination?.limit || 15
  const totalRecord = pagination?.totalCount || pagination?.totalRecord || 15

  const startIndex = (page - 1) * itemsPerPage

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 0, gap: 2 }}>
      {fullDetail ? (
        <Typography
          sx={{
            fontSize: '1rem',
            lineHeight: '1.5rem',
            color: '#07101c',
            textTransform: 'none'
          }}
        >
          Showing {startIndex + 1} to {Math.min(startIndex + itemsPerPage, totalRecord)} of {pagination?.totalCount}{' '}
          items
        </Typography>
      ) : null}
      <MUIPagination
        sx={{ mb: fullDetail ? 0 : 2 }}
        page={page}
        onChange={(e, page) => handlePage(page)}
        count={pagination?.totalPage || 1}
        variant='outlined'
        color='primary'
        shape='rounded'
      />
    </Box>
  )
}
export default PaginationLink
