import { CircularProgress } from '@mui/material'
import React from 'react'

function LoadingButton({ isLoading, title, className, children, ...other }) {
  return (
    <button disabled={isLoading} className={`btn gradient-btn ${className}`} {...other}>
      {isLoading ? <CircularProgress size={30} color='inherit' /> : title || ''}
      {children}
    </button>
  )
}

export default LoadingButton
