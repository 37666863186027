import React, { useEffect, useState } from 'react'
import AxiosHelper from '../../helper/AxiosHelper'
import BreadCrumb from '../common/BreadCrumb'
import PageHeader from '../common/PageHeader'

const Dashboard = ({ id }) => {
  const [data, setData] = useState([])
  const getDashboardInfo = () => {
    let type = id !== undefined ? id : ''
    AxiosHelper.get(`dashboard?type=${type}`)
      .then(resp => {
        setData(resp.data.data)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const breadcrumbRoute = [
    {
      path: '/dashboard',
      label: 'Dashboard'
    }
  ]

  useEffect(() => {
    getDashboardInfo()
  }, [])

  return (
    <div>
      {/* <div className='page-header'>
                <h3 className='page-title'>Dashboard </h3>
                <nav aria-label='breadcrumb'>
                    <ul className='breadcrumb'>
                        <li
                            className='breadcrumb-item active'
                            aria-current='page'
                        >
                            <span></span>Overview{' '}
                            <i className='mdi mdi-alert-circle-outline icon-sm text-primary align-middle'></i>
                        </li>
                    </ul>
                </nav>
            </div> */}
      <BreadCrumb breadcrumbRoute={breadcrumbRoute}>
        <PageHeader title='Dashboard' />
      </BreadCrumb>
      <div className='dashboard-main-content'>
        {data &&
          data.length > 0 &&
          data?.map((item, index) => (
            <div className='dashboard-card'>
              <div className={`card ${item.color} card-img-holder text-white`}>
                <div className='card-body'>
                  <img src={'/assets/images/dashboard/circle.svg'} className='card-img-absolute' alt='circle' />
                  <h4 className='font-weight-normal mb-3'>
                    {item.label} <i className={`${item.icon} mdi-24px float-right`}></i>
                  </h4>
                  <h2 className='mb-5'> {item.value}</h2>
                  <h6 className='card-text'>{item.description}</h6>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  )
}

export default Dashboard
