import axios from 'axios'
import { getToken } from '../app/middleware/auth'

export const FRONT_END_URL =
  window.location.origin || process.env.REACT_APP_FRONTEND_URL || 'https://ebloodconnect.ehrlogic.com'
export const API_URL = process.env.REACT_APP_BASE_URL

class AxiosHelper {
  get(url) {
    const config = {
      method: 'get',
      url: `${API_URL}/${url}`,
      headers: {
        Authorization: `Bearer ${getToken()}`
      }
    }
    return new Promise((resolve, reject) => {
      axios(config)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(this.exceptionHandler(err))
        })
    })
  }

  post(url, data) {
    const config = {
      method: 'post',
      url: `${API_URL}/${url}`,
      headers: {
        Authorization: `Bearer ${getToken()}`
      },
      data: data
    }
    return new Promise((resolve, reject) => {
      axios(config)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(this.exceptionHandler(err))
        })
    })
  }

  put(url, data) {
    const config = {
      method: 'put',
      url: `${API_URL}/${url}`,
      headers: {
        Authorization: `Bearer ${getToken()}`
      },
      data: data
    }
    return new Promise((resolve, reject) => {
      axios(config)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(this.exceptionHandler(err))
        })
    })
  }

  delete(url) {
    const config = {
      method: 'delete',
      url: `${API_URL}/${url}`,
      headers: {
        Authorization: `Bearer ${getToken()}`
      }
    }
    return new Promise((resolve, reject) => {
      axios(config)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(this.exceptionHandler(err))
        })
    })
  }

  patch() {}

  exceptionHandler(error) {
    if (error && error.response && error.response.data && error.response.data.statusCode === 403) {
      window.localStorage.removeItem('authInfo')
      let returnUrl = window?.location?.pathname
      const iframe = document?.querySelector('iframe')
      if (iframe) {
        document?.body?.removeChild(iframe)
      }
      window.localStorage.setItem('returnUrl', returnUrl)
      window.location.replace('/login?returnUrl=' + returnUrl)
    } else if (error && error.response && error.response.data) {
      return error.response.data.message
    } else {
      return 'Something went wrong'
    }
  }
}

export default new AxiosHelper()
