import { CardHeader } from '../shared/card-header'
import { useHistory } from 'react-router-dom'

export const HealthRecord = ({ trailData, HealthStatus }) => {

  const history = useHistory()
  const actionCallBack = async e => {
    if (e.target.name === 'edit' && trailData?.abha_id) {
      history.push(`/donors-detail/screening/${trailData.abha_id}`)
    }
  }
  return (
    <>
      <CardHeader deleteBtn={false} actionBtns actionCallBack={actionCallBack} lastUpdated={trailData?.updatedAt}>
        Health Record
      </CardHeader>
      <div>
        {HealthStatus.map((item, idx) => {
          return (
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                borderTop: idx === 0 ? '0' : '1px solid #ddd',
                padding: '7px 0 3px 0',
                borderCollapse: 'collapse'
              }}
              key={idx}
            >
              <div className='general-info' style={{ width: '70%' }}>
                {item.label}
              </div>
              <div className='general-black'>{item.value}</div>
            </div>
          )
        })}
      </div>
    </>
  )
}
