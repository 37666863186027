import React from 'react'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import WestIcon from '@mui/icons-material/West'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
const BreadCrumb = ({ breadcrumbRoute, children, hideBack, CustomUi }) => {
  const history = useHistory()
  return (
    <div className='page-header'>
      <nav aria-label='breadcrumb'>
        <ol className='breadcrumb'>
          {breadcrumbRoute?.map((data, i) =>
            i !== breadcrumbRoute.length - 1 ? (
              <li key={i} className='breadcrumb-item'>
                <Link to={data.path}>{data.label}</Link>
              </li>
            ) : (
              <li
                key={i}
                style={{ fontSize: '1rem', color: 'black' }}
                className='breadcrumb-item active text-capitalize page-title'
                aria-current='page'
              >
                {data.label}
              </li>
            )
          )}
        </ol>
        {children}
      </nav>
      {hideBack ? (
        ''
      ) : (
        <button onClick={() => history.goBack()} type='button' className='btn backBtn'>
          <ArrowBackIcon />
          Back
        </button>
      )}
      {CustomUi ? CustomUi : null}
    </div>
  )
}

export default BreadCrumb
