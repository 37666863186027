import React, { useState } from 'react'
import { getTenant } from '../../../middleware/auth'
import moment from 'moment'
const lbl = { fontWeight: '400', fontSize: '10px', color: '#4B5563' }
const val = { marginTop: '8px', fontWeight: '400', fontSize: '10px', color: '#07101c' }
const wraper = { width: '33%', display: 'inline-block', padding: '8px 0', borderBottom: '1px solid #E1E1E1' }
const headings = { padding: '8px 10px', color: '#214F8A', fontSize: '14px', fontWeight: '600', background: '#E9EDF3' }

const BloodBagPrint = ({ printId, data, info }) => {
  let tenant = getTenant()

  const details = [
    { label: 'Name', value: info?.name || 'N/A' },
    { label: 'Registration Number', value: info?.reg_id || 'N/A' },
    { label: 'Donation Date', value: info?.donation_date ? moment(info?.donation_date).format('DD-MMM-YYYY') : 'N/A' },
    { label: 'Contact No. ', value: <>+91 {info?.mobile}</> || 'N/A' },
    {
      label: 'Gender / Age',
      value: (
        <>
          {info?.gender || '-'} / {info?.age || '-'} Yr
        </>
      )
    },
    { label: 'Father/Husband Name', value: info?.father_name || 'N/A' },
    {
      label: 'Blood Bag Assign Date',
      value: data?.BloodBagIssueEntity?.assigned_date
        ? moment(data?.BloodBagIssueEntity?.assigned_date).format('DD-MMM-YYYY')
        : 'N/A'
    }
  ]

  return (
    <div id={printId} style={{ width: '100%', fontSize: '10px', color: '#07101C', fontFamily: 'Inter' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          background: 'linear-gradient(180deg, #21538B 0%, #13B9AA 100%)',
          color: '#FFF',
          minHeight: '70px'
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', padding: '10px' }}>
          <div style={{ fontFamily: 'Inter', fontSize: '12px', fontWeight: '500' }}>
            {tenant?.tenant || 'Blood Bank Name : N/A'}
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            fontSize: '12px',
            fontWeight: '500',
            padding: '10px'
          }}
        >
          <div style={{ fontWeight: '600' }}>Blood Bag Assign Report</div>
          <div style={{ marginTop: '6px' }}>
            {data?.BloodBagIssueEntity?.bag_number ? 'Final Result: Assigned' : 'Final Result: Not Assigned'}
          </div>
        </div>
      </div>

      <div style={{ minHeight: '87vh' }}>
        <div style={headings}>Donor Info</div>

        <div style={{ padding: '0 12px' }}>
          {details.map((item, idx) => {
            return (
              <div style={wraper} key={idx}>
                <div style={lbl}>{item['label']}</div>
                <div style={val}>{item['value'] || 'NA'}</div>
              </div>
            )
          })}
        </div>

        <div>
          <div style={headings}>Blood Bag Details</div>

          <div style={{ padding: '16px', paddingTop: '0', width: '100%' }}>
            <div style={wraper}>
              <div style={lbl}>Blood Bag Type</div>
              <div style={val}>{data?.BloodBagIssueEntity?.bag_type || 'N/A'}</div>
            </div>
            <div style={wraper}>
              <div style={lbl}>Bag Number</div>
              <div style={val}>{data?.BloodBagIssueEntity?.bag_number || 'N/A'}</div>
            </div>
            <div style={wraper}>
              <div style={lbl}>Segment Number</div>
              <div style={val}>{data?.BloodBagIssueEntity?.tube_number || 'N/A'}</div>
            </div>
            <div style={wraper}>
              <div style={lbl}>Phlebotomy Duration(In Min)</div>
              <div style={val}>{data?.BloodBagIssueEntity?.bleeding_duration || 'N/A'}</div>
            </div>
            <div style={wraper}>
              <div style={lbl}>Phlebotomist</div>
              <div style={val}>{data?.BloodBagIssueEntity?.phlebotomist || 'N/A'}</div>
            </div>
            <div style={wraper}>
              <div style={lbl}>LOT Number</div>
              <div style={val}>{data?.BloodBagIssueEntity?.lot_number || 'N/A'}</div>
            </div>
          </div>
        </div>

        <div style={{ borderRadius: '4px', background: '#F8F8F8', paddingBottom: '8px' }}>
          <div style={headings}>Reason</div>
          <ul>
            <li style={{ color: '#4B5563' }}>{data?.BloodBagIssueEntity?.bag_number ? 'Fit' : 'Not Fit'}</li>
          </ul>
          <div style={{ fontWeight: '600', marginLeft: '16px' }}>
            {data?.BloodBagIssueEntity?.bag_number
              ? 'Result :  Blood Bag Assigned'
              : 'Result :  Blood Bag Not Assigned'}
          </div>
        </div>

        <div style={{ display: 'flex', flexDirection: 'row-reverse', marginTop: '100px', paddingRight: '75px' }}>
          <div style={{ borderTopStyle: 'dashed', padding: '12px', color: '#9CA3AF', fontSize: '12px' }}>
            Medical Officer
          </div>
        </div>
      </div>

      <div
        style={{
          height: '71px',
          background: '#EBFEF9',
          display: 'flex',
          flexDirection: 'column',
          gap: '14px',
          padding: '8px 16px',
          color: '#374151',
          fontSize: '12px',
          justifyContent: 'center'
        }}
      >
        <div style={{ display: 'flex', gap: '36px' }}>
          <div style={{ display: 'flex', gap: '8px' }}>
            <img src={'../assets/images/invoice/material-symbols_call-outline.svg'} alt='contact' />
            <span>{tenant?.phone_number || 'N/A'}</span>
          </div>
          <div style={{ display: 'flex', gap: '8px' }}>
            <img src={'../assets/images/invoice/fontisto_email.svg'} alt='email' />
            <span>{tenant?.email || 'N/A'}</span>
          </div>
        </div>
        <div
          style={{
            fontSize: '14px',
            gap: '8px'
          }}
        >
          <div style={{ display: 'flex', gap: '8px' }}>
            <img src={'../assets/images/invoice/carbon_location.svg'} alt='location' />
            <span>
              {tenant?.TenantAddressEntity?.street ? tenant?.TenantAddressEntity?.street : ''}{' '}
              {tenant?.TenantAddressEntity?.area ? tenant?.TenantAddressEntity?.area : ''}{' '}
              {tenant?.TenantAddressEntity?.city ? tenant?.TenantAddressEntity?.city : ''}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}
export default BloodBagPrint
