import React, { useEffect, useState } from 'react'
import { flexRender, getCoreRowModel, useReactTable, getSortedRowModel } from '@tanstack/react-table'
import NoDataFound from '../common/NoDataFound'
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  tableCellClasses
} from '@mui/material'
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'

export function SortingImg({ column }) {
  if (column.getIsSorted() === 'asc') {
    return <img className='sort_img' src='/assets/images/sorting/shortArrowUp.svg' alt='arrowup' />
  }
  if (column.getIsSorted() === 'desc') {
    return <img className='sort_img' src='/assets/images/sorting/sortingArrowDown.svg' alt='arrowdown' />
  }

  return <img className='sort_img' src='/assets/images/img_arrowup.svg' alt='arrowdown' />
}

function ExpandedTable({
  item,
  open,
  colSpan,
  rowClass,
  state,
  columns,
  data = [],
  loading = false,
  headerClass,
  ...others
}) {
  const [openCollapse, setOpenCollapse] = useState(false)
  const tableConfig = {
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    state: { ...state },
    ...others
  }

  useEffect(() => {
    setOpenCollapse(true)

    return () => {
      setOpenCollapse(false)
    }
  }, [open])

  const table = useReactTable(tableConfig)

  return (
    <TableRow sx={{ '& td': { border: 0 } }} className={open ? 'bill_bg' : ''}>
      <TableCell
        sx={{
          background: '#FBFBFB',
          [`& .${tableCellClasses.root}`]: {
            borderTop: 'none'
          }
        }}
        style={{ paddingBottom: 0, paddingTop: 0, background: '#E9EDF3' }}
        width='100%'
        colSpan={colSpan || 8}
      >
        <Collapse in={openCollapse} timeout='auto' unmountOnExit>
          <Box sx={{ margin: 1 }}>
            <Table
              hover={false}
              size='small'
              aria-label='purchases'
              sx={{
                '&.MuiTable-root': {
                  borderRadius: '1em',
                  background: '#fff'
                }
              }}
            >
              <TableHead
                sx={{
                  background: '#FBFBFB',
                  '& th': {
                    position: 'static',
                    zIndex: '0 !important'
                  },
                  '& th:first-child': {
                    borderRadius: '1em 0 0 0'
                  },
                  '& th:last-child': {
                    borderRadius: '0 1em 0 0'
                  }
                }}
              >
                {table.getHeaderGroups().map(headerGroup => (
                  <TableRow
                    sx={{
                      borderRadius: '10px 0px 10px 0px',
                      '&:hover': {
                        backgroundColor: 'transparent !important'
                      }
                    }}
                    className={headerClass}
                    key={headerGroup.id}
                  >
                    {headerGroup.headers.map(header => (
                      <TableCell key={header.id}>
                        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableHead>
              <TableBody>
                {table.getRowModel().rows.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      '&:hover': {
                        backgroundColor: 'transparent !important'
                      }
                    }}
                    className={rowClass}
                  >
                    {row.getVisibleCells().map(cell => (
                      <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  )
}

const ReactTable = ({
  state,
  columns,
  data = [],
  loading = false,
  enableSorting = false,
  enableDragDrop = false,
  enableCollapse = false,
  rowClass = '',
  headerClass = '',
  headerProps = {},
  bodyProps = {},
  rowDataProps = {},
  maxHeight,
  styles,
  tableClass,
  nestedColumns,
  heightupper,
  nestedColumnKey, // Columns for nested tables
  ...others
}) => {
  const [expandedRows, setExpandedRows] = useState({})

  const [tableHeight, setTableHeight] = useState(getTableHeight())

  useEffect(() => {
    const debounce = (func, wait) => {
      let timeout
      return (...args) => {
        clearTimeout(timeout)
        timeout = setTimeout(() => func(...args), wait)
      }
    }

    const handleResize = debounce(() => {
      setTableHeight(getTableHeight())
    }, 100)

    window.addEventListener('resize', handleResize)

    // Initial call to set the height
    handleResize()

    // Clean up event listener on unmount
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [heightupper])

  function getTableHeight() {
    let height
    let heightUpper
    const divElement = document.getElementById('scroll__card_all')
    const upperElement = document.getElementById('upper_div_filter')
    if (divElement) {
      height = divElement.offsetHeight
    }
    if (upperElement) {
      heightUpper = upperElement.offsetHeight
    }
    const extraHeight = (typeof heightupper === 'number') ? heightupper : 0;
    return((height - heightUpper - 100) - (extraHeight))
  }

  const toggleRowExpansion = rowId => {
    setExpandedRows(prev => ({ ...prev, [rowId]: !prev[rowId] }))
  }

  const tableConfig = {
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    state: { ...state },
    ...others
  }

  if (enableSorting) {
    tableConfig['getSortedRowModel'] = getSortedRowModel()
  }

  const table = useReactTable(tableConfig)

  return (
    <div style={{ maxHeight: tableHeight || '400px', ...styles }} className='react_table_wrapper'>
      <table className={'table table-hover react_table ' + tableClass}>
        <thead {...headerProps}>
          {table.getHeaderGroups().map(headerGroup => (
            <tr className={headerClass} key={headerGroup.id}>
              {/* {enableCollapse ? <th className='w-fit'></th> : null} */}

              {headerGroup.headers.map(header => (
                <th key={header.id}>
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...bodyProps}>
          {table.getRowModel().rows.map((row, index) => (
            <React.Fragment key={index}>
              <tr className={`${rowClass} ${expandedRows[row.id] ? 'expanded' : ''}`}>
                {/* {enableCollapse ? (
                  <td style={{ width: '5px' }}>
                    <IconButton aria-label='expand row' size='small' onClick={() => toggleRowExpansion(row.id)}>
                      {expandedRows[row.id] ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                  </td>
                ) : null} */}

                {row.getVisibleCells().map((cell, i) => (
                  <td
                    key={cell.id}
                    style={{
                      ...(enableCollapse && i === 0 ? { display: 'flex', width: '100%' } : '')
                    }}
                  >
                    {enableCollapse
                      ? i === 0 && (
                          <IconButton
                            sx={{ p: 0 }}
                            aria-label='expand row'
                            size='small'
                            onClick={() => toggleRowExpansion(row.id)}
                          >
                            {expandedRows[row.id] ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                          </IconButton>
                        )
                      : null}
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
              {expandedRows[row.id] ? (
                <ExpandedTable
                  colSpan={columns?.length}
                  columns={nestedColumns}
                  data={row?.original[nestedColumnKey]}
                  open={expandedRows[row.id]}
                />
              ) : null}
            </React.Fragment>
          ))}
        </tbody>
        {table.getRowModel()?.rows?.length === 0 && !loading ? (
          <tr>
            <td colSpan={8}>
              <NoDataFound mh='30vh' />
            </td>
          </tr>
        ) : null}
      </table>
    </div>
  )
}

export default ReactTable
