import React, { useEffect, useState } from 'react'
import { getTenant } from '../../../middleware/auth'
import moment from 'moment'
const lbl = { fontWeight: '400', fontSize: '10px', color: '#4B5563' }
const val = { marginTop: '8px', fontWeight: '400', fontSize: '10px', color: '#07101c' }
const wraper = { width: '33%', display: 'inline-block', padding: '8px 0', borderBottom: '1px solid #E1E1E1' }
const headings = { padding: '8px 10px', color: '#214F8A', fontSize: '14px', fontWeight: '600', background: '#E9EDF3' }
const mainHeading = {
  padding: '8px 10px',
  color: '#ffffff',
  fontSize: '14px',
  fontWeight: '600',
  background: 'linear-gradient(180deg, #21538B 0%, #13B9AA 100%)',
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '12px'
}

export const DeclarationDetail = ({ idx, title, label }) => {
  let answer = Array.isArray(title)
  return (
    <div style={{ display: 'flex', gap: '50px', marginTop: '12px', fontSize: '10px' }} key={idx}>
      <div style={{ width: '60%' }}>
        {idx + 1}. {label}
      </div>
      <div style={{ width: '40%' }}>
        {answer === true ? (
          title?.length > 0 ? (
            <>
              {title?.map((item, i) => (
                <span key={i}>
                  {item}
                  {i + 1 < title?.length ? ' , ' : ''}
                </span>
              ))}
            </>
          ) : (
            <span>No</span>
          )
        ) : null}
      </div>
    </div>
  )
}

const FinalPrint = ({ printId, data, info }) => {
  const [dieases, setDiseases] = useState([])
  const [result, setResult] = useState(true)
  const [declerations, setDeclarations] = useState([])
  const [screeningDetails, setScreeningDetails] = useState({})
  const [ttiresult, setTtiResult] = useState(true)
  const [failreasion, setFailReasion] = useState([])
  let tenant = getTenant()

  const checkResult = () => {
    let finalresult = true
    let reasion = []
    if (
      data?.BloodBagIssueEntity?.BloodTestingEntity?.hiv == '1' ||
      data?.BloodBagIssueEntity?.BloodTestingEntity?.anty_hcv == '1' ||
      data?.BloodBagIssueEntity?.BloodTestingEntity?.vdrl == '1' ||
      data?.BloodBagIssueEntity?.BloodTestingEntity?.malaria == '1' ||
      data?.BloodBagIssueEntity?.BloodTestingEntity?.hsbg == '1'
    ) {
      finalresult = false
      if (data?.BloodBagIssueEntity?.BloodTestingEntity?.hiv == '1') {
        reasion.push('HIV positive')
      }
      if (data?.BloodBagIssueEntity?.BloodTestingEntity?.anty_hcv == '1') {
        reasion.push('Anti-HCV (Hepatitis C) positive')
      }
      if (data?.BloodBagIssueEntity?.BloodTestingEntity?.vdrl == '1') {
        reasion.push('Syphilis (VDRL) positive')
      }
      if (data?.BloodBagIssueEntity?.BloodTestingEntity?.malaria == '1') {
        reasion.push('Malaria positive')
      }
      if (data?.BloodBagIssueEntity?.BloodTestingEntity?.hsbg == '1') {
        reasion.push('HBsAg(Hepatitis B) positive')
      }
    } else {
    }

    setTtiResult(finalresult)
    setFailReasion(reasion)
  }

  const checkDefferTill = defertill => {
    if (defertill) {
      const startDate = moment() // Current date
      const formattedEndDate = moment(defertill, 'YYYY-MM-DD') // Adjust the date format
      const diffInDays = formattedEndDate.diff(startDate, 'days')
      return diffInDays
    } else {
      return 'N/A'
    }
  }

  useState(() => {
    checkResult()
  }, [data])

  useState(() => {
    if (data) {
      if (data?.id) {
        let latestScreening = data
        setScreeningDetails({ ...latestScreening })
        if (latestScreening?.dieases !== null) {
          let dis = JSON.parse(latestScreening?.dieases)
          setDiseases(dis)
        }
        if (latestScreening?.declration !== null) {
          let dec = JSON.parse(latestScreening?.declration)
          setDeclarations(dec)
        }
      }
    }
  }, [data])

  useEffect(() => {
    if (screeningDetails) {
      if (screeningDetails?.is_defered && !data?.BloodBagIssueEntity?.bag_number) {
        setResult(false)
      }
    }
  }, [screeningDetails])

  // console.log(declerations);
  console.log(info)
  // console.log(tenant);

  const details = [
    { label: 'Name', value: info?.name || 'N/A' },
    { label: 'Registration Number', value: info?.reg_id || 'N/A' },
    { label: 'Donation Date', value: info?.donation_date ? moment(info?.donation_date).format('DD-MMM-YYYY') : 'N/A' },
    { label: 'Contact No. ', value: <>+91 {info?.mobile}</> || 'N/A' },
    {
      label: 'Gender / Age',
      value: (
        <>
          {info?.gender || '-'} / {info?.age || '-'} Yr
        </>
      )
    },
    { label: 'Father/Husband Name', value: info?.father_name || 'N/A' },
    { label: 'Blood Group', value: data?.BloodBagIssueEntity?.BloodTestingEntity?.blood_group || 'N/A' },
    {
      label: 'Current Status',
      value: data?.is_defered ? (data?.defer_type == '1' ? 'Partial Defer' : 'Permanent Defer') : 'Fit'
    }
  ]

  return (
    <div id={printId} style={{ width: '100%', fontSize: '10px', color: '#07101C', fontFamily: 'Inter' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          background: 'linear-gradient(180deg, #21538B 0%, #13B9AA 100%)',
          color: '#FFF',
          minHeight: '60px'
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', padding: '10px' }}>
          <div style={{ fontFamily: 'Inter', fontSize: '12px', fontWeight: '500' }}>
            {tenant?.tenant || 'Blood Bank Name : N/A'}
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            fontSize: '12px',
            fontWeight: '500',
            padding: '10px'
          }}
        >
          <div style={{ fontWeight: '600' }}>Donor Complete Report</div>
        </div>
      </div>

      <div style={{ minHeight: '85vh' }}>
        <div style={headings}>Donor Info</div>

        <div style={{ padding: '0 12px' }}>
          {details.map((item, idx) => {
            return (
              <div style={wraper} key={idx}>
                <div style={lbl}>{item['label']}</div>
                <div style={val}>{item['value'] || 'NA'}</div>
              </div>
            )
          })}
        </div>

        {data?.id ? (
          <>
            <div style={{ marginTop: '40px' }}>
              <div style={mainHeading}>
                <div>Medical Screening</div>
                <div>{data?.screening_date ? moment(data?.screening_date).format('DD-MM-YYYY') : 'N/A'}</div>
              </div>
              <div style={headings}>Body Vitals</div>
              <div style={{ padding: '16px', paddingTop: '0' }}>
                <div style={wraper}>
                  <div style={lbl}>Weight (Kg)</div>
                  <div style={val}>{screeningDetails?.weight || 'N/A'}</div>
                </div>
                <div style={wraper}>
                  <div style={lbl}>Hemoglobin (g/dl)</div>
                  <div style={val}>{screeningDetails?.hemoglobin_level || 'N/A'}</div>
                </div>
                <div style={wraper}>
                  <div style={lbl}>Temperature (Fahrenheit)</div>
                  <div style={val}>{screeningDetails?.temprature || 'N/A'}</div>
                </div>
                <div style={wraper}>
                  <div style={lbl}>Blood Pressure</div>
                  <div style={val}>{screeningDetails?.blood_pressure || 'N/A'}</div>
                </div>
                <div style={wraper}>
                  <div style={lbl}>Pulse</div>
                  <div style={val}>{screeningDetails?.pulse || 'N/A'}</div>
                </div>
              </div>
            </div>

            <div style={{ marginTop: '16px' }}>
              <div style={headings}>Medical Disease</div>
              <div style={{ padding: '16px', paddingTop: '0' }}>
                <div style={{ padding: '8px 0px', fontSize: '10px' }}>
                  {dieases?.length > 0 ? (
                    dieases?.map((item, idx) => (
                      <span style={{ fontWeight: '700', marginRight: '16px' }}>
                        {idx + 1}. {item}
                      </span>
                    ))
                  ) : (
                    <span>Nil</span>
                  )}
                </div>
              </div>
            </div>

            <div>
              <div style={headings}>Volunteer Declaration</div>

              <div style={{ padding: '0 12px', marginBottom: '12px' }}>
                {declerations?.length > 0
                  ? declerations.map((item, idx) => {
                      return (
                        <>
                          {item?.gender === 'Both' && (
                            <div
                              style={{ display: 'flex', gap: '50px', marginTop: '12px', fontSize: '10px' }}
                              key={idx}
                            >
                              <div style={{ width: '60%' }}>
                                {idx + 1}. {item['label']}
                              </div>
                              <div style={{ width: '40%' }}>{item['value'] || 'NA'}</div>
                            </div>
                          )}
                          {item?.gender == info?.gender && (
                            <div
                              style={{ display: 'flex', gap: '50px', marginTop: '12px', fontSize: '10px' }}
                              key={idx}
                            >
                              <div style={{ width: '60%' }}>
                                {idx + 1}. {item['label']}
                              </div>
                              <div style={{ width: '40%' }}>{item['value'] || 'NA'}</div>
                            </div>
                          )}
                          {item.gender == undefined && (
                            <DeclarationDetail title={item['value']} label={item['label']} idx={idx} />
                          )}
                        </>
                      )
                    })
                  : 'N/A'}
              </div>
            </div>

            <div style={{ marginTop: '16px' }}>
              <div style={headings}>Screening By</div>
              <div style={{ padding: '16px', paddingTop: '8px', display: 'flex' }}>
                <div>
                  <div style={lbl}>Doctor Name</div>
                  <div style={val}>{screeningDetails?.counseller || 'N/A'}</div>
                </div>
                <div style={{ marginLeft: '36px' }}>
                  <div style={lbl}>Remarks</div>
                  <div style={val}>{screeningDetails?.reason || '-'}</div>
                </div>
              </div>
            </div>

            <div style={{ borderRadius: '4px', background: '#F8F8F8', paddingBottom: '8px' }}>
              <div style={headings}>Reason</div>
              <div style={{ fontWeight: '600', marginLeft: '16px', marginTop: '8px' }}>
                Result :{' '}
                {result ? (
                  'Donor is fit for blood donation'
                ) : screeningDetails?.defer_type === '1' ? (
                  <>Temporary Defer for {checkDefferTill(screeningDetails?.defer_till)} Days</>
                ) : (
                  <>Permanent Deferred</>
                )}
              </div>
            </div>
          </>
        ) : null}

        {data?.BloodBagIssueEntity?.bag_number ? (
          <>
            <div>
              <div style={mainHeading}>
                <div>Blood Bag Assign To Donor</div>
                <div>
                  {data?.BloodBagIssueEntity?.assigned_date
                    ? moment(data?.BloodBagIssueEntity?.assigned_date).format('DD-MM-YYYY')
                    : 'N/A'}
                </div>
              </div>
              <div style={headings}>Blood Bag Details</div>

              <div style={{ padding: '16px', paddingTop: '0', width: '100%' }}>
                <div style={wraper}>
                  <div style={lbl}>Blood Bag Type</div>
                  <div style={val}>{data?.BloodBagIssueEntity?.bag_type || 'N/A'}</div>
                </div>
                <div style={wraper}>
                  <div style={lbl}>Bag Number</div>
                  <div style={val}>{data?.BloodBagIssueEntity?.bag_number || 'N/A'}</div>
                </div>
                <div style={wraper}>
                  <div style={lbl}>Segment Number</div>
                  <div style={val}>{data?.BloodBagIssueEntity?.tube_number || 'N/A'}</div>
                </div>
                <div style={wraper}>
                  <div style={lbl}>Phlebotomy Duration(In Min)</div>
                  <div style={val}>{data?.BloodBagIssueEntity?.bleeding_duration || 'N/A'}</div>
                </div>
                <div style={wraper}>
                  <div style={lbl}>Phlebotomist</div>
                  <div style={val}>{data?.BloodBagIssueEntity?.phlebotomist || 'N/A'}</div>
                </div>
                <div style={wraper}>
                  <div style={lbl}>LOT Number</div>
                  <div style={val}>{data?.BloodBagIssueEntity?.lot_number || 'N/A'}</div>
                </div>
              </div>
            </div>

            <div style={{ borderRadius: '4px', background: '#F8F8F8', paddingBottom: '8px' }}>
              <div style={headings}>Reason</div>
              <ul>
                <li style={{ color: '#4B5563' }}>{data?.BloodBagIssueEntity?.bag_number ? 'Fit' : 'Not Fit'}</li>
              </ul>
              <div style={{ fontWeight: '600', marginLeft: '16px' }}>
                {data?.BloodBagIssueEntity?.bag_number
                  ? 'Result :  Blood Bag Assigned'
                  : 'Result :  Blood Bag Not Assigned'}
              </div>
            </div>
          </>
        ) : null}

        {data?.BloodBagIssueEntity?.BloodTestingEntity?.id ? (
          <>
            <div style={{ marginTop: '16px' }}>
              <div style={mainHeading}>
                <div>TTI Testing</div>
                <div>
                  {data?.BloodBagIssueEntity?.BloodTestingEntity?.createdAt
                    ? moment(data?.BloodBagIssueEntity?.BloodTestingEntity?.createdAt).format('DD-MM-YYYY')
                    : 'N/A'}
                </div>
              </div>
              <div>
                <div style={headings}>TTI KIT Information</div>
                <div style={{ padding: '16px', paddingTop: '0' }}>
                  <div style={wraper}>
                    <div style={lbl}>HIV KIT</div>
                    <div style={val}>{data?.BloodBagIssueEntity?.BloodTestingEntity?.hivkit || 'N/A'}</div>
                  </div>
                  <div style={wraper}>
                    <div style={lbl}>Hepatitis C KIT</div>
                    <div style={val}>{data?.BloodBagIssueEntity?.BloodTestingEntity?.hepatitisckit || 'N/A'}</div>
                  </div>
                  <div style={wraper}>
                    <div style={lbl}>Malaria KIT</div>
                    <div style={val}>{data?.BloodBagIssueEntity?.BloodTestingEntity?.malariakit || 'N/A'}</div>
                  </div>
                  <div style={wraper}>
                    <div style={lbl}>Syphilis (VDRL) KIT</div>
                    <div style={val}>{data?.BloodBagIssueEntity?.BloodTestingEntity?.syphiliskit || 'N/A'}</div>
                  </div>
                  <div style={wraper}>
                    <div style={lbl}>Hepatitis B KIT</div>
                    <div style={val}>{data?.BloodBagIssueEntity?.BloodTestingEntity?.hepatitisbkit || 'N/A'}</div>
                  </div>
                </div>
              </div>
              <div style={headings}>TTI Information</div>
              <div style={{ padding: '16px', paddingTop: '0' }}>
                <div style={wraper}>
                  <div style={lbl}>Blood Bag Number</div>
                  <div style={val}>{data?.BloodBagIssueEntity?.bag_number || 'N/A'}</div>
                </div>
                <div style={wraper}>
                  <div style={lbl}>Blood Group</div>
                  <div style={val}>{data?.BloodBagIssueEntity?.BloodTestingEntity?.blood_group || 'N/A'}</div>
                </div>
                <div style={wraper}>
                  <div style={lbl}>HIV</div>
                  <div style={val}>
                    {data?.BloodBagIssueEntity?.BloodTestingEntity?.hiv == '1' ? (
                      <span style={{ fontWeight: '700', textDecorationLine: 'underline' }}>Reactive</span>
                    ) : (
                      'Non-Reactive'
                    )}
                  </div>
                </div>
                <div style={wraper}>
                  <div style={lbl}>Anti-HCV(Hepatitis C)</div>
                  <div style={val}>
                    {data?.BloodBagIssueEntity?.BloodTestingEntity?.anty_hcv == '1' ? (
                      <span style={{ fontWeight: '700', textDecorationLine: 'underline' }}>Reactive</span>
                    ) : (
                      'Non-Reactive'
                    )}
                  </div>
                </div>
                <div style={wraper}>
                  <div style={lbl}>Malaria</div>
                  <div style={val}>
                    {data?.BloodBagIssueEntity?.BloodTestingEntity?.malaria == '1' ? (
                      <span style={{ fontWeight: '700', textDecorationLine: 'underline' }}>Positive</span>
                    ) : (
                      'Non-Reactive'
                    )}
                  </div>
                </div>
                <div style={wraper}>
                  <div style={lbl}>Syphilis (VDRL)</div>
                  <div style={val}>
                    {data?.BloodBagIssueEntity?.BloodTestingEntity?.vdrl == '1' ? (
                      <span style={{ fontWeight: '700', textDecorationLine: 'underline' }}>Reactive</span>
                    ) : (
                      'Non-Reactive'
                    )}
                  </div>
                </div>
                <div style={wraper}>
                  <div style={lbl}>HBsAg(Hepatitis B)</div>
                  <div style={val}>
                    {data?.BloodBagIssueEntity?.BloodTestingEntity?.hsbg == '1' ? (
                      <span style={{ fontWeight: '700', textDecorationLine: 'underline' }}>Reactive</span>
                    ) : (
                      'Non-Reactive'
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div style={{ borderRadius: '4px', background: '#F8F8F8', paddingBottom: '8px' }}>
              <div style={headings}>Reason</div>
              {!ttiresult ? (
                <>
                  <ul>
                    {failreasion?.length > 0
                      ? failreasion?.map((ele, idx) => (
                          <li key={idx} style={{ color: '#4B5563' }}>
                            {ele}
                          </li>
                        ))
                      : null}
                  </ul>
                </>
              ) : null}
              <div style={{ fontWeight: '600', marginLeft: '16px', marginTop: '8px' }}>
                {ttiresult ? 'Result :  TTI test pass' : 'Result :  TTI test fail'}
              </div>
            </div>
          </>
        ) : null}

        {data?.BloodBagIssueEntity?.BloodLogsEntities &&
        data?.BloodBagIssueEntity?.BloodLogsEntities?.length > 0 &&
        ttiresult ? (
          <div>
            <div style={mainHeading}>
              <div> Inventory Details</div>
              <div>
                {data?.BloodBagIssueEntity?.BloodLogsEntities
                  ? moment(
                      data?.BloodBagIssueEntity?.BloodLogsEntities[
                        data?.BloodBagIssueEntity?.BloodLogsEntities?.length - 1
                      ].createdAt
                    ).format('DD-MM-YYYY')
                  : 'N/A'}
              </div>
            </div>
            <div style={headings}>Inventory Details</div>
            <div style={{ padding: '16px', paddingTop: '0', width: '100%' }}>
              {data?.BloodBagIssueEntity?.BloodLogsEntities?.map(ele => (
                <div style={{ marginTop: '12px' }}>
                  <div style={wraper}>
                    <div style={lbl}>Component</div>
                    <div style={val}>{ele.component || 'N/A'}</div>
                  </div>
                  <div style={wraper}>
                    <div style={lbl}>Volume</div>
                    <div style={val}>{ele?.volume || 'N/A'}</div>
                  </div>
                  <div style={wraper}>
                    <div style={lbl}>Date Of Expiry</div>
                    <div style={val}>{ele?.expiry_date ? moment(ele?.expiry_date).format('DD-MMM-YYYY') : 'N/A'}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : null}

        {data?.BloodBagIssueEntity?.BloodTestingEntity?.id ? (
          <div style={{ display: 'flex', flexDirection: 'row-reverse', marginTop: '70px', paddingRight: '75px' }}>
            <div style={{ borderTopStyle: 'dashed', padding: '12px', color: '#9CA3AF', fontSize: '12px' }}>
              Medical Officer
            </div>
          </div>
        ) : null}
      </div>

      <div
        style={{
          marginTop: '30px',
          height: '60px',
          background: '#EBFEF9',
          display: 'flex',
          flexDirection: 'column',
          gap: '14px',
          padding: '8px 16px',
          color: '#374151',
          fontSize: '12px',
          justifyContent: 'center'
        }}
      >
        <div style={{ display: 'flex', gap: '36px' }}>
          <div style={{ display: 'flex', gap: '8px' }}>
            <img src={'../assets/images/invoice/material-symbols_call-outline.svg'} alt='contact' />
            <span>{tenant?.phone_number || 'N/A'}</span>
          </div>
          <div style={{ display: 'flex', gap: '8px' }}>
            <img src={'../assets/images/invoice/fontisto_email.svg'} alt='email' />
            <span>{tenant?.email || 'N/A'}</span>
          </div>
        </div>
        <div
          style={{
            fontSize: '14px',
            gap: '8px'
          }}
        >
          <div style={{ display: 'flex', gap: '8px' }}>
            <img src={'../assets/images/invoice/carbon_location.svg'} alt='location' />
            <span>
              {tenant?.TenantAddressEntity?.street ? tenant?.TenantAddressEntity?.street : ''}{' '}
              {tenant?.TenantAddressEntity?.area ? tenant?.TenantAddressEntity?.area : ''}{' '}
              {tenant?.TenantAddressEntity?.city ? tenant?.TenantAddressEntity?.city : ''}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}
export default FinalPrint
