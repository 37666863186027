import React, { useState, useEffect } from 'react'
import Loader from '../shared/Loader'
import AxiosHelper from '../../helper/AxiosHelper'
import { useHistory, useParams } from 'react-router-dom'
import BreadCrumb from '../common/BreadCrumb'
import { Alert, Box, Card, Collapse, Grid, IconButton, Radio, Typography } from '@mui/material'
import ModalCustom from '../shared/ModalCustom'
import { toast } from 'react-toastify'
import ScrollCard from '../shared/ScrollCard'
import { checkPermission } from '../middleware/auth'
import { useAppContext } from '../../AppContext'

export function ImageCard({
  data,
  id,
  type = 'bank',
  checked,
  setChecked,
  setErrorMsg,
  setErrorShow,
  setIsAttached,
  isAttached
}) {
  const [showPrev, setShowPrev] = useState(false)
  const [prevData, setPrevData] = useState('')
  const [loading, setLoading] = useState(false)
  const {setActiveTutorial} = useAppContext()
  const history = useHistory()

  useEffect(() => {
    if (data?.is_active === true) {
      setChecked(data?.id)
      setIsAttached(true)
    } else {
      setChecked('')
      setIsAttached(false)
    }
  }, [data])

  function handleCheck(e) {
    if (checked === data?.id) {
      setChecked(null)
    } else {
      if (!data?.CertificateEntity || data?.CertificateEntity === null) {
        setErrorMsg('Please Fill all the details in template')
        setErrorShow(true)
        return
      }
      setChecked(data?.id)
      setErrorShow(false)
    }
  }

  useEffect(() => {
    setActiveTutorial('attachCertificate')

    return () => {
      setActiveTutorial('')
    }
  }, [])

  function openPreview() {
    setLoading(true)
    AxiosHelper.get(
      `certificate/preview?template_id=${data?.id}&is_camp=${
        type === 'camp' ? true : false
      }${type === 'camp' && id ? '&camp_id=' + id : ''}`
    )
      .then(response => {
        setLoading(false)
        setPrevData(response.data.data)
        openHtmlInNewTab(response.data.data)
        setShowPrev(true)
      })
      .catch(error => {
        setLoading(false)
        console.log(error)
        toast.error('Unable to Load Preview at the moment')
      })
    setShowPrev(true)
  }

  const openHtmlInNewTab = htmlContent => {
    const newWindow = window.open('', '_blank')
    newWindow.document.write(htmlContent)
    newWindow.document.close()
  }

  return (
    <Grid display='flex' alignItems='center' item xs={12} sm={6} md={4} lg={3}>
      <Radio checked={checked === data?.id} onClick={handleCheck} />
      <Card
        className='overlay__main '
        sx={{
          minHeight: '10rem',
          padding: 1,
          position: 'relative',
          borderRadius: '16px',
          boxShadow: '0px 4px 14px 0px #00000024',
          backgroundImage: 'linear-gradient(white, white),linear-gradient(90.26deg, #214f89 0.17%, #12bcab 99.77%)'
        }}
      >
        {!data?.CertificateEntity || data?.CertificateEntity === null ? null : isAttached ? (
          <div class='cr cr-bottom cr-left cr-sticky cr-orange'>Attached</div>
        ) : (
          <div class='cr cr-bottom cr-left cr-sticky cr-orange'>Ready</div>
        )}
        <div className='overlay-container'>
          <img
            style={{
              height: '100%',
              width: '100%',
              objectFit: 'contain'
            }}
            src={data?.icon || '/assets/images/certificate/Template.png'}
            alt=''
          />
          <div className='overlay'></div>
        </div>
        <div className='icon'>
          <Typography fontWeight={600} fontSize='1rem' color='white'>
            Template {data?.id}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 1,
              mt: 2
            }}
          >
            {checkPermission('UPDATE_CERTIFICATE') === true ? (
              <IconButton sx={{ background: 'white', borderRadius: 2 }}>
                <img
                  onClick={() => {
                    let url = id
                      ? `/certification/edit/${data?.id}/${type}/${id}`
                      : `/certification/edit/${data?.id}/${type}`
                    history.push(url)
                  }}
                  src={'/assets/images/editIcon.svg'}
                  alt='edit icon'
                />
              </IconButton>
            ) : null}
            <IconButton sx={{ background: 'white', borderRadius: 2 }}>
              <img onClick={openPreview} src={'/assets/images/alldonors/view_donor.svg'} alt='edit icon' />
            </IconButton>
          </Box>
        </div>
      </Card>

      <Loader isLoading={loading} />
      {/* <ModalCustom
        open={showPrev}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
        closeDialog={() => setShowPrev(false)}
        // fullScreen={true}
        scroll="paper"
        size="lg"
        title={data?.id ? `Template ${data?.id}` : "Certificate title here"}
      >
        <Card dangerouslySetInnerHTML={{ __html: prevData }}></Card>
      </ModalCustom> */}
    </Grid>
  )
}
export const ImageBox = ({ title, handleChoice, img, imgColor, type, style, active }) => {
  const [colored, setColored] = useState(false)
  return (
    <div
      onClick={() => {
        handleChoice(type)
        setColored(!colored)
      }}
      style={style}
      className={`choice-container certificate_ ${active ? 'active ' : ''}`}
      name='abha_no'
    >
      <img src={active ? imgColor : img} alt={title} />
      <p>{title}</p>
    </div>
  )
}

const Certification = () => {
  const [data, setData] = useState([])
  const [isLoading, setLoading] = useState(false)
  const [btnLoading, setBtnLoading] = useState(false)
  const [errorShow, setErrorShow] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [type, setType] = useState('bank')
  const [checked, setChecked] = useState(null)
  const [isAttached, setIsAttached] = useState(false)
  const [isAttachedMsg, setIsAttachedMsg] = useState(false)
  const history = useHistory()

  const { types, id } = useParams()

  const breadcrumbRoute = [
    {
      path: '/dashboard',
      label: 'Dashboard'
    },
    {
      path: '/',
      label: 'Certification'
    }
  ]
  const handleChoice = type => {
    setType(type)
    setErrorMsg('')
    setErrorShow(false)
    getTemplateLists(type)
  }

  const getTemplateLists = filter => {
    setLoading(true)
    AxiosHelper.get(`certificate/template?is_camp=${filter === 'camp' ? true : false}${id ? '&camp_id=' + id : ''}`)
      .then(response => {
        setData(response.data.data)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
        console.log(error)
      })
  }

  const activeTemplate = () => {
    if (checked === null || !checked) {
      setErrorMsg(' Please Select Template To Proceed!')
      setErrorShow(true)
      return
    }
    if (isAttached) {
      setIsAttachedMsg(true)

      setTimeout(() => {
        setIsAttachedMsg(false)
      }, 4000)

      return
    }
    setErrorShow(false)
    setBtnLoading(true)
    let data = {
      template_id: checked,
      is_camp: type === 'camp' ? true : false,
      camp_id: id
    }
    AxiosHelper.post(`certificate/template-active`, data)
      .then(response => {
        setBtnLoading(false)
        handleChoice(type)
        toast.success('Certificate Activated Successfully')
        setErrorShow(false)
      })
      .catch(error => {
        setBtnLoading(false)
        console.log(error)
        setErrorMsg(error)
        setErrorShow(true)
      })
  }

  useEffect(() => {
    if (types) {
      setType(types)
    }
    getTemplateLists(types)
  }, [types])

  useEffect(() => {
    console.log(data)
  }, [data])

  if (isLoading) {
    return <Loader isLoading={true} />
  }

  return (
    <>
      <Loader isLoading={btnLoading} />
      <BreadCrumb breadcrumbRoute={breadcrumbRoute} />

      <ScrollCard>
        <Grid padding={3} container sx={{ background: '#F2F9F7' }}>
          <Grid pb={2} item xs={12}>
            <Typography fontWeight={600} fontSize='1rem' textAlign='center'>
              Please choose certificate for{' '}
            </Typography>
          </Grid>
          <Grid display='flex' gap={2} justifyContent='center' item xs={12}>
            <ImageBox
              type={'bank'}
              active={type === 'bank'}
              style={{ backgroundColor: 'white' }}
              title='Blood Bank'
              handleChoice={handleChoice}
              setIsAttached={setIsAttached}
              isAttached={isAttached}
              img={'/assets/images/certificate/bank-normal.png'}
              imgColor={'/assets/images/certificate/bank-active.png'}
            />
            <ImageBox
              active={type === 'camp'}
              style={{ backgroundColor: 'white' }}
              type={'camp'}
              title='Blood Camp'
              handleChoice={handleChoice}
              isAttached={isAttached}
              setIsAttached={setIsAttached}
              img={'/assets/images/certificate/tent-normal.png'}
              imgColor={'/assets/images/certificate/tent-active.png'}
            />
          </Grid>
        </Grid>

        <Typography sx={{ py: 2 }} fontWeight={600} fontSize='1rem'>
          Please select template to proceed{' '}
        </Typography>
        <Grid container spacing={1}>
          {data?.length
            ? data?.map((item, i) => (
                <ImageCard
                  checked={checked}
                  setChecked={setChecked}
                  id={id}
                  key={i}
                  data={item}
                  type={type}
                  isAttached={isAttached}
                  setIsAttached={setIsAttached}
                  setErrorMsg={setErrorMsg}
                  setErrorShow={setErrorShow}
                />
              ))
            : null}
        </Grid>
        <Collapse in={errorShow} sx={{ mt: 2 }}>
          <Alert severity='error'>{errorMsg}</Alert>
        </Collapse>
        <Collapse in={isAttachedMsg} sx={{ mt: 2 }}>
          <Alert
            severity='info'
            sx={{
              '&.MuiAlert-message': {
                textDecoration: 'none'
              }
            }}
          >
            {'Certificate already attached'}
          </Alert>
        </Collapse>
        {checkPermission('CREATE_CERTIFICATE') === true ? (
          <div className='flex__box mt-5  responsiveDiv-create-donor'>
            <button
              disabled={isLoading}
              onClick={activeTemplate}
              className='btn btn-gradient-primary mr-2 rounded-lg responsiveBtn-create-donor'
            >
              Attach Certificate
            </button>
            <button
              // disabled={isLoading}
              // onClick={() => handleChoice("")}
              className='btn__cancel btn mr-2 rounded-lg responsiveBtn-create-donor'
            >
              Cancel
            </button>
          </div>
        ) : null}
      </ScrollCard>
    </>
  )
}

export default Certification
