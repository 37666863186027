import moment from 'moment'
import Timeline from '@mui/lab/Timeline'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent'
import TimelineDot from '@mui/lab/TimelineDot'
import FlagCircleIcon from '@mui/icons-material/FlagCircle'
import { Typography } from '@mui/material'
import NoDataFound from '../../common/NoDataFound'
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { ExpandMore } from '@mui/icons-material'
export const PersonalTrail = ({ userData, timelineData, timelineFormat = true, bagData, openTimeline, setOpenTimeline }) => {
  return (
    <div
      className='shadowCell'
      style={{ padding: '16px', display: 'flex', flexDirection: 'column', gap: '10px', marginBottom: '10PX' }}
    >
      <div style={{ borderBottom: '1px solid #ddd' }}>
        <div className='trail-subheading'>Timeline</div>
        <div className='trail-small'>
          Last Updated :{userData?.updatedAt ? moment(userData?.updatedAt).format('DD MMM YYYY hh:mm A') : ''}{' '}
        </div>
      </div>
      <Timeline position='alternate'>
        {timelineData ? (
          Object.values(timelineData).map((item, idx, arr) => {
            return timelineFormat ? (
             !openTimeline ? (
              idx < 4 ? (<TimelineRow key={idx} item={item} />) : null
             ) : (<TimelineRow key={idx} item={item} />)
            ) : (
              <TimelineDots arr={arr} idx={idx} item={item} />
            )
          })
        ) : (
          <div className='dnr-no-data-found'>
            <NoDataFound title='No Record found' />
          </div>
        )}
       {
        timelineFormat && timelineData && Object.values(timelineData).length > 4 ? (
          !openTimeline ? <div
          style={{
            textAlign: 'center',
            marginTop: '1rem',
            cursor: 'pointer'
          }} className='trail-subheading' onClick={() => setOpenTimeline(true)}>More <ExpandMore /></div> : <div
          style={{
            textAlign: 'center',
            marginTop: '1rem',
            cursor: 'pointer'
          }} className='trail-subheading' onClick={() => setOpenTimeline(false)}>Less <ExpandLessIcon /></div>
        ) : null
       }
      </Timeline>
    </div>
  )
}

export const TimelineRow = ({ item, idx }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', position: 'relative' }} key={idx}>
      <div style={{ display: 'flex', gap: '6px' }}>
        <div style={{ height: '100%' }}>
          <img src={'/assets/images/donor_profile/rod.svg'} alt='rod flag' className='rod-flag' />
          <img src={'/assets/images/donor_profile/dateflag.svg'} alt='date flag' className='date-flag' />
          <div className='profile-date'>{item?.date ? moment(item?.date).format(' MMM YYYY') : ''}</div>
        </div>
        <div style={{ marginTop: '45px', marginLeft: '20px', height: 'fit-content' }}>
          <div className='profile-heading'>{item?.label}</div>
          <div className='profile-subheading'>{item?.label1}</div>
        </div>
      </div>
    </div>
  )
}

export const TimelineDots = ({ item, idx, arr }) => {
  //console.log(`item data -> `, item)
  return (
    <TimelineItem
      key={idx}
      sx={{
        // display: 'flex',
        // justifyContent: 'center',
        // alignItems: 'center',
        // margin: 'auto'
      }}
    >
      <TimelineOppositeContent sx={{ m: '5px 0' }} align='right' variant='body2' color='text.secondary'>
        <div className='profile-heading'>{item?.date ? moment(item?.date).format('DD MMM YYYY') : ''}</div>
        <div className='profile-heading'>{item?.date ? moment(item?.date).format('hh:mm A') : ''}</div>
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot color='primary'>
          <FlagCircleIcon />
        </TimelineDot>

        {idx === arr.length - 1 ? null : <TimelineConnector />}
        {/* <TimelineConnector/> */}
      </TimelineSeparator>
      <TimelineContent sx={{ py: '12px', px: 2 }}>
        <Typography>
          <div className='profile-heading'>{item?.label}</div>
          <div className='profile-subheading'>{item?.label1}</div>
        </Typography>
      </TimelineContent>
    </TimelineItem>
  )
}
