// ** MUI Imports
import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import MuiSwipeableDrawer from '@mui/material/SwipeableDrawer'

const SwipeableDrawer = styled(MuiSwipeableDrawer)({
  transition: 'width .25s ease-in-out',
  '& .MuiDrawer-paper': {
    left: 'unset',
    right: 'unset',
    top: 'unset',
    borderRadius: '8px',
    height: 'calc(100vh - 14px)',
    zIndex: 1200,
    overflowX: 'hidden',
    transition: 'width .25s ease-in-out, box-shadow .25s ease-in-out'
  }
})

const Drawer = props => {
  // ** Props
  const {
    hidden,
    children,
    navHover,
    navWidth,
    settings,
    navVisible,
    setNavHover,
    navMenuProps,
    setNavVisible,
    collapsedNavWidth,
    saveSettings,
    navigationBorderWidth
  } = props

  // ** Vars
  const { navCollapsed } = settings
  let flag = true

  // Drawer Props for Mobile & Tablet screens
  const MobileDrawerProps = {
    open: navVisible,
    onOpen: () => setNavVisible(true),
    onClose: () => setNavVisible(false),
    ModalProps: {
      keepMounted: true // Better open performance on mobile.
    }
  }

  // Drawer Props for Laptop & Desktop screens
  const DesktopDrawerProps = {
    open: true,
    onOpen: () => null,
    onClose: () => null,
    onMouseEnter: () => {
      // Declared flag to resolve first time flicker issue while trying to collapse the menu
      if (flag || navCollapsed) {
        setNavHover(true)
        flag = false
      }
    },
    onMouseLeave: () => {
      if (navCollapsed) {
        setNavHover(false)
      }
    }
  }
  let userNavMenuStyle = {}
  let userNavMenuPaperStyle = {}

  if (navMenuProps && navMenuProps.sx) {
    userNavMenuStyle = navMenuProps.sx
  }
  if (navMenuProps && navMenuProps.PaperProps && navMenuProps.PaperProps.sx) {
    userNavMenuPaperStyle = navMenuProps.PaperProps.sx
  }
  const userNavMenuProps = Object.assign({}, navMenuProps)
  delete userNavMenuProps.sx
  delete userNavMenuProps.PaperProps

  return (
    <>
      <SwipeableDrawer
        className='layout-vertical-nav '
        variant={hidden ? 'temporary' : 'permanent'}
        {...(hidden ? { ...MobileDrawerProps } : { ...DesktopDrawerProps })}
        PaperProps={{
          className: 'sidebar',
          sx: {
            background: 'linear-gradient(90deg,#214f89,#12bcab)',
            position: 'relative',
            padding: '10px 5px 0px 10px',
            width: navCollapsed && !navHover ? collapsedNavWidth : navWidth,
            ...(!hidden && navCollapsed && navHover ? { boxShadow: 10 } : {}),
            borderRight: theme =>
              navigationBorderWidth === 0 ? 0 : `${navigationBorderWidth}px solid ${theme.palette.divider}`,
            ...userNavMenuPaperStyle
          },
          ...navMenuProps?.PaperProps
        }}
        sx={{
          width: navCollapsed ? collapsedNavWidth : navWidth,
          ...userNavMenuStyle
        }}
        {...userNavMenuProps}
      >
        {children}
      </SwipeableDrawer>
      <Box
        sx={{
          position: navCollapsed && !navHover ? 'absolute' : 'absolute',
          width: navCollapsed && !navHover ? collapsedNavWidth : navWidth,
          transition: 'width .25s ease-in-out'
        }}
      >
        {hidden ? null : (
          <img
            onClick={() => saveSettings({ ...settings, navCollapsed: !navCollapsed })}
            type='button'
            className='menu-logo absolute_togle'
            src={navCollapsed ? '/assets/images/navbar/toggle.png' : '/assets/images/menu_icon.svg'}
            alt='menu logo'
          />
        )}
      </Box>
    </>
  )
}

export default Drawer
