import { Button, IconButton, Tooltip } from '@mui/material'
import React, { useState } from 'react'
import ReactPlayer from 'react-player'
import ModalCustom, { ModalActions } from './ModalCustom'
import { useAppContext } from '../../AppContext'
import Lottie from 'lottie-react'
import animationData from './tutorial.json' // Update the path to your JSON file

const videoUrl = {
  bloodStock:
    'https://ehr-training.s3.ap-south-1.amazonaws.com/drive-download-20240519T150504Z-001/Blood+Stock+Reporting+to+e+-+rakht+kosh+(1).mp4',
  bloodStockHindi:
    'https://ehr-training.s3.ap-south-1.amazonaws.com/drive-download-20240519T150504Z-001/Blood+Stock+Reporting+to+e+-+rakht+kosh-+Hindi.mp4',
  donorRegisteration:
    'https://ehr-training.s3.ap-south-1.amazonaws.com/drive-download-20240519T150504Z-001/How+To+Register+Donor-+Shorter+verison.mp4',
  donorRegisterationAllProcess:
    'https://ehr-training.s3.ap-south-1.amazonaws.com/drive-download-20240519T150504Z-001/Eblood+Connect+Process+from+Screening+to+Stock+Inventory.mp4',
  allDonors:
    'https://ehr-training.s3.ap-south-1.amazonaws.com/drive-download-20240519T150504Z-001/All+Donor+Screen+Information.mp4',
  attachCertificate: 'https://youtu.be/k_8s1NYaUHY?si=xPuIgmcjj_2yF_eB',
  manageCamp:
    'https://ehr-training.s3.ap-south-1.amazonaws.com/drive-download-20240519T150504Z-001/How+To+Manage+Camp+through+e-blood+connect.mp4'
}
const videoTitle = {
  bloodStock: 'Blood Stock Reporting to e - rakht kosh',
  bloodStockHindi: 'Blood Stock Reporting to e - rakht kosh- Hindi',
  donorRegisteration: 'How To Register Donor',
  donorRegisterationAllProcess: 'Eblood Connect Process from Screening to Stock Inventory',
  allDonors: 'All Donor Screen Information',
  attachCertificate: 'How to Attach Certificate updated',
  manageCamp: 'How To Manage Camp through e-blood connect'
}

function Tutorials() {
  const [openVideo, setOpenVideo] = useState(false)
  const { activeTutorial } = useAppContext()

  function handleModalClose() {
    setOpenVideo(false)
  }

  if (!activeTutorial) return null

  return (
    <>
      <Tooltip title='Information'>
        <IconButton
          sx={{ p: 0 }}
          onClick={() => {
            setOpenVideo(!openVideo)
          }}
        >
          <Lottie animationData={animationData} style={{ width: 32, height: 32 }} />
        </IconButton>
      </Tooltip>

      <ModalCustom
        open={openVideo}
        title={videoTitle[activeTutorial] || 'Tutorial'}
        size='md'
        dialogStyle={{
          px: 2,
          pt: 0,
          pb: 0
        }}
        closeDialog={handleModalClose}
        // actionComponent={(
        //   <ModalActions>
        //     <div className="d-flex justify-content-end align-items-end p-3 ">
        //       <button type="button" className="btn backBtn mr-2 " onClick={handleModalClose}>
        //         Close
        //       </button>
        //     </div>
        //   </ModalActions>
        // )}
      >
        <div style={{ width: '100%', height: '500px', padding: '1rem' }} className='react_player'>
          <ReactPlayer
            url={videoUrl[activeTutorial] || 'https://youtu.be/ixXyTCuqgNU'}
            playing={openVideo}
            controls
            // volume={null}
            // muted={false}
            width='100%'
            height='100%'
          />
        </div>
      </ModalCustom>
    </>
  )
}

export default Tutorials
