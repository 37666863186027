// ** MUI Imports
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import { styled, useTheme } from '@mui/material/styles'

import { Close, Menu } from '@mui/icons-material'
import { Link } from 'react-router-dom'
import { getEntityInfo, getInitials, getName, getTenatName,getThreeInitials } from '../../app/middleware/auth'
import { useAppContext } from '../../AppContext'
import { Avatar } from '@mui/material'

// ** Styled Components
const MenuHeaderWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  transition: 'padding .25s ease-in-out',
  minHeight: theme.mixins.toolbar.minHeight
}))

function VerticalNavHeader(props) {
  // ** Props
  const {
    hidden,
    navHover,
    settings,
    saveSettings,
    toggleNavVisibility,
    menuLockedIcon: userMenuLockedIcon,
    menuUnlockedIcon: userMenuUnlockedIcon
  } = props
  const { user } = useAppContext()

  const { navCollapsed } = settings

  return (
    <>
      {getEntityInfo() != 1 ? (
        <ul className='nav'>
          <div className=' nav-item nav-profile'>
            <span className='nav-link' onClick={evt => evt.preventDefault()}>
              {user?.logo ? (
                <Avatar
                  sx={{ bgcolor: 'white', color: 'black', fontWeight: 500, height: 44, width: 44 }}
                  alt={getThreeInitials(getTenatName())}
                  src={user?.logo || ''}
                />
              ) : (
                <div className='nav-profile-image'>
                  <p>{getThreeInitials(getTenatName())}</p>
                  <span className='login-status online' />{' '}
                </div>
              )}
              {/* <div className='nav-profile-image'>
                <p>{getInitials(getTenatName())}</p>
                <span className='login-status online' />{' '}
              </div> */}
              <div className='nav-profile-text hidetext'>
                <span className='font-weight-bold mb-2'>{getTenatName()}</span>
                <span className='text-small'>{getName()}</span>
              </div>
            </span>
          </div>
        </ul>
      ) : (
        <div className='d-flex mx-3 my-1'>
          <Link className='logo-container' to='/'>
            <img className='banner-logo' src='/assets/images/logo.svg' alt='logo' />
            <img className='mini-logo' src='/assets/images/logo-mini.svg' alt='logo' />
          </Link>
        </div>
      )}

      {/* {hidden ? (
        <IconButton
          disableRipple
          disableFocusRipple
          onClick={toggleNavVisibility}
          sx={{ p: 0, backgroundColor: 'transparent !important' }}
        >
          <Close fontSize='small' />
        </IconButton>
      ) : navCollapsed && !navHover ? null : (
        <>
          {userMenuLockedIcon && userMenuUnlockedIcon ? (
            navCollapsed ? (
              userMenuUnlockedIcon
            ) : (
              userMenuLockedIcon
            )
          ) : (
            <div className='absolute_togle'>
              <img
                onClick={() => saveSettings({ ...settings, navCollapsed: !navCollapsed })}
                type='button'
                className='menu-logo '
                src={navCollapsed ? '/assets/images/navbar/toggle.png' : '/assets/images/menu_icon.svg'}
                alt='menu logo'
              />
            </div>
          )}
        </>
      )} */}
    </>
  )
}

export default VerticalNavHeader
