import '../ui/style.css'
// Helper Component for Info Items
export const InfoItem = ({ title, value, children, is_bulk = false }) => (
  <div className='pd-ib-item1'>
    <div
      style={{
        display: 'flex',
        gap: '0.5rem',
        flexDirection: 'column'
      }}
      className='flex-bag'
    >
      <div className='cont-heading'>{title}</div>
      <div className='general-black'>{!is_bulk ? value || children : '-'}</div>
    </div>
  </div>
)
