import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import AxiosHelper from '../../helper/AxiosHelper'
import PaginationLink from '../pagination/PaginationLink'
import NoDataFound from '../common/NoDataFound'
import Loader from '../shared/Loader'
import moment from 'moment'
import BreadCrumb from '../common/BreadCrumb'
import { Checkbox, IconButton, Toolbar, Tooltip, Typography, alpha } from '@mui/material'
import ToolTips from '../common/ToolTips'
import ScrollCard from '../shared/ScrollCard'
import ScrollBar from 'react-perfect-scrollbar'
import dayjs from 'dayjs'
import { toast } from 'react-toastify'

export const getFilenameFromURL = url => {
  const parts = url.split('/')
  return parts[parts.length - 1]
}

export const downloadFile = async (url, filename,toastId) => {
  const response = await fetch(url) 
  const blob = await response.blob()

  // const filename = getFilenameFromURL(url);
  const link = document.createElement('a')
  link.href = URL.createObjectURL(blob)

  let defaultFileName = `${filename ? filename : ""}${filename ? "-":""}`+ dayjs().format('DD-MM-YYYY_HH-mm')
  if (!filename) {
    let name="ehr-"+ defaultFileName
    link.download = name
  } else {
    link.download = defaultFileName
  }
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
  if(toastId){
    toast.update(toastId, { render: "Your file has been successfully downloaded", type: "success", isLoading: false,autoClose: 2000, });
  }
   
}

const IssueCertification = () => {
  const [data, setData] = useState([])
  const [pageData, setPageData] = useState(null)
  const [page, setPage] = useState(1)
  const [isLoading, setLoading] = useState(false)
  const [selected, setSelected] = React.useState([])

  const history = useHistory()

  const getDonors = () => {
    setLoading(true)
    AxiosHelper.get(`donor?page=${page}`)
      .then(response => {
        setData(response.data.data)
        setPageData(response.data.data.pagination)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
        console.log(error)
      })
  }

  useEffect(() => {
    getDonors()
  }, [page])

  const downloadCertificate = id => {
    AxiosHelper.post('certificate/donor-certifictate', {
      abha_id: id
    })
      .then(response => {
        window.open(response?.data?.data, '_blank', 'rel=noopener noreferrer')
        // downloadFile(response?.data?.data);
      })
      .catch(error => {
        console.log(error)
      })
  }

  const breadcrumbRoute = [
    {
      path: '/dashboard',
      label: 'Dashboard'
    },
    {
      label: 'Issue Certification'
    }
  ]

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name)
    let newSelected = []
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))
    }
    setSelected(newSelected)
  }

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelected = data?.response?.map(n => n.id)
      setSelected(newSelected)
      return
    }
    setSelected([])
  }

  const isSelected = name => selected.indexOf(name) !== -1

  return (
    <>
      <Loader isLoading={isLoading} />
      <BreadCrumb breadcrumbRoute={breadcrumbRoute} />
      <ScrollCard>
        <div className='my-auto pb-2'>
          <Toolbar
            sx={{
              pl: { sm: 2 },
              pr: { xs: 1, sm: 1 },
              ...(selected?.length > 0 && {
                bgcolor: theme => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity)
              })
            }}
          >
            {selected?.length > 0 ? (
              <Typography sx={{ flex: '1 1 100%' }} color='inherit' variant='subtitle1' component='div'>
                {selected?.length} selected
              </Typography>
            ) : (
              <Typography sx={{ flex: '1 1 100%' }} variant='h6' id='tableTitle' component='div'>
                Donors
              </Typography>
            )}

            {selected?.length > 0 ? (
              <>
                <Tooltip title='Issue Certificate'>
                  <IconButton
                  //  onClick={openDelete}
                  >
                    <img
                      style={{ maxWidth: '40px' }}
                      // onClick={() => {
                      //   downloadCertificate(item.id);
                      // }}
                      src={'/assets/images/certificate/certificate.png'}
                      alt='certificate'
                    />{' '}
                  </IconButton>
                </Tooltip>
              </>
            ) : null}
          </Toolbar>

          <div className='table-responsive mt-2 border-top'>
            <ScrollBar>
              <table className='table  table-hover'>
                <thead>
                  <tr className='fiter-tr'>
                    {/* <th 
                         > */}
                    <Checkbox
                      // sx={{ p: 0 }}
                      color='primary'
                      indeterminate={selected.length > 0 && selected.length < data?.response?.length}
                      checked={data?.response?.length > 0 && selected.length === data?.response?.length}
                      onChange={handleSelectAllClick}
                      inputProps={{
                        'aria-label': 'select all desserts'
                      }}
                    />
                    {/* </th> */}
                    <th>Name</th>
                    <th>Donor-ID</th>
                    <th>Health-ID/Abha-Id</th>
                    <th>AdharCard</th>
                    <th>Donation Type</th>
                    <th>Mobile</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.response?.length &&
                    data?.response?.map(item => {
                      const isItemSelected = isSelected(item?.id)
                      return (
                        <tr key={item.id}>
                          {/* <td  > */}
                          <Checkbox
                            // sx={{ p: 0 }}
                            onClick={event => handleClick(event, item.id)}
                            color='primary'
                            checked={isItemSelected}
                          />
                          {/* </td> */}
                          <td
                            className='cursor-pointer'
                            // onClick={() => {
                            //   history.push(`/donors/${item.id}`);
                            //   handleClickDonor(item);
                            // }}
                          >
                            <div>{item?.name}</div>
                          </td>
                          <td>{item?.reg_id || '-'}</td>
                          <td>{item?.abha_id || '-'}</td>
                          <td>{item?.adharcard || '-'}</td>
                          <td>{item?.donation_type || '-'}</td>
                          <td>{item?.mobile || '-'}</td>
                          <td>
                            <span className='donor_action'>
                              <ToolTips
                                title='Issue Certificate'
                                id='t-1'
                                // placement="top"
                              >
                                <img
                                  style={{ maxWidth: '50px' }}
                                  onClick={() => {
                                    downloadCertificate(item.id)
                                  }}
                                  src={'/assets/images/certificate/certificate.png'}
                                  alt='certificate'
                                />
                              </ToolTips>
                            </span>
                          </td>
                        </tr>
                      )
                    })}
                </tbody>
              </table>
            </ScrollBar>
          </div>

          {data?.response?.length == 0 && (
            <div className='dnr-no-data-found'>
              <NoDataFound title='No Record found' />
            </div>
          )}

          {pageData && pageData.totalPage > 1 && (
            <div className='float-right mt-1 '>
              <PaginationLink pagination={pageData} getNext={page => setPage(page)} />
            </div>
          )}
        </div>
      </ScrollCard>
    </>
  )
}

export default IssueCertification
