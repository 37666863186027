import React from 'react';

function UnderContruction({
  message = `Sorry for the inconvenience but we&prime;re performing some maintenance
at the moment`,
  headingStyle,
  messageStyle,
}) {
  return (
    <div className="centerr">
      <h5 style={{ marginBottom: 2.5, textAlign: 'center', ...headingStyle }}>Under Construction! 🚧</h5>
      <p style={{ textAlign: 'center', ...messageStyle }}>{message}</p>
    </div>
  );
}

export default UnderContruction;
