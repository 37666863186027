import { CardHeader } from '../shared/card-header'

export const MedicalHistory = ({ result }) => {
  return (
    <>
      <CardHeader>Medical History</CardHeader>
      <div>
        {result?.map((item, idx) => {
          return item.id > 5 ? null : (
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                padding: '7px 0 3px 8px'
              }}
              key={idx}
            >
              <div className='general-info' style={{ width: '90%' }}>
                {item.id}. {item.label}
              </div>
              <div className='general-black'>{item.value}</div>
            </div>
          )
        })}
      </div>
    </>
  )
}
