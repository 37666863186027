import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import FormProvider from '../../shared/FormProvider'
import RHFTextField from '../../shared/RHFTextfield'
import { inputStyles, labelStyles } from '../../certification/EditCertificate'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormLabel, Grid, MenuItem } from '@mui/material'
import { LetterMessage } from './AddBloodReqForm'
import RHFSelect from '../../shared/RHFSelect'
import { useEffect } from 'react'
import ModalCustom, { ModalActions } from '../../shared/ModalCustom'
import LoadingButton from '../../shared/LoadingButton'

export const AddHospitalForm = ({
  states,
  districts,
  getDistict,
  showModal,
  handleCloseModal,
  handleSubmitParent,
  isLoading
}) => {
  const schema = yup.object().shape({
    hospital_name: yup.string().required('Hospital name is required'),
    hospital_address: yup.string().required('Hospital address is required').max(150, LetterMessage),
    city: yup.string().required('City is required').max(50, LetterMessage),
    district: yup.string().required('District is required'),
    state: yup.string().required('State is required'),
    contact_number: yup
      .string()
      .optional()
      .matches(
        /^(\d{10}|\d{3,5}-\d{5,8})$/,
        'Enter a valid 10-digit mobile number or landline number (e.g., 012-3456789)'
      )
  })

  const defaultValues = {
    hospital_name: '',
    state: '',
    district: '',
    hospital_address: '',
    city: '',
    contact_number: ''
  }
  const methods = useForm({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver(schema)
  })

  const {
    control,
    handleSubmit,
    watch,
    setError,
    setValue,
    getValues,
    clearErrors,
    reset,
    formState: { errors }
  } = methods

  const onSubmit = async data => {
    handleSubmitParent(data)
    console.log(`api data 2 -> `, data)
    return data // This will return the form data to the parent
  }

  useEffect(() => {
    if (watch('state')) {
      getDistict(watch('state'))
    }
  }, [watch('state'), states])

  return (
    <ModalCustom
      size={'md'}
      title={`Add Hospital`}
      open={showModal}
      closeDialog={handleCloseModal}
      actionComponent={
        <ModalActions>
          <div className='d-flex justify-content-end align-items-end p-3 '>
            <button type='button' className='btn backBtn mr-2 ' onClick={handleCloseModal}>
              Cancel
            </button>
            <LoadingButton type={'button'} onClick={handleSubmit(onSubmit)} isLoading={isLoading} title='Submit' />
          </div>
        </ModalActions>
      }
    >
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <FormLabel sx={labelStyles}>
              Hospital Name<span className='text-danger'>*</span>
            </FormLabel>
            <RHFTextField sx={inputStyles} fullWidth size='small' placeholder='Hospital Name' name='hospital_name' />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <FormLabel sx={labelStyles}>
              Hospital Address<span className='text-danger'>*</span>
            </FormLabel>
            <RHFTextField size='small' name='hospital_address' fullWidth sx={inputStyles} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <FormLabel sx={labelStyles}>
              State<span className='text-danger'>*</span>
            </FormLabel>
            {/* <RHFTextField
                      size="small"
                      name="state"
                      fullWidth
                      sx={inputStyles}
                    />  */}
            <RHFSelect size='small' name='state' fullWidth sx={inputStyles}>
              {states?.length > 0 ? (
                states?.map((stat, i) => (
                  <MenuItem key={i} value={stat?.name}>
                    {stat?.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value=''>No States found</MenuItem>
              )}
            </RHFSelect>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <FormLabel sx={labelStyles}>
              District<span className='text-danger'>*</span>
            </FormLabel>
            <RHFSelect size='small' name='district' fullWidth sx={inputStyles}>
              {districts?.length > 0 ? (
                districts?.map((stat, i) => (
                  <MenuItem key={i} value={stat?.name}>
                    {stat?.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value=''>No District found</MenuItem>
              )}
            </RHFSelect>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <FormLabel sx={labelStyles}>
              City/Village<span className='text-danger'>*</span>
            </FormLabel>
            <RHFTextField size='small' type='text' fullWidth name='city' sx={inputStyles} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <FormLabel sx={labelStyles}>
              Hospital Contact Number<span className='text-danger'>*</span>
            </FormLabel>
            <RHFTextField size='small' type='text' fullWidth name='contact_number' sx={inputStyles} />
          </Grid>
        </Grid>
      </FormProvider>
    </ModalCustom>
  )
}
