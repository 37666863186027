export const bloodComponent = [
  'Whole Blood (CPD)',
  'Whole Blood (CPDA-1)',
  'Packed Red Blood Cells',
  'Fresh Frozen Plasma',
  'Single Donor Platelet',
  'Cryoprecipitate',
  'Plasma',
  'Platelet Concentrate',
  'Cryo Poor Plasma',
  'Random Donor Platelets',
  'Platelets additive solutions',
];

export const bloodType = ['O+', 'O-', 'A+', 'A-', 'B+', 'AB+', 'AB-'];
