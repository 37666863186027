export const StatusCapsule = ({ children, status }) => {
  const getStatusStyles = () => {
    switch (status) {
      case 'done':
        return { color: '#1a7f37', backgroundColor: '#e6f4ea' } // green text, lighter green background
      case 'pending':
        return { color: '#9a6700', backgroundColor: '#fff7e0' } // dark yellow/brown text, lighter yellow background
      case 'cancel':
        return { color: '#a61d24', backgroundColor: '#fdecea' } // dark red text, lighter red background
      default:
        return {}
    }
  }

  return (
    <div style={{ padding: '4px 8px', borderRadius: '8px', fontWeight: 'bold', ...getStatusStyles() }}>{children}</div>
  )
}
