import * as React from 'react'
import { Controller, Control, Path, FieldValues, useFormContext } from 'react-hook-form'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import { FormHelperText } from '@mui/material'
import { inputStyles } from '../certification/EditCertificate'

export const RHFAutoComplete = props => {
  const { options, name, size, handleChange, ...other } = props
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: true
      }}
      render={({ field, fieldState: { error } }) => {
        const { onChange, value, ref } = field

        return (
          <>
            <Autocomplete
              size={size || 'small'}
              value={
                value
                  ? options.find(option => {
                      return value === option.id
                    }) ?? null
                  : null
              }
              onChange={(event, newValue) => {
                onChange(newValue ? newValue.id : '')
                if (handleChange) {
                  handleChange(newValue)
                }
              }}
              // id='controllable-states-demo'
              options={options}
              renderInput={params => (
                <TextField
                  error={Boolean(error)}
                  {...params}
                  placeholder={props.placeholder}
                  inputRef={ref}
                  sx={inputStyles}
                />
              )}
              {...other}
            />
            {error && <FormHelperText sx={{ color: 'error.main' }}>{error.message}</FormHelperText>}
          </>
        )
      }}
    />
  )
}
