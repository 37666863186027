import React from 'react';

export function FiltersDonor({
  title,
  subtitle,
  icon,
  bgColor,
  imgbg,
  onClick,
  cla,
  iconSize,
  isMulti = false,
  TitleComponent,
  widthCustom = '147px',
}) {
  const cl = 'donor-filter-main' + ` ${cla}`;
  return (
    <div style={{ background: bgColor, width: widthCustom, position: 'relative' }} className={cl} onClick={onClick}>
      <div className="donor-filter-img" style={{ background: imgbg }}>
        <img src={icon} style={iconSize} alt={subtitle} />
      </div>
      <div className="donor-filter-content">
        {isMulti ? TitleComponent : <span className="donor-filter-content-title">{title}</span>}
        <span className="donor-filter-content-sub-title">{subtitle}</span>
      </div>
    </div>
  );
}
