import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

function ToolTips({
  id, children, title, placement,
}) {
  return (
    <OverlayTrigger placement={placement} overlay={<Tooltip id={id}>{title}</Tooltip>}>
      {children}
    </OverlayTrigger>
  );
}

export default ToolTips;
