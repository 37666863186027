import React, { useEffect, useState } from 'react'
import { getTenant } from '../../../middleware/auth'
import moment from 'moment'
const lbl = { fontWeight: '400', fontSize: '10px', color: '#4B5563' }
const val = { marginTop: '8px', fontWeight: '400', fontSize: '10px', color: '#07101c' }
const wraper = { width: '33%', display: 'inline-block', padding: '8px 0', borderBottom: '1px solid #E1E1E1' }
const headings = { padding: '8px 10px', color: '#214F8A', fontSize: '14px', fontWeight: '600', background: '#E9EDF3' }

export const DeclarationDetail = ({ idx, title, label }) => {
  let answer = Array.isArray(title)
  return (
    <div style={{ display: 'flex', gap: '50px', marginTop: '12px', fontSize: '10px' }} key={idx}>
      <div style={{ width: '60%' }}>
        {idx + 1}. {label}
      </div>
      <div style={{ width: '40%' }}>
        {answer === true ? (
          title?.length > 0 ? (
            <>
              {title?.map((item, i) => (
                <span key={i}>
                  {item}
                  {i + 1 < title?.length ? ' , ' : ''}
                </span>
              ))}
            </>
          ) : (
            <span>No</span>
          )
        ) : null}
      </div>
    </div>
  )
}

const ScreeningPrint = ({ printId, data, info }) => {
  const [dieases, setDiseases] = useState([])
  const [result, setResult] = useState(true)
  const [declerations, setDeclarations] = useState([])
  const [screeningDetails, setScreeningDetails] = useState({})
  const [sdpData, setSDPData] = useState({})
  let tenant = getTenant()

  const checkDefferTill = defertill => {
    if (defertill) {
      const startDate = moment() // Current date
      const formattedEndDate = moment(defertill, 'YYYY-MM-DD') // Adjust the date format
      const diffInDays = formattedEndDate.diff(startDate, 'days')
      return diffInDays
    } else {
      return 'N/A'
    }
  }

  useState(() => {
    if (data) {
      if (data?.id) {
        let latestScreening = data
        setScreeningDetails({ ...latestScreening })
        if (latestScreening?.checkup_detail) {
          let sdp = JSON.parse(latestScreening?.checkup_detail)
          setSDPData(sdp)
        }

        if (latestScreening?.dieases !== null) {
          let dis = JSON.parse(latestScreening?.dieases)
          setDiseases(dis)
        }
        if (latestScreening?.declration !== null) {
          let dec = JSON.parse(latestScreening?.declration)
          setDeclarations(dec)
        }
      }
    }
  }, [data])

  useEffect(() => {
    if (screeningDetails) {
      if (screeningDetails?.is_defered && !data?.BloodBagIssueEntity?.bag_number) {
        setResult(false)
      }
    }
  }, [screeningDetails])

  console.log(declerations)
  console.log(dieases)
  console.log(screeningDetails)

  const details = [
    { label: 'Name', value: info?.name || 'N/A' },
    { label: 'Registration Number', value: info?.reg_id || 'N/A' },
    { label: 'Donation Date', value: info?.donation_date ? moment(info?.donation_date).format('DD-MMM-YYYY') : 'N/A' },
    { label: 'Contact No. ', value: <>+91 {info?.mobile}</> || 'N/A' },
    {
      label: 'Gender / Age',
      value: (
        <>
          {info?.gender || '-'} / {info?.age || '-'} Yr
        </>
      )
    },
    { label: 'Father/Husband Name', value: info?.father_name || 'N/A' },
    {
      label: 'Testing Date',
      value: screeningDetails?.screening_date ? moment(screeningDetails?.screening_date).format('DD-MMM-YYYY') : 'N/A'
    },
    { label: 'Donor Type', value: screeningDetails?.donor_type || '-' }
  ]

  return (
    <div id={printId} style={{ width: '100%', fontSize: '10px', color: '#07101C', fontFamily: 'Inter' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          background: 'linear-gradient(180deg, #21538B 0%, #13B9AA 100%)',
          color: '#FFF'
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', padding: '10px' }}>
          <div style={{ fontFamily: 'Inter', fontSize: '12px', fontWeight: '500' }}>
            {tenant?.tenant || 'Blood Bank Name : N/A'}
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            fontSize: '12px',
            fontWeight: '500',
            padding: '10px'
          }}
        >
          <div style={{ fontWeight: '600' }}>Medical Screening Report</div>
          <div style={{ marginTop: '6px' }}>Final Result: {result ? 'Pass' : 'Fail'}</div>
        </div>
      </div>

      <div style={{ minHeight: '85vh' }}>
        <div style={headings}>Donor Info</div>

        <div style={{ padding: '0 12px' }}>
          {details.map((item, idx) => {
            return (
              <div style={wraper} key={idx}>
                <div style={lbl}>{item['label']}</div>
                <div style={val}>{item['value'] || 'NA'}</div>
              </div>
            )
          })}
        </div>

        <div style={{ marginTop: '16px' }}>
          <div style={headings}>Body Vitals</div>
          <div style={{ padding: '16px', paddingTop: '0' }}>
            <div style={wraper}>
              <div style={lbl}>Weight (Kg)</div>
              <div style={val}>{screeningDetails?.weight || 'N/A'}</div>
            </div>
            <div style={wraper}>
              <div style={lbl}>Hemoglobin (g/dl)</div>
              <div style={val}>{screeningDetails?.hemoglobin_level || 'N/A'}</div>
            </div>
            <div style={wraper}>
              <div style={lbl}>Temperature (Fahrenheit)</div>
              <div style={val}>{screeningDetails?.temprature || 'N/A'}</div>
            </div>
            <div style={wraper}>
              <div style={lbl}>Blood Pressure</div>
              <div style={val}>{screeningDetails?.blood_pressure || 'N/A'}</div>
            </div>
            <div style={wraper}>
              <div style={lbl}>Pulse</div>
              <div style={val}>{screeningDetails?.pulse || 'N/A'}</div>
            </div>
            {screeningDetails?.donor_type === 'SDP' ? (
              <>
                <div style={wraper}>
                  <div style={lbl}>WBC (10^3/uL)</div>
                  <div style={val}>{sdpData?.wbc || 'N/A'}</div>
                </div>
                <div style={wraper}>
                  <div style={lbl}>RBC (10^6/uL)</div>
                  <div style={val}>{sdpData?.rbc || 'N/A'}</div>
                </div>
                <div style={wraper}>
                  <div style={lbl}>HCT (%)</div>
                  <div style={val}>{sdpData?.hct || 'N/A'}</div>
                </div>
                <div style={wraper}>
                  <div style={lbl}>PLT (10^3/uL)</div>
                  <div style={val}>{sdpData?.plt || 'N/A'}</div>
                </div>
              </>
            ) : null}
          </div>
        </div>

        <div style={{ marginTop: '16px' }}>
          <div style={headings}>Medical Disease</div>
          <div style={{ padding: '16px', paddingTop: '0' }}>
            <div style={{ padding: '8px 0px', fontSize: '10px' }}>
              {dieases?.length > 0 ? (
                dieases?.map((item, idx) => (
                  <span style={{ fontWeight: '700', marginRight: '16px' }}>
                    {idx + 1}. {item}
                  </span>
                ))
              ) : (
                <span>Nil</span>
              )}
            </div>
          </div>
        </div>

        <div>
          <div style={headings}>Volunteer Declaration</div>

          <div style={{ padding: '0 12px', marginBottom: '12px' }}>
            {declerations?.length > 0
              ? declerations.map((item, idx) => {
                  return (
                    <>
                      {item?.gender === 'Both' && (
                        <div style={{ display: 'flex', gap: '50px', marginTop: '12px', fontSize: '10px' }} key={idx}>
                          <div style={{ width: '60%' }}>
                            {idx + 1}. {item['label']}
                          </div>
                          <div style={{ width: '40%' }}>{item['value'] || 'NA'}</div>
                        </div>
                      )}
                      {item?.gender == info?.gender && (
                        <div style={{ display: 'flex', gap: '50px', marginTop: '12px', fontSize: '10px' }} key={idx}>
                          <div style={{ width: '60%' }}>
                            {idx + 1}. {item['label']}
                          </div>
                          <div style={{ width: '40%' }}>{item['value'] || 'NA'}</div>
                        </div>
                      )}
                      {item.gender == undefined && (
                        <DeclarationDetail title={item['value']} label={item['label']} idx={idx} />
                      )}
                    </>
                  )
                })
              : 'N/A'}
          </div>
        </div>

        <div style={{ marginTop: '16px' }}>
          <div style={headings}>Screening By</div>
          <div style={{ padding: '16px', paddingTop: '8px', display: 'flex' }}>
            <div>
              <div style={lbl}>Doctor Name</div>
              <div style={val}>{screeningDetails?.counseller || 'N/A'}</div>
            </div>
            <div style={{ marginLeft: '36px' }}>
              <div style={lbl}>Remarks</div>
              <div style={val}>{screeningDetails?.reason || '-'}</div>
            </div>
          </div>
        </div>

        <div style={{ borderRadius: '4px', background: '#F8F8F8', paddingBottom: '8px' }}>
          <div style={headings}>Reason</div>
          {/* {!ttiresult ? <>
                        <ul>
                            {failreasion?.length > 0 ? failreasion?.map((ele, idx) => (
                                <li key={idx} style={{ color: "#4B5563" }}>{ele}</li>
                            )) : null}
                        </ul>
                    </> : null
                    } */}
          <div style={{ fontWeight: '600', marginLeft: '16px', marginTop: '8px' }}>
            Result :{' '}
            {result ? (
              'Donor is fit for blood donation'
            ) : screeningDetails?.defer_type === '1' ? (
              <>Temporary Defer for {checkDefferTill(screeningDetails?.defer_till)} Days</>
            ) : (
              <>Permanent Deferred</>
            )}
          </div>
        </div>
      </div>

      <div
        style={{
          marginTop: '8px',
          height: '60px',
          background: '#EBFEF9',
          display: 'flex',
          flexDirection: 'column',
          gap: '14px',
          padding: '8px 16px',
          color: '#374151',
          fontSize: '12px',
          justifyContent: 'center'
        }}
      >
        <div style={{ display: 'flex', gap: '36px' }}>
          <div style={{ display: 'flex', gap: '8px' }}>
            <img src={'../assets/images/invoice/material-symbols_call-outline.svg'} alt='contact' />
            <span>{tenant?.phone_number || 'N/A'}</span>
          </div>
          <div style={{ display: 'flex', gap: '8px' }}>
            <img src={'../assets/images/invoice/fontisto_email.svg'} alt='email' />
            <span>{tenant?.email || 'N/A'}</span>
          </div>
        </div>
        <div
          style={{
            fontSize: '14px',
            gap: '8px'
          }}
        >
          <div style={{ display: 'flex', gap: '8px' }}>
            <img src={'../assets/images/invoice/carbon_location.svg'} alt='location' />
            <span>
              {tenant?.TenantAddressEntity?.street ? tenant?.TenantAddressEntity?.street : ''}{' '}
              {tenant?.TenantAddressEntity?.area ? tenant?.TenantAddressEntity?.area : ''}{' '}
              {tenant?.TenantAddressEntity?.city ? tenant?.TenantAddressEntity?.city : ''}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}
export default ScreeningPrint
