import React, { useRef, useState } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import Loader from '../../shared/Loader'
import { useEffect } from 'react'
import AxiosHelper from '../../../helper/AxiosHelper'
import { useHistory } from 'react-router-dom'
import useOnClickOutside from '../../hook/useClickOnOutside'
import { useLocation } from 'react-router-dom'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { addDonorSide, getInitials, getPinCodeData, getTenatName } from '../../middleware/auth'
import { Trans } from 'react-i18next'
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControl,
  FormHelperText,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  TextField,
  Typography,
  Select,
  createFilterOptions,
  Alert,
  Collapse,
  CircularProgress
} from '@mui/material'
import { useParams } from 'react-router-dom'
import dayjs from 'dayjs'

import { People } from '@mui/icons-material'
import moment from 'moment'
import { ImageBox } from '../../certification'
import { inputStyles } from '../../certification/EditCertificate'
import MUIAutocomplete from '../../shared/MUIAutocomplete'
import FormProvider from '../../shared/FormProvider'
import RHFTextField from '../../shared/RHFTextfield'
import RHFSelect from '../../shared/RHFSelect'
import { RHFDatePicker } from '../../shared/RHFDatePicker'
import ScrollBar from 'react-perfect-scrollbar'
import AddIcon from '@mui/icons-material/Add'
import { checkTenantId } from '../../../helper/CommonAPI'

const NoResult = ({ value }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center'
      }}
    >
      <Typography variant='h6' sx={{ mb: 11.5, wordWrap: 'break-word' }}>
        No results for{' '}
        <Typography variant='h6' component='span' sx={{ wordWrap: 'break-word' }}>
          {`"${value}"`}
        </Typography>
      </Typography>
    </Box>
  )
}

export const filterOptions = createFilterOptions({
  matchFrom: 'start',
  stringify: option => option.name
})

export const radioStyles = {
  '&.MuiCheckbox-indeterminate,&.Mui-checked': {
    color: '#214F8A'
  }
}

const relations = [
  'Father',
  'Mother',
  'Son',
  'Daughter',
  'Husband',
  'Wife',
  'Brother',
  'Sister',
  'Grandfather',
  'Grandmother',
  'Grandson',
  'Uncle',
  'Aunt',
  'Nephew',
  'Niece',
  'Cousins',
  'Father-in-law',
  'Mother-in-law',
  'Brother-in-law',
  'Sister-in-law',
  'Friend',
  'Other'
]

const defaultValues = {
  donation_type: 'Voluntary',
  martial_status: 'Prefer not to Say',
  relation: 'Father',
  gender: 'Male',
  state: '',
  district: '',
  name: '',
  father_name: '',
  dob: '',
  mobile: '',
  city: '',
  area: '',
  reg_date: '',
  camp_id: '',
  consent_sms: true,
  consent_call: true,
  adharcard: '',
  type: 'bank'
}

let LetterMessage = 'Must be less than 50 characters'

export const phoneRegExp = /^[0-9]{10}$/
export const regex = '^(1[8-9]|[2-5][0-9]|6[0-5])$'
// export const regex = /^(1[8-9])|([2-5][0-9])|(6[0-5])$/
export const emailregex = '^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$'

const letters = /^[a-zA-Z ]*$/
const schema = yup.object().shape({
  type: yup.string(),
  camp_id: yup.string().when('type', {
    is: 'camp',
    then: () => yup.string().required('Camp is required')
  }),
  father_name: yup.string().required('Father name is required').max(50, LetterMessage).matches(letters, 'Invalid name'),
  email: yup.string().optional().email(),
  age: yup.string().required('Age is required').matches(regex, 'Age Must be between 18 - 65 years'),
  name: yup.string().required('Name is required').matches(letters, 'Invalid name').max(50, LetterMessage),
  // spouse_name: yup.string().matches(letters, 'Invalid spouse name').max(50, LetterMessage),
  area: yup.string().max(50, LetterMessage).nullable(),
  state: yup.string().required('state is required'),
  district: yup.string().required('district is required'),
  city: yup.string().required('city is required'),
  reg_date: yup.string().required('Donation Date is required'),
  mobile: yup.string().required('Mobile number is required').matches(phoneRegExp, 'mobile number is not valid')
})

const DonorDetail = ({ setDetailPage, detailPageData, setDetailPageData, self, type, handleChoice }) => {
  const [isCampDonor, setIsCampDonor] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [message, setMessage] = useState(null)
  const [typeBank, setTypeBank] = useState('bank')

  const [data, setData] = useState([])
  const [focus, setFocus] = React.useState(false)
  const [registeredData, setRegisteredData] = React.useState([])
  const [hospitalinfo, sertHospitalInfo] = useState(false)
  const [campInfo, setCampInfo] = useState({})

  const [searchValue, setSearchValue] = useState('')
  const [options, setOptions] = useState([])

  const mobileref = useRef()

  const tenant = JSON.parse(localStorage.getItem('authInfo')) || {}
  const { donType, id, slug } = useParams()

  const history = useHistory()
  const location = useLocation()
  const [states, setStates] = useState([])
  const [districts, setDistricts] = useState([])
  const todaydate = new Date()
  const queryParams = new URLSearchParams(location.search)
  const tanentidd = queryParams.get('tanentid')
  const methods = useForm({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver(schema)
  })

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    setFocus: setErrorFocus,
    setError: setForrmError,
    reset,
    formState: { errors, isValid }
  } = methods

  const getStates = () => {
    AxiosHelper.get('address/state')
      .then(response => {
        setStates(response?.data?.data)
        let arr = response?.data?.data
        return arr
      })
      .catch(error => {
        console.log(error)
      })
  }

  const getDistict = val => {
    AxiosHelper.get('address/district/' + val)
      .then(response => {
        setDistricts(response?.data?.data)
      })
      .catch(error => {
        console.log(error)
      })
  }

  useEffect(() => {
    getStates()
    getBloodCampRequests()
  }, [])

  useEffect(() => {
    if (watch('donation_type') === 'Voluntary') {
      sertHospitalInfo(false)
    } else {
      sertHospitalInfo(true)
    }
  }, [watch('donation_type')])

  useEffect(() => {
    if (watch('relation') === 'Husband') {
      setValue('martial_status', 'Married')
      setValue('gender', 'Female')
      setValue('spouse_name', watch('spouse_name'))
    } else {
      setValue('martial_status', 'Prefer not to Say')
    }
  }, [watch('relation')])

  useEffect(() => {
    if (watch('relation') === 'Husband' && watch('gender') === 'Female') {
      setValue('martial_status', 'Married')
      setValue('gender', 'Female')
      setValue('spouse_name', watch('spouse_name'))
    } else {
      setValue('relation', 'Father')
      setValue('martial_status', 'Prefer not to Say')
    }
  }, [watch('gender')])

  useEffect(() => {
    if (typeBank === 'bank') {
      setValue('reg_date', dayjs(todaydate))
    }
  }, [typeBank])

  const handleSubmitForm = data => {
    setLoading(true)
    delete data['abha_id']

    if (data?.martial_status === 'Prefer not to Say') {
      delete data['martial_status']
    }

    let url = ''
    if (type === 'Quick Registeration') {
      if (self) {
        url = `register/quick?tenant_id=${tanentidd}`
      } else {
        url = 'donor/quick'
      }
    } else {
      url = self === true ? 'register/basic-info' : 'donor/basic-info'
    }
    let bodyData = { ...data, patient_relation: data?.relation }
    bodyData.tenant_id = tenant?.tenant_id
    if (data?.adharcard) {
      bodyData.adharcard = `${data?.adharcard}`
    }
    if (id && donType !== 'camp') {
      bodyData.tenant_id = id
    }

    AxiosHelper.post(url, bodyData)
      .then(response => {
        setLoading(false)
        if (!self) {
          setIsCampDonor(true)
        } else {
          setSuccess(true)
        }
      })
      .catch(error => {
        handlerError(error)
      })
    // }
  }

  const handleGetNumber = value => {
    if (value.length < 10) {
      setRegisteredData([])
      return
    }
    let url = 'register/check-mobile'
    let data = {
      mobile: value
    }
    AxiosHelper.post(url, data)
      .then(response => {
        setRegisteredData(response?.data?.data)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const handlerError = error => {
    setLoading(false)
    setMessage(error)
    setError(true)
    // toast.error(error);
  }

  useEffect(() => {
    if (detailPageData) {
      let data = detailPageData
      data.reg_date = watch('reg_date')
      data.camp_id = watch('camp_id')
      data.state = campInfo?.state ? campInfo?.state : ""
      data.district = campInfo?.district ? campInfo?.district : ""
      data.city = campInfo?.city ? campInfo?.city : ""
      data.pincode = campInfo?.pincode ? campInfo?.pincode : ""
      data.dob = detailPageData?.dob ? dayjs(detailPageData?.dob) : ''
      data.martial_status = data?.martial_status ? data?.martial_status : 'Prefer not to Say'
      data.email = data?.email ? data?.email : ''
      reset({
        ...defaultValues,
        ...data
      })
    }
  }, [detailPageData])

  function closeAll() {
    setSuccess(false)
    setDetailPage(false)
    handleChoice()
    if (!self) {
      history.push('/donors')
    }
  }

  useOnClickOutside(mobileref, () => {
    setFocus(false)
  })

  const handleSearch = async value => {
    if (!value) {
      setOptions([])
      return false
    }
    if (value.length !== 12) {
      setOptions([])
      return
    }
    let url = 'register/check-adhar'
    let data = {
      aadhaar: value
    }

    AxiosHelper.post(url, data)
      .then(response => {
        if (response?.data?.data !== null) {
          let array = []
          array.push(response?.data?.data)
          setOptions(array)
        } else {
          setOptions([])
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  const handleOptionClick = (item = {}) => {
    setOptions([])
    if (typeof item !== 'object') return
    if (typeBank === 'camp') {
      let getStateId = states?.find(ele => {
        return ele.name.toUpperCase() == item?.state.toUpperCase()
      })

      let checkValues = {
        ...item,
        donation_type: watch('donation_type'),
        type: watch('type'),
        relation: watch('relation'),
        dob: dayjs(item?.dob),
        camp_id: watch('camp_id'),
        reg_date: watch('reg_date'),
        state: getStateId?.name,
        consent_sms: watch('consent_sms'),
        consent_call: watch('consent_call')
      }
      reset(checkValues)
    } else {
      history.push(`/donors/${item.id}`)
      addDonorSide(item)
    }
  }

  function getNames(name) {
    if (!name) return
    return name?.replaceAll('-', ' ')
  }

  const getBloodCampRequests = () => {
    let url = 'camp/without-pagination'

    if (self) {
      url = `register/without-pagination?tenant_id=${tanentidd}`
    }
    url = checkTenantId(url)
    AxiosHelper.get(url)
      .then(response => {
        setData(response.data.data)
      })
      .catch(error => {
        console.log(error)
      })
  }

  useEffect(() => {
    if (watch('state')) {
      getDistict(watch('state'))
    }
  }, [watch('state')])

  useEffect(() => {
    if (watch('dob')) {
      let age = dayjs().diff(dayjs(watch('dob')), 'years')
      setValue('age', age)
    }
  }, [watch('dob')])

  useEffect(() => {
    if (states.length && detailPageData) {
      let getStateId = states?.find(ele => {
        return ele.name.toUpperCase() == detailPageData?.state?.toUpperCase()
      })
      setValue('state', getStateId?.name)
    }
  }, [states])

  useEffect(() => {
    if (donType === 'camp') {
      setTypeBank('camp')
      setValue('camp_id', id)
      setValue('type', 'camp')
    }
  }, [donType])

  const handleSpecial = e => {
    if (e.target.checked) {
      setValue(e.target.name, true)
    } else {
      setValue(e.target.name, false)
    }
  }

  function getCampDataa(id) {
    let timeD = data?.find(item => item.id == id)
    setCampInfo(timeD)
    autoSelectAddress(timeD)

    setValue('reg_date', dayjs(timeD?.camp_schedule_date), {
      shouldValidate: true,
      shouldDirty: true
    })
  }

  const handleTypeChoice = type => {
    setTypeBank(type)
    setValue('type', type)
  }

  const autoSelectAddress = (add) => {
    setValue("state", add?.state ? add?.state : "")
    setValue("district", add?.district ? add?.district : "")
    setValue("city", add?.city ? add?.city : "")
    setValue("pincode", add?.pincode ? add?.pincode : "")
  }

  async function getAddressVal(val) {
    if (val?.length === 6) {
      setLoading(true)
      let address = await getPinCodeData(val)
      if (address) {
        setValue('state', address?.State || '', {
          shouldValidate: true,
          shouldDirty: true
        })
        setValue('district', address?.District || '', {
          shouldValidate: true,
          shouldDirty: true
        })
        setValue('city', address?.Block || '', {
          shouldValidate: true,
          shouldDirty: true
        })
      }
      setLoading(false)
    }
  }

  useEffect(() => {
    getAddressVal(watch('pincode'))
  }, [watch('pincode')])

  useEffect(() => {
    if (watch('camp_id') && data.length > 0) {
      getCampDataa(watch('camp_id'))
    }
  }, [watch('camp_id'), data])

  React.useEffect(() => {
    const firstError = Object.keys(errors).reduce((field, a) => {
      return !!errors[field] ? field : a
    }, null)

    if (firstError) {
      setErrorFocus(firstError)
    }
  }, [errors, setErrorFocus])

  const AddMoreDonorHandler = () => {
    let data = detailPageData
    data.reg_date = watch('reg_date')
    data.camp_id = watch('camp_id')    
    data.state = campInfo?.state ? campInfo?.state : ""
    data.district = campInfo?.district ? campInfo?.district : ""
    data.city = campInfo?.city ? campInfo?.city : ""
    data.pincode = campInfo?.pincode ? campInfo?.pincode : ""
    data.dob = detailPageData?.dob ? dayjs(detailPageData?.dob) : ''
    data.martial_status = data?.martial_status ? data?.martial_status : 'Prefer not to Say'
    data.email = data?.email ? data?.email : ''
    reset({
      ...defaultValues,
      ...data
    })
    setIsCampDonor(false)
  }
  const GoToDonorListHandler = () => {
    setIsCampDonor(false)
    history.push(`/donors`)
  }
  if (success) {
    return (
      <Modal show={success} onHide={closeAll} className='donor-details-container'>
        <Modal.Body>
          <div className='modal-input-item-container registeration-slef'>
            <h1>You have registered Successfully.</h1>
            <p>Please contact to Medical Screening Doctor for Further Process.</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={closeAll}>
            Done
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
  if (isCampDonor) {
    return (
      <Modal show={isCampDonor} className='donor-details-container'>
        <Modal.Body>
          <div className='modal-input-item-container registeration-slef'>
            <div className='my-4'>
              <img alt='' src={'/assets/images/registration/Frame.png'} />
            </div>
            <h1 className=' text-center'>You have registered Successfully.</h1>
            <p className=' text-center'>Please contact to Medical Screening Doctor for Further Process.</p>
            <div className='d-flex justify-content-center align-items-center my-4'>
              <button onClick={GoToDonorListHandler} className='btn btn-custom-text mx-2'>
                Go All Donor Page
              </button>
              <button
                type='button'
                className='btn gradient-btn mx-2'
                style={{ fontSize: '0.8rem' }}
                onClick={AddMoreDonorHandler}
              >
                <AddIcon />
                Add More Donor
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    )
  }
  return (
    <ScrollBar>
      <div className='pb-5'>
        <Loader isLoading={isLoading} />
        <FormProvider
          methods={methods}
          onSubmit={e => {
            e.preventDefault()
          }}
        >
          <Row className='p-2 quick-donor-regestration-container'>
            {!self ? (
              <Col xs={12}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    p: 1,
                    borderRadius: 2,
                    gap: 2,
                    background: '#F2F9F7'
                  }}
                >
                  <ImageBox
                    style={{
                      maxHeight: '120px',
                      maxWidth: '160px',
                      backgroundColor: 'white'
                    }}
                    type={'bank'}
                    active={typeBank === 'bank'}
                    title='Blood Bank'
                    handleChoice={handleTypeChoice}
                    img={'/assets/images/certificate/bank-normal.png'}
                    imgColor={'/assets/images/certificate/bank-active.png'}
                  />
                  <ImageBox
                    active={typeBank === 'camp'}
                    style={{
                      maxHeight: '120px',
                      maxWidth: '160px',
                      backgroundColor: 'white'
                    }}
                    type={'camp'}
                    title='Blood Camp'
                    handleChoice={handleTypeChoice}
                    img={'/assets/images/certificate/tent-normal.png'}
                    imgColor={'/assets/images/certificate/tent-active.png'}
                  />
                </Box>
              </Col>
            ) : null}
            <Col lg={3} md={4}>
              <Form.Group className='mb-3'>
                <Form.Label style={{ textTransform: 'capitalize' }}>
                  {donType ? getNames(donType) : 'Blood Bank/Camp'} Name
                </Form.Label>
                <div className={donType === 'camp' ? 'hindi-label' : 'd-none'}>शिविर का नाम</div>
                {typeBank === 'camp' ? (
                  <>
                    <RHFTextField
                      sx={inputStyles}
                      fullWidth
                      select
                      name='camp_id'
                      size='small'
                      disabled={self}
                      InputLabelProps={{ shrink: false }}
                      label={!watch('camp_id') ? 'Select Camp' : null}
                    >
                      {data?.length > 0 ? (
                        data?.map((item, i) => (
                          <MenuItem key={i} value={item?.id}>
                            {item?.camp_name}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem>No Camps Found</MenuItem>
                      )}
                    </RHFTextField>
                  </>
                ) : (
                  <TextField sx={inputStyles} fullWidth value={donType ? getNames(slug) : getTenatName()} disabled />
                )}
              </Form.Group>
            </Col>

            {/* ) : null} */}
            <Col lg={3} md={4}>
              <Form.Group className='mb-3'>
                <Form.Label>Donation Type</Form.Label>
                <div className={donType === 'camp' ? 'hindi-label' : 'd-none'}>दान का प्रकार</div>

                <RHFSelect
                  sx={inputStyles}
                  fullWidth
                  select
                  disabled={id ? true : false}
                  name='donation_type'
                  inputProps={{ placeholder: 'Select Donation Type' }}
                >
                  <MenuItem value='Voluntary'>Voluntary Donor</MenuItem>
                  <MenuItem value='Replacement'>Replacement Donor</MenuItem>
                  <MenuItem value='Autologous'>Autologous Donor</MenuItem>
                </RHFSelect>
              </Form.Group>
            </Col>
            {typeBank === 'camp' && campInfo?.camp_schedule_date ? (
              <Col xs={12}>
                <div className='mb-2'>
                  Schedule Date :- {moment(campInfo?.camp_schedule_date).format('DD/MM/YYYY')} <br />
                  Venue :- {campInfo?.city}, {campInfo?.district}, {campInfo?.state}
                </div>
              </Col>
            ) : null}
            <Col md={12} className='Donor-details-sections no-top-margin '>
              <div className='detail-header'>
                Personal Details
                <span className={donType === 'camp' ? 'hindi-label' : 'd-none'}>(व्यक्तिगत विवरण)</span>{' '}
              </div>
            </Col>
            <Col lg={3} md={4}>
              <Form.Group className='mb-3'>
                <Form.Label>
                  Donation Date
                  {/* <span className="text-danger">*</span> */}
                </Form.Label>
                <div className={donType === 'camp' ? 'hindi-label' : 'd-none'}>
                  {' '}
                  पंजीकरण तिथि
                  {/* <span className="text-danger">*</span> */}
                </div>

                <FormControl
                  // required
                  fullWidth
                >
                  <RHFDatePicker
                    format='DD-MM-YYYY'
                    sx={inputStyles}
                    name='reg_date'
                    disabled={typeBank === 'camp' ? true : false}
                  />
                </FormControl>
              </Form.Group>
            </Col>

            {type === 'Quick Registeration' ? (
              <Col lg={3} md={4}>
                <Form.Group className='mb-3'>
                  <Form.Label>
                    Enter Aadhaar
                    {/* <span className="text-danger">*</span> */}
                  </Form.Label>
                  <div className={donType === 'camp' ? 'hindi-label' : 'd-none'}>आधार नंबर दर्ज करें</div>
                  <Autocomplete
                    freeSolo
                    autoHighlight
                    size='small'
                    open={options?.length > 0}
                    disablePortal
                    disableClearable={false}
                    options={options}
                    id='appBar-search'
                    isOptionEqualToValue={() => true}
                    onChange={(event, obj) => handleOptionClick(obj)}
                    onInputChange={(event, value, reason) => {
                      if (reason === 'reset') return
                      setSearchValue(value)
                      handleSearch(value)
                    }}
                    noOptionsText={searchValue.length ? <NoResult value={searchValue} /> : null}
                    getOptionLabel={option => option.name || ''}
                    filterOptions={(options, state) => options}
                    sx={{
                      boxShadow: 'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px',
                      '& + .MuiAutocomplete-popper': {
                        ...(searchValue.length && {
                          overflow: 'auto',
                          maxHeight: 'calc(100vh - 69px)',
                          minHeight: 20,
                          background: '#E5E4E2',
                          boxShadow:
                            'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px'
                        })
                      }
                    }}
                    renderInput={params => {
                      return (
                        <TextField
                          {...params}
                          placeholder='Enter Aadhaar number'
                          value={searchValue}
                          type='number'
                          size='small'
                          name='adharcard'
                          onChange={event => {
                            setSearchValue(event.target.value)
                            setValue('adharcard', event.target.value)
                          }}
                          sx={inputStyles}
                        // required
                        // error={Boolean(formErrors?.adharcard)}
                        />
                      )
                    }}
                    renderOption={(props, option) => {
                      return options.length ? (
                        <ListItemButton onClick={() => handleOptionClick(option)}>
                          <ListItemIcon>
                            <People />
                          </ListItemIcon>
                          <ListItemText primary={option?.name || ''} />
                        </ListItemButton>
                      ) : null
                    }}
                  />
                </Form.Group>
              </Col>
            ) : null}
            <Col lg={3} md={4}>
              <Form.Group className='mb-3'>
                <Form.Label>
                  Enter Your Name <span className='text-danger'>*</span>
                </Form.Label>
                <div className={donType === 'camp' ? 'hindi-label' : 'd-none'}>
                  {' '}
                  अपना नाम दर्ज करें <span className='text-danger'>*</span>{' '}
                </div>
                <RHFTextField
                  sx={inputStyles}
                  fullWidth
                  size='small'
                  type='text'
                  placeholder='Donor Name'
                  name='name'
                />
              </Form.Group>
            </Col>

            <Col lg={3} md={4}>
              <Form.Group className='mb-3'>
                <Form.Label>
                  Father/Husband's Name <span className='text-danger'>*</span>
                </Form.Label>
                <div className={donType === 'camp' ? 'hindi-label' : 'd-none'}>
                  {' '}
                  पिता/पति का नाम <span className='text-danger'>*</span>{' '}
                </div>
                <RHFTextField
                  sx={inputStyles}
                  size='small'
                  fullWidth
                  type='text'
                  placeholder='Father/Husband Name'
                  name='father_name'
                />
              </Form.Group>
            </Col>

            <Col lg={3} md={4}>
              <Form.Group className='mb-3'>
                <Form.Label>
                  Relation <span className='text-danger'>*</span>
                </Form.Label>
                <div className={donType === 'camp' ? 'hindi-label' : 'd-none'}>
                  {' '}
                  संबंध <span className='text-danger'>*</span>{' '}
                </div>
                <ul className='donate-now'>
                  <li>
                    <input
                      type='radio'
                      id='Father'
                      value='Father'
                      name='relation'
                      onChange={() => {
                        setValue('relation', 'Father')
                      }}
                      checked={watch('relation') === 'Father'}
                    // required
                    />
                    <label htmlFor='Father'>Father</label>
                  </li>
                  <li>
                    <input
                      type='radio'
                      id='Husband'
                      value='Husband'
                      name='relation'
                      checked={watch('relation') === 'Husband'}
                      onChange={() => {
                        setValue('relation', 'Husband')
                      }}
                    />
                    <label htmlFor='Husband'>Husband</label>
                  </li>
                </ul>
              </Form.Group>
            </Col>

            <Col lg={3} md={4}>
              <Form.Group ref={mobileref} style={{ position: 'relative' }} className='mb-3'>
                <Form.Label sx={{ marginBottom: '3px' }}>
                  Mobile <span className='text-danger'>*</span>
                </Form.Label>
                <div className={donType === 'camp' ? 'hindi-label' : 'd-none'}>
                  {' '}
                  मोबाइल <span className='text-danger'>*</span>{' '}
                </div>

                <RHFTextField
                  sx={inputStyles}
                  fullWidth
                  type='number'
                  size='small'
                  onFocus={() => setFocus(true)}
                  onKeyDown={e => {
                    if (e.key === 'Tab') {
                      setFocus(false)
                    }
                  }}
                  placeholder='Donor Mobile'
                  name='mobile'
                  onChange={e => {
                    handleGetNumber(e.target.value)
                  }}
                  value={watch('mobile')}
                  InputProps={{
                    startAdornment: (
                      <Box sx={{ borderRight: '2px solid #d1d5db', mr: 1 }}>
                        <Select size='small' variant='standard' disableUnderline value='+91'>
                          <MenuItem value='+91'>+91</MenuItem>
                        </Select>
                      </Box>
                    )
                  }}
                />

                {focus ? (
                  <div style={{ position: 'absolute' }} className='searchCustomm '>
                    {registeredData?.length > 0 ? (
                      registeredData?.map((item, i) => (
                        <div
                          onClick={() => {
                            setFocus(false)
                            setDetailPageData(item)
                          }}
                          key={i}
                          className='nav-item cursor-pointer my-2 '
                        >
                          <div className='nav-profile-textt'>
                            <h1>{getInitials(item?.name)}</h1>
                          </div>
                          <div className='nav-profile-text'>
                            <span className='font-weight-bold mb-2'>
                              <Trans>{item?.name}</Trans>
                            </span>
                          </div>
                        </div>
                      ))
                    ) : (
                      <p>
                        {watch('mobile')?.length < 10 || watch('mobile') == undefined
                          ? 'Enter 10 digit mobile number to find users'
                          : watch('mobile')?.length > 10
                            ? 'Invalid mobile number'
                            : 'No Registered Users found with this number'}
                      </p>
                    )}
                  </div>
                ) : null}
              </Form.Group>
            </Col>

            <Col lg={3} md={4}>
              <Form.Group className='mb-3'>
                <Form.Label>Enter Email </Form.Label>
                <div className={donType === 'camp' ? 'hindi-label' : 'd-none'}> अपना ईमेल दर्ज करें </div>

                <RHFTextField
                  sx={inputStyles}
                  fullWidth
                  size='small'
                  type='email'
                  placeholder='Donor Email ID'
                  name='email'
                />
              </Form.Group>
            </Col>

            <Col lg={3} md={4}>
              <Form.Group className='mb-3'>
                <Form.Label>
                  Gender <span className='text-danger'>*</span>
                </Form.Label>
                <div className={donType === 'camp' ? 'hindi-label' : 'd-none'}>
                  {' '}
                  लिंग <span className='text-danger'>*</span>{' '}
                </div>

                <ul className='donate-now flex'>
                  <li>
                    <input
                      type='radio'
                      id='a25'
                      value='Male'
                      name='gender'
                      checked={watch('gender') === 'Male'}
                      onChange={() => {
                        setValue('gender', 'Male')
                      }}
                    />
                    <label htmlFor='a25'>Male</label>
                  </li>
                  <li>
                    <input
                      type='radio'
                      id='a50'
                      value='Female'
                      name='gender'
                      checked={watch('gender') === 'Female'}
                      onChange={() => {
                        setValue('gender', 'Female')
                      }}
                    />
                    <label htmlFor='a50'>Female</label>
                  </li>
                  <li>
                    <input
                      type='radio'
                      id='a75'
                      value='Other'
                      name='gender'
                      checked={watch('gender') === 'Other'}
                      onChange={() => {
                        setValue('gender', 'Other')
                      }}
                    />
                    <label htmlFor='a75'>Other</label>
                  </li>
                </ul>
                {watch('gender') === 'Other' ? (
                  <FormHelperText sx={{ color: 'error.main' }}>Donor Ineligible for Donation</FormHelperText>
                ) : null}
              </Form.Group>
            </Col>

            <Col lg={3} md={4}>
              <Form.Group className='mb-3'>
                <Form.Label>
                  Date of Birth
                  {/* <span className="text-danger">*</span> */}
                </Form.Label>
                <div className={donType === 'camp' ? 'hindi-label' : 'd-none'}>
                  {' '}
                  जन्म की तारीख
                  {/* <span className="text-danger">*</span> */}
                </div>

                <FormControl fullWidth>
                  <RHFDatePicker
                    name='dob'
                    sx={inputStyles}
                    format='DD/MM/YYYY'
                    disableFuture
                    minDate={dayjs().subtract(65, 'years')}
                    maxDate={dayjs().subtract(18, 'years')}
                  />
                </FormControl>
              </Form.Group>
            </Col>
            <Col lg={3} md={4}>
              <Form.Group className='mb-3'>
                <Form.Label>
                  Age <span className='text-danger'>*</span>
                </Form.Label>
                <div className={donType === 'camp' ? 'hindi-label' : 'd-none'}>
                  {' '}
                  आयु <span className='text-danger'>*</span>
                </div>

                <FormControl
                  // required
                  fullWidth
                >
                  <RHFTextField sx={inputStyles} fullWidth size='small' type='number' placeholder='Age' name='age' />
                </FormControl>
              </Form.Group>
            </Col>

            <Col lg={3} md={4}>
              <Form.Group className='mb-3'>
                <Form.Label>Martial Status </Form.Label>
                <div className={donType === 'camp' ? 'hindi-label' : 'd-none'}> वैवाहिक स्थिति </div>

                <MUIAutocomplete
                  fullWidth
                  options={[{ name: 'Married' }, { name: 'Single' }, { name: 'Prefer not to Say' }]}
                  placeholder='Select martial status'
                  value={watch('martial_status')}
                  filterOptions={filterOptions}
                  onChange={val => {
                    setValue('martial_status', val)
                  }}
                />
              </Form.Group>
            </Col>

            {watch('martial_status') === 'Married' ? (
              <Col lg={3} md={4}>
                <Form.Group className='mb-3'>
                  <Form.Label>Enter Spouse Name</Form.Label>
                  <div className={donType === 'camp' ? 'hindi-label' : 'd-none'}> जीवनसाथी का नाम दर्ज करें </div>

                  <RHFTextField
                    sx={inputStyles}
                    size='small'
                    fullWidth
                    type='text'
                    placeholder='Donor Spouse Name'
                    name='spouse_name'
                  />
                </Form.Group>
              </Col>
            ) : null}

            <Col md={12} className='Donor-details-sections'>
              <div className='detail-header'>
                Contact Information
                <span className={donType === 'camp' ? 'hindi-label' : 'd-none'}>(संपर्क विवरण)</span>
              </div>
            </Col>

            <Col lg={3} md={4}>
              <Form.Group className='mb-3'>
                <Form.Label>Address</Form.Label>
                <div className={donType === 'camp' ? 'hindi-label' : 'd-none'}> पता पता</div>

                <RHFTextField
                  sx={inputStyles}
                  fullWidth
                  size='small'
                  type='text'
                  placeholder='Enter Address Line 1'
                  name='area'
                />
              </Form.Group>
            </Col>

            <Col lg={3} md={4}>
              <Form.Group className='mb-3'>
                <Form.Label>
                  State <span className='text-danger'>*</span>
                </Form.Label>
                <div className={donType === 'camp' ? 'hindi-label' : 'd-none'}>
                  {' '}
                  राज्य <span className='text-danger'>*</span>{' '}
                </div>

                <MUIAutocomplete
                  fullWidth
                  options={states}
                  placeholder='Select State'
                  value={watch('state')}
                  filterOptions={filterOptions}
                  onChange={val => {
                    setValue('state', val, {
                      shouldValidate: true,
                      shouldDirty: true
                    })
                    setValue('district', '')
                  }}
                  error={errors?.state?.message}
                />
              </Form.Group>
            </Col>

            <Col lg={3} md={4}>
              <Form.Group className='mb-3'>
                <Form.Label>
                  District <span className='text-danger'>*</span>
                </Form.Label>
                <div className={donType === 'camp' ? 'hindi-label' : 'd-none'}>
                  {' '}
                  ज़िला <span className='text-danger'>*</span>{' '}
                </div>

                <MUIAutocomplete
                  // sx={inputStyles}
                  fullWidth
                  options={districts}
                  placeholder='Select District'
                  value={watch('district')}
                  filterOptions={filterOptions}
                  onChange={val => {
                    setValue('district', val, {
                      shouldValidate: true,
                      shouldDirty: true
                    })
                  }}
                  error={errors?.district?.message}
                />
              </Form.Group>
            </Col>
            <Col lg={3} md={4}>
              <Form.Group className='mb-3'>
                <Form.Label>
                  City/Village<span className='text-danger'>*</span>
                </Form.Label>
                <div className={donType === 'camp' ? 'hindi-label' : 'd-none'}>
                  {' '}
                  शहर/गांव <span className='text-danger'>*</span>
                </div>

                <RHFTextField
                  sx={inputStyles}
                  fullWidth
                  type='text'
                  size='small'
                  placeholder='Enter City'
                  name='city'
                />
              </Form.Group>
            </Col>
            <Col lg={3} md={4}>
              <Form.Group className='mb-3'>
                <Form.Label>Pin Code </Form.Label>
                <div className={donType === 'camp' ? 'hindi-label' : 'd-none'}> पिन कोड</div>

                <RHFTextField
                  sx={inputStyles}
                  fullWidth
                  type='number'
                  size='small'
                  placeholder='Enter Pin Code'
                  name='pincode'
                />
              </Form.Group>
            </Col>

            {hospitalinfo ? (
              <>
                <Col md={12} className='Donor-details-sections'>
                  <div className='detail-header'>
                    Other Details
                    <span className={donType === 'camp' ? 'hindi-label' : 'd-none'}> (अन्य विवरण)</span>
                  </div>
                </Col>
                <Col lg={3} md={4}>
                  <Form.Group className='mb-3'>
                    <Form.Label>Patient Name </Form.Label>
                    <div className={donType === 'camp' ? 'hindi-label' : 'd-none'}> रोगी का नाम </div>

                    <RHFTextField
                      sx={inputStyles}
                      fullWidth
                      size='small'
                      type='text'
                      placeholder='Enter Patient Name'
                      name='patient_name'
                    />
                  </Form.Group>
                </Col>

                <Col lg={3} md={4}>
                  <Form.Group className='mb-3'>
                    <Form.Label>Relation with Patient </Form.Label>
                    <div className={donType === 'camp' ? 'hindi-label' : 'd-none'}> रोगी के साथ संबंध </div>

                    <RHFSelect
                      sx={inputStyles}
                      fullWidth
                      size='small'
                      type='text'
                      placeholder='Enter Relation with Patient'
                      name='patient_relation'
                    >
                      {relations?.map((rel, i) => (
                        <MenuItem key={i} value={rel}>
                          {rel}
                        </MenuItem>
                      ))}
                    </RHFSelect>
                  </Form.Group>
                </Col>

                <Col lg={3} md={4}>
                  <Form.Group className='mb-3'>
                    <Form.Label>Hospital Name</Form.Label>
                    <div className={donType === 'camp' ? 'hindi-label' : 'd-none'}> अस्पताल का नाम </div>

                    <RHFTextField
                      sx={inputStyles}
                      fullWidth
                      type='text'
                      placeholder='Enter Hospital Name'
                      name='hospital'
                    />
                  </Form.Group>
                </Col>
              </>
            ) : null}
            {/* {donType !== "camp" ? ( */}
            <div className='row'>
              <Col md={12} className='Donor-details-sections'>
                <div className='detail-header'>Donor consent </div>
              </Col>
              <Col lg={3} md={4} style={{ pl: '0px' }}>
                <div className='col-md-3'>
                  <div className='d-flex '>
                    <Checkbox
                      name='consent_sms'
                      sx={radioStyles}
                      checked={watch('consent_sms')}
                      onChange={handleSpecial}
                    />
                    <Form.Label>
                      <div className='mt-2 label-txt'>Consent to send sms</div>
                    </Form.Label>
                  </div>
                </div>
              </Col>
              <Col lg={3} md={4}>
                <div className='col md-3'>
                  <div className='d-flex mb-2 '>
                    <Checkbox
                      sx={radioStyles}
                      name='consent_call'
                      checked={watch('consent_call')}
                      onChange={handleSpecial}
                    />
                    <Form.Label>
                      <div className='mt-2 label-txt'>Consent to call </div>
                    </Form.Label>
                  </div>
                </div>
              </Col>
            </div>

            <Col md={12}>
              <Form.Group className='mb-3'>
                <Collapse in={error}>
                  <Alert severity='error' onClose={() => setError(false)} dismissible>
                    {message}{' '}
                  </Alert>
                </Collapse>
              </Form.Group>
            </Col>
            <Col md={12}>
              <div className='d-flex ' style={{ gap: '10px' }}>
                <button
                  type='button'
                  onClick={() => {
                    setDetailPage(false)
                  }}
                  className='btn backBtn'
                >
                  Cancel
                </button>

                <button
                  type='button'
                  disabled={isLoading}
                  onClick={handleSubmit(handleSubmitForm)}
                  className='btn gradient-btn'
                >
                  {isLoading ? <CircularProgress size={30} color='inherit' /> : 'Register'}
                </button>
              </div>
            </Col>
          </Row>
        </FormProvider>
      </div>
    </ScrollBar>
  )
}

export default DonorDetail
