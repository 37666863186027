import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import App from './app/App'

import reportWebVitals from './reportWebVitals'
import AppContextProvider from './AppContext'
import { SettingsProvider } from './hooks/useSetting'
import ThemeComponent from './layout/shared/ThemeComponent'

const root = ReactDOM.createRoot(document.getElementById('root'));

const AppContainer = () => (
  <BrowserRouter>
    <ThemeComponent>
      <AppContextProvider>
        <SettingsProvider>
          <App />
        </SettingsProvider>
      </AppContextProvider>
    </ThemeComponent>
  </BrowserRouter>
);

root.render(
  process.env.REACT_APP_Mode === 'development' ? (
    <React.StrictMode>
      <AppContainer />
    </React.StrictMode>
  ) : (
    <AppContainer />
  )
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
