import { Close } from '@mui/icons-material';
import { Box, styled, Typography } from '@mui/material';
import Drawer from '@mui/material/Drawer';

import React from 'react';

export const Header = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(3, 4),
  justifyContent: 'space-between',
}));

function SideDrawer({
  open, handleClose, title, children, side, customWidth, closeAble,
}) {
  return (
    <Drawer
      open={open}
      anchor={side || 'right'}
      variant="temporary"
      onClose={handleClose}
      ModalProps={{ keepMounted: true }}
      sx={{
        '& .MuiDrawer-paper': { width: customWidth || { xs: 300, sm: 700 } },
      }}
    >
      {closeAble ? (
        <Header>
          <Typography variant="h6" sx={{ color: 'white' }}>
            {title}
          </Typography>
          <Close fontSize="small" onClick={handleClose} sx={{ cursor: 'pointer' }} />
        </Header>
      ) : null}
      {children}
    </Drawer>
  );
}

export default SideDrawer;
