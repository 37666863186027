import React from 'react';
import { Spinner } from 'react-bootstrap';

function Loader({ isLoading }) {
  return (
    <>
      {isLoading && (
        <Spinner
          animation="border"
          size="lg"
          role="status"
          style={{
            position: 'fixed',
            right: '50%',
            left: '50%',
            top: '50%',
            bottom: '50%',
            color: '#13BCAB',
            width: '5rem',
            height: '5rem',
            zIndex: '999',
            display: 'block',
          }}
        />
      )}
    </>
  );
}
export default Loader;
