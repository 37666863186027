import React from 'react';
import { useHistory } from 'react-router-dom';

function PageHeader({ title, hideBack }) {
  const history = useHistory();

  return null;

  // return (
  //   <div className="page-header">
  //     <h3 className="page-title">{title}</h3>
  //     {hideBack ? (
  //       ""
  //     ) : (
  //       <button
  //         onClick={() => history.goBack()}
  //         type="button"
  //         className="btn backBtn"
  //       >
  //         <i className="mdi mdi-arrow-left"></i>
  //         Back
  //       </button>
  //     )}
  //   </div>
  // );
}

export default PageHeader;
