import moment from 'moment'
import PrintComponentsView from '../../printComponents/PrintComponentsView'
import { InfoItem } from '../shared/info-item'
import ModalCustom from '../../../../shared/ModalCustom'
import { CardHeader } from '../shared/card-header'
import LoadingButton from '../../../../shared/LoadingButton'
import { useEffect, useState } from 'react'
import AxiosHelper from '../../../../../helper/AxiosHelper'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'
import { downLoadFiles } from '../../../../middleware/auth'

export const BloodBagInfo = ({
  trailData,
  setPrintOptions,
  setOpenModal,
  openModal,
  basicData,
  printOptions,
  setCurrentData,
  info,
  setInfo,
  getDonorHistory,
  data
}) => {
  const [btnLoading, setBtnLoading] = useState(false)
  const [btnLoadingType, setBtnLoadingType] = useState(false)
  const [loading, setLoading] = useState(false)
  const { id } = useParams()
  const history = useHistory()

  const handleCloseModal = () => {
    setPrintOptions('')
    setOpenModal(false)
  }
  function findSmsStatusObj(array, type) {
    let objj = array?.find(item => {
      return item.type === type
    })

    if (objj) return objj

    return null
  }

  const sendSMSAgain = (bag_id, type) => {
    setBtnLoading(bag_id)
    setBtnLoadingType(type)
    AxiosHelper.get(`sms-log/send-sms-via-bag/${bag_id}/${type}`)
      .then(response => {
        getDonorHistory()
        setBtnLoading(null)
        setBtnLoadingType(null)
        toast.success('Report Sent Successfully')
      })
      .catch(error => {
        console.log(error)
        setBtnLoading(null)
        setBtnLoadingType(null)
        toast.error(error || 'Something went wrong on our side')
      })
  }

  console.log(`bag data info --> `, data?.is_bulk)

  return (
    <>
      <CardHeader lastUpdated={data?.updatedAt}>Blood Bag</CardHeader>
      <div className='bag-wrap'>
        {/* Donor Date & Token ID */}
        <InfoItem
          title='Date & Time'
          value={
            data?.createdAt
              ? moment(data?.createdAt).format('DD MMM YYYY hh:mm A')
              : data?.screening_date
                ? moment(data?.screening_date).format('DD MMM YYYY hh:mm A')
                : '-'
          }
        />
        {/* <InfoItem title='Token ID' value={data?.id || ''} /> */}

        {/* Medical Screening */}
        <InfoItem is_bulk={data.is_bulk} title='Medical Screening'>
          {data?.id ? (
            <div
              className='pending'
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setPrintOptions('print_screening_Report')
                setOpenModal(true)
              }}
            >
              <img src='/assets/images/pdf.svg' alt='pdf' style={{ borderRadius: 'initial', height: '24px' }} />
              Done
            </div>
          ) : (
            <div className='pending'>Pending</div>
          )}
        </InfoItem>

        {/* Blood Bag Issue & Number */}
        <InfoItem is_bulk={data.is_bulk} title='Blood Bag Issue'>
          {data?.bag_id ? (
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setPrintOptions('print_bloodbag_details')
                setOpenModal(true)
              }}
            >
              <img src='/assets/images/pdf.svg' alt='pdf' style={{ borderRadius: 'initial', height: '24px' }} />
              Issued
            </div>
          ) : data?.is_defered ? (
            <span className='abort'>Abort</span>
          ) : (
            'Pending'
          )}
        </InfoItem>
        <InfoItem title='Blood Bag Number' value={data?.bag_number || 'N/A'} />
        <InfoItem title='Blood Bag Type' value={data?.bag_type || 'N/A'} />

        {/* Segment Number */}
        <InfoItem title='Segment Number' value={trailData?.BloodBagIssueEntity?.tube_number || 'N/A'} />

        {/* Tti Testing */}
        <InfoItem title='Tti Testing'>
          {data?.BloodTestingEntity?.id || data.is_bulk ? (
            <div
              className='pending'
              style={{ cursor: 'pointer' }}
              onClick={() => {
                if (!data.is_bulk) {
                  setPrintOptions('print_TTI_Report')
                  setOpenModal(true)
                }
              }}
            >
              <img src='/assets/images/pdf.svg' alt='pdf' style={{ borderRadius: 'initial', height: '24px' }} />
              Done
            </div>
          ) : data?.is_defered ? (
            <div className='abort'>Abort</div>
          ) : (
            <div
              style={{
                cursor: 'pointer'
              }}
              onClick={() => history.push(`/donors-detail/testing/${data?.abha_id}`)}
              className='pending'
            >
              Pending
            </div>
          )}
        </InfoItem>

        {/* Inventory */}
        <InfoItem title='Inventory'>
          {data?.is_defered ? (
            <div className='abort'>Abort</div>
          ) : data?.BloodLogsEntities?.length > 0 ? (
            <div
              className='pending'
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setPrintOptions('inventory_detail')
                setOpenModal(true)
              }}
            >
              <img src='/assets/images/pdf.svg' alt='pdf' style={{ borderRadius: 'initial', height: '24px' }} />
              Done
            </div>
          ) : (
            <div
              style={{
                cursor: 'pointer'
              }}
              onClick={() => {
                if (data?.abha_id) {
                  history.push(`/donors-detail/donor-inventory/${data?.abha_id}`)
                }
              }}
              className='pending'
            >
              Pending
            </div>
          )}
        </InfoItem>

        {/* Donation Site & Certificate Status */}
        <InfoItem
          is_bulk={data.is_bulk}
          title='Donation Site/Place'
          value={data?.DonorCampEntities?.[0]?.CampEntity?.camp_name || data?.TenantsEntity?.name || 'N/A'}
        />
        <InfoItem is_bulk={data.is_bulk} title='Donation Status'>
          {data?.is_defered ? (
            <div
              className='abort'
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setPrintOptions('full_detail')
                setOpenModal(true)
              }}
            >
              <img src='/assets/images/pdf.svg' alt='pdf' style={{ borderRadius: 'initial', height: '24px' }} />
              Abort
            </div>
          ) : (
            <div
              className='pending'
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setPrintOptions('full_detail')
                setOpenModal(true)
              }}
            >
              <img src='/assets/images/pdf.svg' alt='pdf' style={{ borderRadius: 'initial', height: '24px' }} />
              Pending
            </div>
          )}
        </InfoItem>

        {/* Blood Group */}
        <InfoItem title='Forward/Reverse Grouping' value={data?.BloodTestingEntity?.blood_group || 'N/A'} />

        <InfoItem is_bulk={data.is_bulk} title={'Donation Certificate'}>
          {data?.is_defered ? (
            <span className='abort'>Abort</span>
          ) : data && data?.bag_id ? (
            <div
              className='pending'
              style={{ cursor: 'pointer' }}
              onClick={() => {
                downLoadFiles(`/download/donor-certificate/${data?.id}`, `Donation_Certificate`)
              }}
            >
              {
                <img
                  src={'/assets/images/pdf.svg'}
                  alt='pdf'
                  style={{
                    borderRadius: 'initial',
                    height: '24px'
                  }}
                />
              }
              Get Certificate
            </div>
          ) : (
            <div className='pending' style={{ cursor: 'pointer' }}>
              {
                <img
                  src={'/assets/images/pdf.svg'}
                  alt='pdf'
                  style={{
                    borderRadius: 'initial',
                    height: '24px'
                  }}
                />
              }
              Pending
            </div>
          )}
        </InfoItem>

        <InfoItem is_bulk={data.is_bulk} title={'Certificate SMS Status'}>
          {data?.is_defered ? (
            <span className='abort'>Abort</span>
          ) : data && data?.bag_id ? (
            <div className='pending'>
              <LoadingButton
                title={
                  findSmsStatusObj(data?.certificateDeliverLogEntities, 'Certificate')?.is_delivered ? 'Resend' : 'Send'
                }
                disabled={!data}
                style={{ height: '2rem' }}
                isLoading={btnLoading == data?.id && btnLoadingType == 'Certificate'}
                onClick={() => {
                  sendSMSAgain(data?.id, 'Certificate')
                }}
              />
            </div>
          ) : (
            'Not Sent'
          )}
        </InfoItem>

        <InfoItem is_bulk={data.is_bulk} title={'TTI SMS Status'}>
          {data?.is_defered ? (
            <div className='abort'>Abort</div>
          ) : data?.BloodTestingEntity && data?.BloodTestingEntity?.id ? (
            <div className='pending'>
              <LoadingButton
                disabled={!data}
                title={findSmsStatusObj(data?.certificateDeliverLogEntities, 'TTI')?.is_delivered ? 'Resend' : 'Send'}
                style={{ height: '2rem' }}
                isLoading={btnLoading == data?.id && btnLoadingType == 'TTI'}
                onClick={() => {
                  sendSMSAgain(data?.id, 'TTI')
                }}
              />
            </div>
          ) : (
            <div className='pending'>
              {/* {<img src={'/assets/images/pdf.svg'}
                              alt="pdf"
                              onClick={() => printAll("print_TTI_Report", "i_frame")}
                              style={{ borderRadius: "initial", height: "24px" }}
                            />} */}
              Pending
            </div>
          )}
        </InfoItem>

        {/* Modal */}
        <ModalCustom open={openModal} fullScreen closeDialog={handleCloseModal}>
          <PrintComponentsView
            printId={printOptions}
            data={{
              ...trailData,
              is_bulk: data.is_bulk
            }}
            info={basicData}
            handlecloseModle={handleCloseModal}
          />
        </ModalCustom>
      </div>
    </>
  )
}
