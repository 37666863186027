import React, { useEffect, useState } from 'react'
import { Box, InputAdornment, TextField } from '@mui/material'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import FormProvider from '../../../app/shared/FormProvider'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { inputStyles } from '../../../app/certification/EditCertificate'
import RHFTextField from '../../../app/shared/RHFTextfield'
import { SearchOutlined } from '@mui/icons-material'

export const GlobalSearch = () => {
  // const [searchValue, setSearchValue] = useState('')
  const history = useHistory()

  const schema = yup.object().shape({
    searchValue: yup.string()
  })

  const defaultValues = {
    searchValue: ''
  }

  const methods = useForm({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver(schema)
  })

  const { handleSubmit, reset, watch, setValue } = methods

  const handleSearch = () => {
    if (watch('searchValue').trim()) {
      history.push(`/search?query=${encodeURIComponent(watch('searchValue').trim())}`)
    }
  }

  const onSubmit = data => {
    // e.preventDefault() // Prevents page reload
    console.log(`data -> `, data)
    handleSearch() // Call your search function
  }

  const defaultStyle = {
    // border: '1px solid transparent',
    height: '2.5rem',
    width: '2.5rem',
    padding: '0.5rem',
    borderRadius: '25%',
  }

  const activeStyle = {
    color: '#214F8A',
    cursor: 'pointer',
    // border: '1px dotted #214F8A',
    ...defaultStyle
  }

  const disabledStyle = {
    color: 'grey',
    cursor: 'not-allowed',
    ...defaultStyle
  }

  const InputEndProps = {
    endAdornment: (
      <InputAdornment
        position='end'
        sx={{
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <SearchOutlined
          onClick={handleSubmit(onSubmit)}
          sx={watch('searchValue').trim() ? activeStyle : disabledStyle}
        />
      </InputAdornment>
    )
  }

  useEffect(() => {
    if (watch('searchValue').length === 0) {
      console.log(`resetting`)
    } else {
      console.log(`watch -> `, watch('searchValue'))
    }
  }, [watch('searchValue')])

  return (
    <Box sx={{ ml: 2 }}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <TextField
          fullWidth
          placeholder='Search'
          name='searchValue'
          sx={inputStyles}
          InputProps={InputEndProps}
          value={watch('searchValue')}
          onChange={e => setValue('searchValue', e.target.value)}
        />
      </FormProvider>
    </Box>
  )
}
