import React from 'react';

function TextButton({ onClick, children, ...other }) {
  return (
    <p className="edit-btn" onClick={onClick} {...other}>
      {children}
    </p>
  );
}

export default TextButton;
