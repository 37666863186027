import useMediaQuery from '@mui/material/useMediaQuery';

import Navbar from '../app/shared/Navbar';
import { useSettings } from '../hooks/useSetting';
import Layout from '.';
import AppBarContent from './shared/AppBarContent';
import navigation from './paths';

const afterNavContent = () => (
  <section style={{ background: 'linear-gradient(90deg,#214f89,#12bcab)' }} className="footer-section  ">
    <p className="footer-note">© EHR Logic. All rights reserved</p>
  </section>
);

function UserLayout({ children, contentHeightFixed }) {
  // ** Hooks
  const { settings, saveSettings } = useSettings();

  const hidden = useMediaQuery((theme) => theme.breakpoints.down('lg'));
  if (hidden && settings.layout === 'horizontal') {
    settings.layout = 'vertical';
  }

  // const { user } = useAuth()

  return (
    <Layout
      hidden={hidden}
      settings={settings}
      saveSettings={saveSettings}
      contentHeightFixed={contentHeightFixed}
      verticalLayoutProps={{
        navMenu: {
          navItems: navigation(),
          // Uncomment the below line when using server-side menu in vertical layout and comment the above line
          // navItems: verticalMenuItems
        },

        appBar: {
          content: (props) => (
            <AppBarContent
              hidden={hidden}
              settings={settings}
              saveSettings={saveSettings}
              toggleNavVisibility={props.toggleNavVisibility}
            />
          ),
        },
      }}
    >
      {children}
    </Layout>
  );
}

export default UserLayout;
