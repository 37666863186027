import { Close } from '@mui/icons-material';
import {
  Box, Button, Dialog, DialogActions, DialogContent, Fade, IconButton, Typography,
} from '@mui/material';
import React, { forwardRef } from 'react';

const Transition = forwardRef((props, ref) => <Fade ref={ref} {...props} />);

export function ModalActions({ children }) {
  return <div style={{ width: '100%' }}>{children}</div>;
}

function ModalCustom(props) {
  // ** props
  const {
    closeDialog, children, open, title, size, scroll, id, fullScreen, actionComponent, dialogStyle, ...other
  } = props;

  return (
    <Dialog
      fullWidth
      fullScreen={fullScreen || false}
      open={open}
      maxWidth={size || 'sm'}
      scroll={scroll || 'body'}
      onClose={closeDialog}
      onBackdropClick={closeDialog}
      TransitionComponent={Transition}
      id={id || 'custom_modal'}
      {...other}
    >
      <Box className="modal__titlediv">
        <Typography variant="h6" color="black" sx={{ display: 'flex', alignItems: 'center', textTransform: 'none' }}>
          {title}
        </Typography>
        <IconButton size="small" onClick={closeDialog}>
          <Close sx={{ color: 'black' }} />
        </IconButton>
      </Box>

      <DialogContent
        sx={{
          px: { xs: 3, sm: 5.5 },
          pt: { xs: 3, sm: 4 },
          pb: actionComponent ? 8 : 3,
          position: 'relative',
          ...dialogStyle,
        }}
      >
        {children}
      </DialogContent>
      {actionComponent}
    </Dialog>
  );
}

export default ModalCustom;
