import {
  AccountBalance,
  AccountBalanceWallet,
  AccountBox,
  AccountBoxOutlined,
  Article,
  Casino,
  CheckBoxOutlineBlankOutlined,
  CreditCard,
  DonutLarge,
  DownhillSkiingSharp,
  FireTruck,
  FolderCopy,
  FormatListBulleted,
  Leaderboard,
  Money,
  ShoppingBag,
} from '@mui/icons-material';

const navigation = () => [
  // {
  //   icon: Home,
  //   title: 'Dashboards',
  //   children: [
  //     {
  //       icon: ChartDonut,
  //       title: 'CRM',
  //       path: '/dashboards/crm',
  //       action: 'read',
  //       subject: 'crm'
  //     },
  //     {
  //       icon: CheckboxMarkedCircleOutline,
  //       title: 'Satelite View',
  //       path: '/broadcast',
  //       action: 'read',
  //       subject: 'Production'
  //     }
  //   ]
  // },
  {
    icon: DonutLarge,
    title: 'Dashboard',
    path: '/dashboards/crm',
    action: 'read',
    subject: 'crm',
  },
  {
    icon: CheckBoxOutlineBlankOutlined,
    title: 'Satelite View',
    path: '/broadcast',
    action: 'read',
    subject: 'Production',
  },
  {
    icon: Leaderboard,
    title: 'Leads',
    path: '/leads',
    action: 'read',
    subject: 'Project',
    isNew: true,
  },
  {
    icon: FolderCopy,
    title: 'Projects',
    path: '/projects',
    action: 'read',
    subject: 'Project',
  },
  {
    icon: FolderCopy,
    title: 'Clients/Supplier',
    children: [
      {
        title: 'Client List',
        icon: FolderCopy,
        path: '/clients',
        action: 'read',
        subject: 'client-page',
      },
      {
        title: 'Supplier List',
        icon: FireTruck,
        path: '/supplier',
        action: 'read',
        subject: 'Supplier',
      },
    ],
  },
  {
    icon: AccountBox,
    title: 'Admin',
    children: [
      {
        title: 'Product List',
        icon: ShoppingBag,
        path: '/product',
        action: 'read',
        subject: 'Product',
      },
      {
        title: 'User List',
        icon: FormatListBulleted,
        path: '/apps/user/list',
        action: 'read',
        subject: 'Users',
      },
      {
        title: 'Roles',
        icon: AccountBalance,
        path: '/apps/roles',
        action: 'read',
        subject: 'Roles',
      },
    ],
  },
  {
    icon: Casino,
    title: 'Finance Report',
    children: [
      {
        title: 'Bills',
        icon: CreditCard,
        path: '/finance/bills',
        action: 'read',
        subject: 'Export-Bill',
      },
      {
        title: 'Invoice',
        icon: Money,
        path: '/finance/invoice',
        action: 'read',
        subject: 'Export-Invoice',
      },
    ],
  },
  {
    title: 'Onboard Requests',
    icon: AccountBoxOutlined,
    path: '/admin/onboarded-users',
    action: 'read',
    subject: 'OnBoard-User',
  },
  {
    title: 'Subscription Plan',
    icon: DownhillSkiingSharp,
    path: '/admin/subscription-plan',
    action: 'read',
    subject: 'Subscription',
  },
  {
    title: 'Members',
    icon: AccountBalanceWallet,
    path: '/admin/members',
    action: 'read',
    subject: 'Admin-Users',
  },
  {
    title: 'Finance',
    icon: AccountBalanceWallet,
    path: '/admin/finance',
    action: 'read',
    subject: 'Payment-History',
  },
  {
    title: 'Reports',
    icon: Article,
    path: '/reports',
    action: 'read',
    subject: 'Project',
  },
];

export default navigation;
