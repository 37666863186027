import { Dialog, DialogContent, Fade } from '@mui/material';
import React, { forwardRef, useState } from 'react';

const Transition = forwardRef((props, ref) => <Fade ref={ref} {...props} />);

function BillingModal(props) {
  // ** props
  const {
    closeDialog, children, open, confirmDialog, title, size, scroll, id, fullScreen,
  } = props;
  const [name, setName] = useState('');
  const [contact, setContact] = useState('');
  const handleclick = () => {
    if (contact.length === 10 && name.length > 1) {
      const newdata = { name, mobile: contact };
      confirmDialog(newdata);
    }
  };
  return (
    <Dialog
      fullWidth
      fullScreen={fullScreen || false}
      open={open}
      maxWidth={size || 'sm'}
      scroll={scroll || 'body'}
      onClose={closeDialog}
      onBackdropClick={closeDialog}
      TransitionComponent={Transition}
      id={id || 'custom_modal'}
    >
      <div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
          autoComplete="off"
        >
          <DialogContent
            sx={{
              position: 'relative',
            }}
          >
            {' '}
            <div>
              <h3>Discount Authorize Personnel </h3>
            </div>
            <div>
              {' '}
              <label htmlFor="input" className="font-weight-normal mt-2">
                Name
                <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control form-control-sm"
                id="input"
                style={{ borderRadius: '8px' }}
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                placeholder=" "
                autoComplete="off"
              />
            </div>
            <div>
              {' '}
              <label htmlFor="input" className="font-weight-normal mt-2">
                Contact
                <span className="text-danger">*</span>
              </label>
              <input
                type="number"
                className="form-control form-control-sm"
                id="input"
                style={{ borderRadius: '8px' }}
                value={contact}
                onChange={(e) => {
                  setContact(e.target.value);
                }}
                placeholder=" "
                autoComplete="off"
              />
            </div>
            <div style={{ marginTop: '16px', display: 'flex' }}>
              <div style={{ display: 'flex', gap: '16px', marginLeft: 'auto' }}>
                <button type="submit" className="btn gradient-btn" onClick={handleclick}>
                  Confirm
                </button>
                <button
                  className="btn backBtn"
                  onClick={() => {
                    closeDialog();
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </DialogContent>
        </form>
        {' '}
      </div>
    </Dialog>
  );
}

export default BillingModal;
