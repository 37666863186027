import React from 'react'
import TtiPrint from './TtiPrint'
import ScreeningPrint from './ScreeninigPrint'
import BloodBagPrint from './BloodBagPrint'
import { printAll } from '../../../../helper/CommonAPI'
import InventoryPrint from './InventoryPrint'
import FinalPrint from './FinalPrint'
import PrintWrapper from '../../../print-components/PrintWrapper'
import ReportCompDetail from '../../../TTI/ReportCompDetail'
import ReportComp from '../../../TTI/ReportComp'
import TtiReport from '../../../TTI/TtiReport'

const printComponentsView = ({ data, info, handlecloseModle, printId }) => (
  <PrintWrapper handleCancel={() => handlecloseModle(false)}>
    {printId === 'print_TTI_Report' ? 
    <TtiReport  data={data} info={info} />
    // <TtiPrint printId={printId} data={data} info={info} />
    
    : null}

    {printId === 'print_screening_Report' ? <ScreeningPrint printId={printId} data={data} info={info} /> : null}

    {printId === 'print_bloodbag_details' ? <BloodBagPrint printId={printId} data={data} info={info} /> : null}

    {printId === 'inventory_detail' ? <InventoryPrint printId={printId} data={data} info={info} /> : null}

    {printId === 'full_detail' ? <FinalPrint printId={printId} data={data} info={info} /> : null}
  </PrintWrapper>
)

export default printComponentsView
