import React, { useState } from 'react'
import { Box, FormLabel, Grid, IconButton, Menu, MenuItem, Select, Typography } from '@mui/material'
import { inputStyles, labelStyles } from '../certification/EditCertificate'
import dayjs from 'dayjs'
import { ChevronLeft, ChevronRight, FileDownload } from '@mui/icons-material'
import { DatePicker } from '@mui/x-date-pickers'
import { downLoadFiles } from '../middleware/auth'

const DailyReport = ({ baseUrl, pdf, excel, type, name }) => {
    const [reportAnchor, setReportAnchor] = useState(null)
    const initialFil = {
        duration: 'Daily',
        customDate: '',
        date: dayjs().format('YYYY-MM-DD'),
        month: dayjs().month()
    }
    const [searchParams, setSearchParams] = useState(initialFil)

    const handleApplyFilter = (values, downloadFormat) => {
        let filterurl = ''
        if (values?.duration === 'Daily') {
            let dt = dayjs(values?.date).format("YYYY-MM-DD")
            filterurl = filterurl + `&date=${dt}`
        }
        if (values?.duration === 'custom') {
            let dt = dayjs(values?.customDate).format("YYYY-MM-DD")
            filterurl = filterurl + `&date=${dt}`
        }
        downLoadFiles(`/${baseUrl}?${downloadFormat}=true${filterurl}`, type)
    }

    const openReportMenu = event => {
        setReportAnchor(event.currentTarget)
    }

    const closeBillMenu = () => {
        setReportAnchor(null)
    }

    function handleAllChanges(e) {
        if (e.target.value === 'Daily') {
            setSearchParams({
                ...searchParams,
                [e.target.name]: e.target.value,
                customDate: '',
                endDate: ''
            })
        } else {
            setSearchParams({
                ...searchParams,
                [e.target.name]: e.target.value
            })
        }
    }

    return (
        <>
            <button
                type='button'
                className='btn gradient-btn'
                // onClick={handlebillingReport}
                onClick={openReportMenu}
            >
                <FileDownload fontSize='small' /> {name ? name : "Report"}
            </button>
            <Menu
                open={Boolean(reportAnchor)}
                anchorEl={reportAnchor}
                onClose={closeBillMenu}
                keepMounted
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}

            //TransitionComponent={Slide}
            >
                <div className='fltr-billing-container'>
                    <Grid sx={{ maxWidth: '480px', padding: '16px' }} container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <FormLabel sx={labelStyles}>Duration</FormLabel>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <Select
                                sx={{ height: '42px', ...inputStyles, maxWidth: "260px" }}
                                fullWidth
                                select
                                placeholder='duration'
                                name='duration'
                                value={searchParams?.duration}
                                onChange={handleAllChanges}
                                disabled={type === "Daily_stock_report" ? true : false}
                            >
                                <MenuItem value='Daily'>Daily</MenuItem>
                                {/* <MenuItem value="Half_year">Last 6 Months</MenuItem> */}
                                <MenuItem value='custom'>Custom</MenuItem>
                            </Select>
                            {searchParams?.duration === 'Daily' ? (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        background: '#fff',
                                        borderRadius: 2
                                    }}
                                >
                                    <IconButton
                                        onClick={() => {
                                            const newDate = dayjs(searchParams?.date).subtract(1, 'day');
                                            setSearchParams({
                                                ...searchParams,
                                                date: dayjs(newDate).format('YYYY-MM-DD')
                                            });
                                        }}
                                    >
                                        <ChevronLeft />
                                    </IconButton>
                                    <Typography>
                                        <DatePicker
                                            views={['year', 'month', 'day']}
                                            disableFuture
                                            value={dayjs(searchParams?.date)}
                                            format='DD-MMMM-YYYY'
                                            onChange={value => {
                                                setSearchParams({
                                                    ...searchParams,
                                                    date: dayjs(value).format('YYYY-MM-DD')
                                                });
                                            }}
                                            sx={{
                                                ...inputStyles,
                                                background: 'transparent !important',
                                                border: 0,
                                                '& fieldset': { border: '0 !important' },
                                                '& .MuiOutlinedInput-root': {
                                                    p: '0 !important'
                                                }
                                            }}
                                            slotProps={{
                                                textField: {
                                                    size: 'small',
                                                    fullWidth: true
                                                }
                                            }}
                                        />
                                    </Typography>
                                    <IconButton
                                        onClick={() => {
                                            const newDate = dayjs(searchParams?.date).add(1, 'day');
                                            if (newDate.isBefore(dayjs())) {
                                                setSearchParams({
                                                    ...searchParams,
                                                    date: dayjs(newDate).format('YYYY-MM-DD')
                                                });
                                            }
                                        }}
                                    >
                                        <ChevronRight />
                                    </IconButton>
                                </Box>
                            ) : null}
                            {searchParams?.duration === 'custom' ? (

                                <DatePicker
                                    sx={{ mt: 2, maxWidth: '100', ...inputStyles }}
                                    fullWidth
                                    type='date'
                                    format='YYYY-MM-DD'
                                    name='customDate'
                                    disableFuture
                                    value={searchParams?.customDate || null}
                                    onChange={value =>
                                        setSearchParams({
                                            ...searchParams,
                                            customDate: dayjs(value).format('YYYY-MM-DD')
                                        })
                                    }
                                />

                            ) : null}
                        </Grid>

                        <Grid item xs={12} display='flex' justifyContent='flex-end' gap={2}>
                            <button
                                className='btn backBtn '
                                onClick={() => {
                                    setSearchParams(initialFil)
                                }}
                            >
                                Reset
                            </button>

                            {excel ? (
                                <button className='btn gradient-btn' onClick={() => handleApplyFilter(searchParams, 'downloadExcel')}>
                                    Download Excel
                                </button>
                            ) : null}

                            {pdf ? (
                                <button className='btn gradient-btn' onClick={() => handleApplyFilter(searchParams, 'downloadPdf')}>
                                    Download pdf
                                </button>
                            ) : null}
                        </Grid>
                    </Grid>
                </div>
            </Menu>
        </>
    )
}

export default DailyReport
