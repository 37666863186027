import React from 'react';

function NoDataFound({ title, mh = '10vh' }) {
  return (
    <div className="emty_cell" style={{ minHeight: mh }}>
      <img src="/assets/images/donor_inventory/addTableData.svg" className='no_data_img' alt="search-icon" />

      <h6 className="w-100 div_label_text text-center mt-2">{title || 'No Data Found'}</h6>
    </div>
  );
}

export default NoDataFound;
