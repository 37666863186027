// ** MUI Imports
import { styled, useTheme } from '@mui/material/styles';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import MuiAppBar from '@mui/material/AppBar';
import MuiToolbar from '@mui/material/Toolbar';

const AppBar = styled(MuiAppBar)(({ theme }) => ({
  transition: 'none',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex:999,
  backgroundColor: 'transparent',
  color: theme.palette.text.primary,
  minHeight: theme.mixins.toolbar.minHeight,
}));

function LayoutAppBar(props) {
  // ** Props
  const { settings, appBarProps, appBarContent: userAppBarContent } = props;
  const { appBar } = settings;

  if (appBar === 'hidden') {
    return null;
  }

  let userAppBarStyle = {};
  if (appBarProps && appBarProps.sx) {
    userAppBarStyle = appBarProps.sx;
  }
  const userAppBarProps = { ...appBarProps };
  delete userAppBarProps.sx;

  return (
    <AppBar
      elevation={0}
      color="default"
      // className='layout-navbar'
      // sx={{ ...userAppBarStyle }}
      position={appBar === 'fixed' ? 'sticky' : 'static'}
      {...userAppBarProps}
    >
      {(userAppBarContent && userAppBarContent(props)) || null}
    </AppBar>
  );
}

export default LayoutAppBar;
