// ** MUI Theme Provider
import { deepmerge } from '@mui/utils';

export const breakpoints = () => ({
  values: {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1536,
  },
});

export const UserThemeOptions = () => ({});

const themeOptions = () => {
  const userThemeConfig = { ...UserThemeOptions() };

  const mergedThemeConfig = deepmerge(
    {
      breakpoints: breakpoints(),
    },
    userThemeConfig,
  );

  return deepmerge(mergedThemeConfig);
};

export default themeOptions;
