import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material/styles';
import themeOptions from './ThemeOtions';
import themeConfig from '../../config/themeconfig';

function ThemeComponent(props) {
  // ** Props
  const { settings, children } = props;

  // ** Pass merged ThemeOptions (of core and user) to createTheme function
  let theme = createTheme(themeOptions(settings, 'light'));

  // ** Set responsive font sizes to true
  if (themeConfig.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}

export default ThemeComponent;
