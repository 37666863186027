import moment from 'moment'
import React from 'react'

export const textMargin = {
  fontFamily: 'Inter',
  margin: '0px'
}
const gridItem = {
  height: '48px',
  fontSize: '30px',
  textAlign: 'left'
}
const gridMainItem = {
  display: 'flex',
  flexDirection: 'column',
  gap: 6
}

const gridItemTitle = {
  color: '#000000',
  fontSize: '10px',
  fontWeight: '400',
  lineHeight: '24px',
  ...textMargin
}
const gridItemVal = {
  color: '#000000',
  fontSize: '12px',
  fontWeight: '500',
  lineHeight: '24px',
  ...textMargin
}

const main_div = {
  background: '#fff',
  gap: '3px',
  display: 'grid',
  gridTemplateColumns: '2fr 10fr' /* 3:9 ratio */,
  gap: '10px',
  borderRadius: '8px 8px 0px 0px'
}
const left_title = {
  background: '#DBE7F2',
  width: '100%',
  height: '100%',
  padding: '6px 16px',
  borderRadius: '8px 0px 0px 8px'
}
const gridItemTitle2 = {
  color: '#205B8D',
  fontSize: '14px',
  fontWeight: '700',
  ...textMargin
}
const gridItem2 = {
  flex: 1,
  borderRigth: '1px dotted #205B8D',
  padding: '6px 16px',
  display: 'grid',
  gap: '3px',
  gridTemplateColumns: 'repeat(5, 1fr)'

  //   'auto auto auto auto'
}

function Info({ title, desc }) {
  return (
    <div
      style={{
        // borderTop: '2px solid #14B2A8',
        // background: '#EDF7F7',
        minHeight: '43px',
        padding: '6px',
        display: 'flex',
        flexDirection: 'column',
        gap: 3
        // borderRadius: '0px 0px 8px 8px'
      }}
    >
      <p
        style={{
          ...textMargin,
          color: '#205B8D',
          fontSize: '10px',
          fontWeight: '400',
          whiteSpace: 'nowrap'
        }}
      >
        {title || '-'}
      </p>
      <p
        style={{
          ...textMargin,
          color: '#000000',
          fontSize: '12px',
          fontWeight: '500',
          lineHeight: '14.52px'
        }}
      >
        {desc || '-'}
      </p>
    </div>
  )
}

const styles = {
  container: {
    fontFamily: 'Arial, sans-serif',
    lineHeight: '1.6',
    padding: '8px 16px',

    borderRadius: '5px',
    maxWidth: '100%'
  },
  heading: {
    fontSize: '16px',
    fontWeight: 'bold'
  },
  bodyText: {
    marginBottom: '20px'
  },
  dateText: {
    fontWeight: 'bold'
  },
  signatureSection: {
    marginTop: '40px',
    textAlign: 'right'
  },
  signature: {
    marginTop: '60px',
    fontWeight: 'bold'
  },
  position: {
    marginTop: '5px'
  }
}

const ReportComp = ({ data, ttiresult, failreasion, tenant, info }) => {
  let checkup_detail = null
  if (data?.donor_type === 'SDP') {
    checkup_detail = JSON.parse(data?.checkup_detail)
  }

  return (
    <div
      style={{
        maxWidth: '800px',
        display: 'flex',
        flexDirection: 'column',
        flexShrink: '0',
        background: '#ffffff',
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
        fontFamily: 'Inter',
        fontSize: '10px',
        fontWeight: '400',
        minHeight: '100vh',
        position: 'relative'
      }}
    >
      <style type='text/css' media='print'>
        {
          '\
   @page { size: portrait; }\
'
        }
      </style>
      <img
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          maxHeight: '145px'
        }}
        alt=''
        src='/assets/images/tti/header.svg'
      />
      <div
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'flex-start',
          padding: '10px 16px',
          justifyContent: 'space-between',
          zIndex: 1
        }}
      >
        <img
          style={{
            zIndex: 1,
            maxHeight: '67px'
          }}
          alt=''
          src='/assets/images/tti/mainlogo.svg'
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '3px',
            justifyContent: 'flex-start',
            alignItems: 'flex-end'
          }}
        >
          <img
            style={{
              zIndex: 1,
              maxWidth: '135px',
              objectFit: 'contain',
              maxHeight: '67px'
            }}
            alt=''
            src={tenant?.logo || '/assets/images/tti/sidelogo.svg'}
          />
          <p
            style={{
              fontSize: '12px',
              color: '#214F8A',
              margin: '0px',
              textAlign: 'end'
            }}
          >
            {tenant?.name} <br />
            {/* <span>
              {tenant?.TenantAddressEntity?.area ? tenant?.TenantAddressEntity?.area : ''}{' '}
              {tenant?.TenantAddressEntity?.street ? tenant?.TenantAddressEntity?.street : ''}{' '}
              {tenant?.TenantAddressEntity?.city ? tenant?.TenantAddressEntity?.city : ''}{' '}
              {tenant?.TenantAddressEntity?.state ? tenant?.TenantAddressEntity?.state : ''}{' '}
              {tenant?.TenantAddressEntity?.pincode ? <>,Pincode:{tenant?.TenantAddressEntity?.pincode}</> : ''}
            </span> */}
          </p>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '6px',
              color: '#214F8A'
            }}
          >
            <p
              style={{
                fontSize: '10px',
                margin: '0px'
              }}
            >
              Call: <span style={{ color: '#111827' }}>{tenant?.contact_number || ''}</span>
            </p>
            <p
              style={{
                fontSize: '10px',
                margin: '0px'
              }}
            >
              Email : <span style={{ color: '#111827' }}>{tenant?.email || ''}</span>
            </p>
          </div>
        </div>
      </div>

      <div
        style={{
          background: '#fff',
          margin: '1.3rem',
          display: 'flex',
          flexDirection: 'column',
          zIndex: 1,
          flex: 1
        }}
      >
        <div style={styles.container}>
          <p style={styles.heading}>Dear {info?.name || ''},</p>
          <p style={styles.bodyText}>
            I express my heartfelt gratitude to you for taking the initiative of donating blood on{' '}
            <span style={styles.dateText}>{info?.donation_date ? moment(info?.donation_date).format('MMM DD, YYYY') : 'N/A'}</span>. You have not only given the power of hope to those for
            whom life was slowing down, but also shown the path to many more to come forward for this noble cause of
            humanity.
          </p>
        </div>

        <div
          style={{
            background: '#F6FBFB',
            minHeight: '271px',
            borderRadius: '8px',
            display: 'flex',
            flexDirection: 'column',
            zIndex: 1
          }}
        >
          <div
            style={{
              background: '#EDF7F7',
              height: '32px',
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'column',
              gap: '3px',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '8px 8px 0px 0px'
            }}
          >
            <p
              style={{
                color: '#205B8D',
                fontFamily: 'Inter',
                fontSize: '24px',
                fontWeight: '600',
                lineHeight: '24px',
                ...textMargin
              }}
            >
              Blood screening test {data?.donor_type === 'SDP' ? '(SDP)' : null}
            </p>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              flex: 1,
              padding: '6px 20px',
              borderBottom: '1px solid #DCDCDC'
            }}
          >
            <div style={gridMainItem}>
              <div style={gridItem}>
                <p style={gridItemTitle}>Donor Id.</p>
                <p style={gridItemVal}>{info?.reg_id || 'N/A'}</p>
              </div>
            </div>
            <div style={gridMainItem}>
              <div style={gridItem}>
                <p style={gridItemTitle}>Blood Bag No.</p>
                <p style={gridItemVal}>{data?.BloodBagIssueEntity?.bag_number || 'N/A'}</p>
              </div>
            </div>
            <div style={gridMainItem}>
              <div style={gridItem}>
                <p style={gridItemTitle}>Donation Date</p>
                <p style={gridItemVal}>
                  {info?.donation_date ? moment(info?.donation_date).format('DD-MMM-YYYY') : 'N/A'}
                </p>
              </div>
            </div>
            <div style={gridMainItem}>
              <div style={gridItem}>
                <p style={gridItemTitle}>Blood Group</p>
                <p style={gridItemVal}>{data?.BloodBagIssueEntity?.BloodTestingEntity?.blood_group || 'N/A'}</p>
              </div>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              gap: '10px',
              padding: '16px',
              flex: 1
            }}
          >
            <div style={main_div}>
              <div style={left_title}>
                <p style={gridItemTitle2}>TTI Kit Information</p>
              </div>
              <div style={gridItem2}>
                <Info title='HIV Kit' desc={data?.BloodBagIssueEntity?.BloodTestingEntity?.hivkit || 'N/A'} />
                <Info
                  title='Hepatitis-B Kit'
                  desc={data?.BloodBagIssueEntity?.BloodTestingEntity?.hepatitisbkit || 'N/A'}
                />
                <Info
                  title='Hepatitis-C Kit'
                  desc={data?.BloodBagIssueEntity?.BloodTestingEntity?.hepatitisckit || 'N/A'}
                />
                <Info title='Malaria Kit' desc={data?.BloodBagIssueEntity?.BloodTestingEntity?.malariakit || 'N/A'} />
                <Info
                  title='Syphilis(VDRL) Kit'
                  desc={data?.BloodBagIssueEntity?.BloodTestingEntity?.syphiliskit || 'N/A'}
                />
              </div>
            </div>
            <div style={main_div}>
              <div style={left_title}>
                <p style={gridItemTitle2}>TTI Information</p>
              </div>
              <div style={gridItem2}>
              <Info
                  title='HIV'
                  desc={
                    data?.BloodBagIssueEntity?.BloodTestingEntity?.hiv == '1' ? (
                      <span style={{ fontWeight: '700', textDecorationLine: 'underline' }}>
                        {data?.BloodBagIssueEntity?.BloodTestingEntity?.hivkit == 'ELISA Kit' ? 'Reactive' : 'Positive'}
                      </span>
                    ) :
                      (data?.BloodBagIssueEntity?.BloodTestingEntity?.hivkit == 'ELISA Kit' ? 'Non-Reactive' : 'Negative')
                  }
                />
                 <Info
                  title='HBsAg(Hepatitis B)'
                  desc={
                    data?.BloodBagIssueEntity?.BloodTestingEntity?.hsbg == '1' ? (
                      <span style={{ fontWeight: '700', textDecorationLine: 'underline' }}>
                        {data?.BloodBagIssueEntity?.BloodTestingEntity?.hepatitisbkit == 'ELISA Kit' ? 'Reactive' : 'Positive'}
                      </span>
                    ) :
                      (data?.BloodBagIssueEntity?.BloodTestingEntity?.hepatitisbkit == 'ELISA Kit' ? 'Non-Reactive' : 'Negative')
                  }
                />
                <Info
                  title='Anti-HCV(Hepatitis C)'
                  desc={
                    data?.BloodBagIssueEntity?.BloodTestingEntity?.anty_hcv == '1' ? (
                      <span style={{ fontWeight: '700', textDecorationLine: 'underline' }}>
                        {data?.BloodBagIssueEntity?.BloodTestingEntity?.hepatitisckit == 'ELISA Kit' ? 'Reactive' : 'Positive'}
                      </span>
                    ) :
                      (data?.BloodBagIssueEntity?.BloodTestingEntity?.hepatitisckit == 'ELISA Kit' ? 'Non-Reactive' : 'Negative')
                  }
                />
                <Info
                  title='Malaria'
                  desc={
                    data?.BloodBagIssueEntity?.BloodTestingEntity?.malaria == '1' ? (
                      <span style={{ fontWeight: '700', textDecorationLine: 'underline' }}>
                        {data?.BloodBagIssueEntity?.BloodTestingEntity?.malariakit == 'ELISA Kit' ? 'Reactive' : 'Positive'}
                      </span>
                    ) :
                      (data?.BloodBagIssueEntity?.BloodTestingEntity?.malariakit == 'ELISA Kit' ? 'Non-Reactive' : 'Negative')
                  }
                />
                <Info
                  title='Syphilis(VDRL)'
                  desc={
                    data?.BloodBagIssueEntity?.BloodTestingEntity?.vdrl == '1' ? (
                      <span style={{ fontWeight: '700', textDecorationLine: 'underline' }}>
                        {data?.BloodBagIssueEntity?.BloodTestingEntity?.syphiliskit == 'ELISA Kit' ? 'Reactive' : 'Positive'}
                      </span>
                    ) :
                      (data?.BloodBagIssueEntity?.BloodTestingEntity?.syphiliskit == 'ELISA Kit' ? 'Non-Reactive' : 'Negative')
                  }
                />
              </div>
            </div>
            <div style={main_div}>
              <div style={left_title}>
                <p style={gridItemTitle2}>Body Vitals</p>
              </div>
              <div style={gridItem2}>
                <Info title='Weight (Kg)' desc={data?.weight} />
                <Info title='Temperature (F)' desc={data?.temprature} />
                <Info title='Pulse' desc={data?.pulse} />
                <Info title='Hemoglobin (g/dl)' desc={data?.hemoglobin_level} />
                <Info title='Blood Pressure' desc={data?.blood_pressure} />
                {data?.donor_type === 'SDP' ? (
                  <>
                    <Info title='PLT(10^3/uL) ' desc={checkup_detail?.plt} />
                    <Info title='HCT(%)' desc={checkup_detail?.hct} />
                    <Info title='RBC(10^6/uL)' desc={checkup_detail?.rbc} />
                    <Info title='WBC(10^3/uL)' desc={checkup_detail?.wbc} />
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div style={styles.container}>
          <p style={styles.bodyText}>
            {/* Next Due Date for Blood Donation: <span style={styles.dateText}>Nov. 6, 2024</span> */}
            <br />
            Thank you for being a blood donor. We appreciate your generosity that saves and improves the lives of so
            many!
          </p>
          <div style={styles.signatureSection}>
            <p>Sincerely,</p>
            <p style={styles.signature}>Medical Officer</p>
            <p style={styles.position}> {tenant?.name}</p>
          </div>
        </div>
      </div>

      <div
        style={{
          minHeight: '70px',
          padding: '6px 16px',
          background: '#F6FBFB',
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          alignItems: 'center'
        }}
      >
        <p
          style={{
            color: '#7B7B7B',
            fontSize: '10px',
            fontWeight: '400',
            lineHeight: '16px',
            ...textMargin
          }}
        >
          All laboratory results require clinical interpretation by a medical professional. This report is not intended
          for medico-legal purposes. For any concerns regarding the report, please contact us here
        </p>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '12px',
            color: '#214F8A'
          }}
        >
          <p
            style={{
              fontSize: '10px',
              margin: '0px'
            }}
          >
            Call: <span style={{ color: '#111827' }}>{tenant?.contact_number || ''}</span>
          </p>
          <p
            style={{
              fontSize: '10px',
              margin: '0px'
            }}
          >
            Email : <span style={{ color: '#111827' }}>{tenant?.email || ''}</span>
          </p>
        </div>
      </div>
    </div>
  )
}

export default ReportComp
